import * as Yup from "yup";
import {
  getDescriptionSchema,
  getNameSchema,
  getSelectSchema,
} from "shared/lib/helpers/validation";

export const MAX_FILE_UPLOAD_COUNT = 50;

export const addLocationValidationSchema = (optionalFields, t) =>
  Yup.object({
    name: getNameSchema(t, { required: true }),
    address: getNameSchema(t, optionalFields.address),
    description: getDescriptionSchema(t, optionalFields.description),
    code: Yup.string().max(50, "Must be 50 characters or less").nullable(),
    parent: getSelectSchema(t, optionalFields.ancestry),
    uploads_attributes: Yup.array()
      .max(
        MAX_FILE_UPLOAD_COUNT,
        t("attachments.errors.exceed_limit", { max: MAX_FILE_UPLOAD_COUNT })
      )
      .nullable(),
  });
