import React from "react";
import styled from "styled-components";
import { Description, H2, Hint } from "shared/components/Typography";
import { Divider } from "shared/components/Divider";
import { Details } from "widgets/Details";
import { useTranslation } from "react-i18next";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { LinksList } from "shared/components/LinksList";
import { VendorsList } from "shared/components/VendorsList";
import {
  useDisplayDate,
  useDisplayTerm,
  useGetLocaleSettings,
} from "shared/lib/hooks";
import { notificationOptions } from "modules/assets";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLinksList } from "shared/components/ModalLinksList";
import { ModalLink } from "shared/components/ModalLink";

import { useFlags } from "launchdarkly-react-client-sdk";

export const View = ({
  id,
  name,
  assetCode,
  description,
  uploadsAttributes,
  location,
  updatedAt,
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // teams,
  technicians,
  assetTypes,
  model,
  manufacturer,
  serialNumber,
  purchaseDate,
  parent,
  vendors,
  parts,
  urls,
  warrantyExpirationDate,
  warrantyExpirationNotification,
  lifeExpectancy,
  purchaseCost,
  replacementCost,
  salvageValue,
  code,
  FilesNode,
  isFieldVisible,
}) => {
  const { enableAssetCode } = useFlags();
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const displayTerm = useDisplayTerm();
  const { language, currency } = useGetLocaleSettings();

  const rows = [];

  if (isFieldVisible("location")) {
    rows.push({
      label: t("assets.details.location"),
      value: <LocationPath location={location} withTrim={false} />,
    });
  }

  if (isFieldVisible("asset_types")) {
    rows.push({
      label: t("assets.details.assetType"),
      value: assetTypes?.map(({ name }) => name).join(", "),
      multiline: true,
    });
  }

  if (isFieldVisible("model")) {
    rows.push({
      label: t("assets.details.model"),
      value: model,
    });
  }

  if (isFieldVisible("manufacturer")) {
    rows.push({
      label: t("assets.details.manufacturer"),
      value: manufacturer,
    });
  }

  if (isFieldVisible("serial_number")) {
    rows.push({
      label: t("assets.details.serialNumber"),
      value: serialNumber,
    });
  }

  if (isFieldVisible("purchase_date")) {
    rows.push({
      label: t("assets.details.purchaseDate"),
      value: purchaseDate && displayDate(purchaseDate),
    });
  }

  if (
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // (isFieldVisible("teams") && teams.length > 0) ||
    isFieldVisible("assign_to")
  ) {
    rows.push({
      label: t("assets.details.assignedTo"),
      multiline: true,
      value: <UserAvatarGroup users={technicians} />,
    });
  }

  if (isFieldVisible("vendors")) {
    rows.push({
      label: t("assets.details.vendors"),
      value: <VendorsList vendors={vendors} />,
      multiline: true,
    });
  }

  if (isFieldVisible("parts")) {
    rows.push({
      label: t("assets.details.parts"),
      value: (
        <ModalLinksList
          type="part"
          items={parts}
          layout="inline"
          separator=", "
        />
      ),
    });
  }

  if (isFieldVisible("urls") && urls) {
    rows.push({
      label: t("assets.details.urls"),
      value: <LinksList links={urls} />,
      multiline: true,
    });
  }

  if (isFieldVisible("parent")) {
    rows.push({
      label: t("assets.details.parent"),
      value: parent ? (
        <ModalLink modalParams={{ type: "asset", id: parent.id }}>
          {parent.name}
        </ModalLink>
      ) : null,
    });
  }

  if (isFieldVisible("warranty_exp_date")) {
    rows.push({
      label: t("assets.details.warrantyExpirationDate"),
      value: warrantyExpirationDate && displayDate(warrantyExpirationDate),
    });
  }

  if (isFieldVisible("warranty_exp_date")) {
    rows.push({
      label: t("assets.details.warrantyExpirationNotification"),
      value: notificationOptions?.find(
        (val) => String(val.value) === String(warrantyExpirationNotification)
      )?.label,
    });
  }

  if (isFieldVisible("life_expectancy")) {
    rows.push({
      label: t("assets.details.lifeExpectancy"),
      value: lifeExpectancy && displayTerm(lifeExpectancy),
    });
  }

  if (isFieldVisible("purchase_cost")) {
    rows.push({
      label: t("assets.details.purchaseCost"),
      value: formatCurrency(purchaseCost, language, currency),
    });
  }

  if (isFieldVisible("replacement_cost")) {
    rows.push({
      label: t("assets.details.replacementCost"),
      value: formatCurrency(replacementCost, language, currency),
    });
  }

  if (isFieldVisible("salvage_value")) {
    rows.push({
      label: t("assets.details.salvageValue"),
      value: formatCurrency(salvageValue, language, currency),
    });
  }

  return (
    <Box>
      <Hint># {id}</Hint>
      <H2>{name}</H2>
      {isFieldVisible("asset_code") && assetCode && enableAssetCode ? (
        <AssetCodeStyled>
          {`${t("assets.details.assetCode")}: ${assetCode}`}
        </AssetCodeStyled>
      ) : null}
      {description ? (
        <DescriptionStyled>{description}</DescriptionStyled>
      ) : null}
      <FilesNode value={uploadsAttributes} />
      <DividerStyled />
      <Details
        date={updatedAt}
        rows={rows}
        code={isFieldVisible("qr_code") ? code : null}
      />
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssetCodeStyled = styled(Description)`
  color: var(--color_primary_text);
  margin-bottom: 0px;
`;

const DescriptionStyled = styled(Description)`
  margin-top: 20px;
`;

const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
