import LogoIcon from "shared/assets/icon/pdf/logo.png";
import { StyleSheet, Image, Text, View, Link } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

export const PDFFooter = () => {
  const { origin, pathname, href } = window.location;
  const { t } = useTranslation();

  return (
    <View style={styles.footer} fixed>
      <Image src={LogoIcon} style={styles.logo} />
      <Link src={href} style={styles.link}>
        <Text>{origin}</Text>
        <Text style={{ fontWeight: "bold" }}>{pathname}</Text>
      </Link>
      <Text
        style={styles.pageCount}
        render={({ pageNumber, totalPages }) =>
          t("work_orders.pdf.page_number", { pageNumber, totalPages })
        }
      />
    </View>
  );
};

export const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 30,
    left: 35,
    right: 35,

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "auto",

    color: "#333238",
    fontSize: 8,
  },
  logo: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  link: {
    textDecoration: "none",
    color: "#333238",

    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pageCount: {
    marginLeft: "auto",
  },
});
