import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { getImages } from "modules/qrcodes";

export const QRCodeInformationBox = ({
  code = {},
  styles,
  module,
  isShowCode = true,
}) => {
  const { t } = useTranslation();
  const image = getImages(code.qr_code, code.size);

  const additionalFields = {
    assets: {
      SN: code.serial_number,
      Location: code.location,
      Address: code.address,
    },
    locations: {
      Address: code.address,
    },
    parts: {
      Storeroom: code.storeroom_id,
      "Area / Bin": code.area,
    },
    request_portals: {},
  };

  return (
    <div style={styles.container} key={code}>
      <div>
        <View>
          <Image src={image} style={styles.image} />
        </View>
      </div>
      <div style={styles.informationBox}>
        <View>
          <Text style={styles.bold}>{code.entity}</Text>
          <Text style={{ ...styles.fontSize, ...styles.bold }}>
            {code.name}
          </Text>
          {isShowCode && (
            <Text>
              <Text style={styles.bold}>QR#:</Text> {code.qr_code}
            </Text>
          )}
          {Object.keys(additionalFields[module]).map((field, index) => {
            const value = additionalFields[module][field] || "";
            return (
              <Text key={index}>
                <Text style={styles.bold}>{field}:</Text> {value}
              </Text>
            );
          })}
          {module === "request_portals" && (
            <Text>{t("request_portals.scanQrCode")}</Text>
          )}
        </View>
      </div>
    </div>
  );
};
