import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { useState } from "react";
import { Label } from "./styled";
import { useTranslation } from "react-i18next";
import { RadioButtonGroup, RadioButton } from "shared/components/RadioButton";
import { VerticalItem } from "./styled";
import { ItemProps } from "./types";

export const YesNoNa = ({ item, onChange }: ItemProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(item.value?.data as string);

  useUpdateProcedureItem(item, { data: value }, value);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <VerticalItem>
      <Label>{item.name}</Label>
      <RadioButtonGroup value={value}>
        <RadioButton value="yes" onSelect={(v) => handleChange(v as string)}>
          {t("procedures.items.yes_no_na.yes")}
        </RadioButton>
        <RadioButton value="no" onSelect={(v) => handleChange(v as string)}>
          {t("procedures.items.yes_no_na.no")}
        </RadioButton>
        <RadioButton value="n/a" onSelect={(v) => handleChange(v as string)}>
          {t("procedures.items.yes_no_na.na")}
        </RadioButton>
      </RadioButtonGroup>
    </VerticalItem>
  );
};
