import React from "react";
import PropTypes from "prop-types";
import { Input } from "shared/components/Input";
import { propTypesFormik, defaultPropsFormik } from "shared/lib/hooks/useForm";
import { InlineLabel } from "shared/components/widgets";
import { QrCodeInput } from "shared/components/QrCodeInput";
import { SelectCreatable } from "shared/components/Select";
import { useSearch } from "shared/lib/hooks/useSearch";
import { LocationCreate, useGetAllLocationsQuery } from "modules/locations";
import { getLocationsOptions } from "shared/lib/helpers/data";
import { useTranslation } from "react-i18next";

export const LocationEditor = React.memo(
  ({
    form = defaultPropsFormik,
    generateQr,
    setGenerateQr,
    locationId,
    optionalFields,
    defaultLocations,
  }) => {
    const { t } = useTranslation();
    const [locations = [], onLocationsSearch] = useSearch(
      useGetAllLocationsQuery,
      !defaultLocations,
      {
        ...(locationId
          ? {
              "filter[id_not_eq]": locationId,
              // Excluding locations lower in the hierarchy in the same location family
              "filter[ancestry_does_not_match]": `%25/${locationId}/%25`,
              sort: "path_name",
            }
          : { sort: "path_name" }),
      },
      "path_name"
    );

    const parentOptions = getLocationsOptions(
      defaultLocations || locations,
      {},
      "path"
    );

    return (
      <>
        <Input
          name="name"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("locations.form.title")}
          errorMessage={form.touched.name && form.errors.name}
          height={50}
          fontSize={20}
        />
        {optionalFields.hasOwnProperty("address") && (
          <InlineLabel
            label={t("locations.columns.address")}
            isRequired={optionalFields.address.required}
          >
            <Input
              name="address"
              value={form.values.address}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder={t("locations.columns.address")}
              errorMessage={form.touched.address && form.errors.address}
            />
          </InlineLabel>
        )}
        {optionalFields.hasOwnProperty("description") && (
          <InlineLabel
            label={t("locations.columns.description")}
            isRequired={optionalFields.description.required}
          >
            <Input
              name="description"
              value={form.values.description}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              placeholder={t("locations.columns.description")}
              errorMessage={form.touched.description && form.errors.description}
              isTextArea
            />
          </InlineLabel>
        )}
        {optionalFields.hasOwnProperty("qr_code") && (
          <InlineLabel multiline label={t("locations.form.qr_code")}>
            <QrCodeInput
              name="code"
              value={form.values.code}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              setValue={form.handleSetValue}
              generateQr={generateQr}
              setGenerateQr={setGenerateQr}
              errorMessage={form.touched.code && form.errors.code}
            />
          </InlineLabel>
        )}

        {optionalFields.hasOwnProperty("parent") && (
          <InlineLabel
            label={t("locations.columns.parent")}
            isRequired={optionalFields.parent.required}
          >
            <SelectCreatable
              options={parentOptions}
              name="parent"
              value={form.values.parent}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              errorMessage={form.touched.parent && form.errors.parent}
              onSearch={onLocationsSearch}
              optionCreateModal={<LocationCreate />}
              noOptionsMessage={t("noOptions")}
            />
          </InlineLabel>
        )}
      </>
    );
  }
);

LocationEditor.propTypes = {
  form: propTypesFormik,
  locationsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    })
  ),
};
