import * as Yup from "yup";
import {
  getStringSchema,
  getUrlSchema,
  getSelectSchema,
  getMultiSelectSchema,
  getCostSchema,
  getDescriptionSchema,
  getNameSchema,
} from "shared/lib/helpers/validation";

const getIntegerSchema = (t) => Yup.number().integer(t("validation.integer"));

const getPositiveNumberSchema = (t) =>
  Yup.number().min(0, t("validation.positive"));

const getNumberMaxLenghtSchema = (maxLength, maxСharacters, t) =>
  Yup.number().max(
    maxLength,
    t("validation.maxNumber", { limit: maxСharacters })
  );

const getNumberSchema = (field, t) =>
  Yup.number()
    .typeError(t("validation.positive"))
    .test("Required test", "Required", (value, testContext) => {
      if (field?.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

const getNotificationSchema = (field, t) =>
  Yup.object({
    value: Yup.string(),
    label: Yup.string(),
  })
    .nullable()
    .test("Required test", "Required", (value, testContext) => {
      if (field?.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

const getDateSchema = (field, t) =>
  Yup.date().test("Required test", "Required", (value, testContext) => {
    if (field?.required) {
      return value
        ? true
        : testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.required"),
          });
    }
    return true;
  });

export const getCustomValidationSchema = (t, field) => {
  const schema = {
    string: getStringSchema(t, field),
  };
  return schema[field.type];
};

const getQRCodeSchema = (field, t, generateQr) => {
  return Yup.string()
    .max(50, t("validation.stringMaxLength", { stringMaxLength: 50 }))
    .test("Required test", "Required", (value, testContext) => {
      if (generateQr) return true;
      if (field?.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });
};

export const addAssetValidationSchema = (optionalFields, t, generateQr) => {
  return Yup.object({
    name: getNameSchema(t, { required: true }),
    asset_code: getStringSchema(t, optionalFields.asset_code),
    description: getDescriptionSchema(t),
    location: getSelectSchema(t, optionalFields.location_id),
    asset_types: getMultiSelectSchema(t, optionalFields.asset_types_ids),
    qr_code_attributes: getQRCodeSchema(
      optionalFields.qr_code_attributes,
      t,
      generateQr
    ),
    assign_to: getMultiSelectSchema(t, optionalFields.assign_to),
    model: getStringSchema(t, optionalFields.model),
    manufacturer: getStringSchema(t, optionalFields.manufacturer),
    serial_number: getStringSchema(t, optionalFields.serial_number),
    purchase_date: getDateSchema(optionalFields.purchase_date, t),
    parent: getSelectSchema(t, optionalFields.parent_id),
    urls_attributes: getUrlSchema(t, optionalFields.urls_attributes),
    vendors: getMultiSelectSchema(t, optionalFields.vendors_ids),
    parts: getMultiSelectSchema(t, optionalFields.parts_ids),
    warranty_exp_date: getDateSchema(optionalFields.warranty_exp_date, t),
    warranty_expiration_notification: getNotificationSchema(
      optionalFields.warranty_exp_date,
      t
    ),
    life_expectancy: getPositiveNumberSchema(t)
      .concat(getNumberMaxLenghtSchema(999999999999, 12, t))
      .concat(getIntegerSchema(t))
      .concat(getNumberSchema(optionalFields.life_expectancy, t)),
    purchase_cost: getCostSchema(t).concat(
      getNumberSchema(optionalFields.purchase_cost, t)
    ),
    replacement_cost: getCostSchema(t).concat(
      getNumberSchema(optionalFields.replacement_cost, t)
    ),
    salvage_value: getCostSchema(t).concat(
      getNumberSchema(optionalFields.salvage_value, t)
    ),
  });
};
