export const getFiltersByKey = (key: string): Array<unknown> | null => {
  const filters = JSON.parse(localStorage.getItem("filters") || "{}");
  return filters[key] || null;
};

export const setFiltersByKey = (
  key: string,
  newFilters: Array<unknown>
): void => {
  const filters = JSON.parse(localStorage.getItem("filters") || "{}");
  filters[key] = newFilters;
  localStorage.setItem("filters", JSON.stringify(filters));
};
