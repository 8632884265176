import { Pagination } from "features/Pagination";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { useProceduresTableTools } from "modules/procedures/lib/useProceduresTableTools";
import { useGetProcedureTemplatesQuery } from "modules/procedures/state/proceduresApi";
import { isWorkOrderOverdue } from "modules/workOrders/lib/helpers/isWorkOrderOverdue";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoResults } from "shared/components/NoResults";
import { Table } from "shared/components/Table";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import {
  useSearchParam,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { TopBar } from "widgets/TopBar";
import { BulkActions } from "../BulkActions";
import { Empty } from "./Empty";
import { getFiltersByKey } from "modules/filters/lib/filterStorage";

interface ListProps {
  onOpenProcedureTemplate: (id: string | number) => void;
}

export const List = ({ onOpenProcedureTemplate }: ListProps) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const storedFilters = getFiltersByKey("procedure_templates") ?? [];
  const [filters] = useFiltersParam(storedFilters);
  const [selected, setSelected] = useState<number[]>([]);

  const { tableSettings, applySettings, columns, areTableSettingsFetching } =
    useProceduresTableTools({
      onOpen: onOpenProcedureTemplate,
    });

  const queryParams = {
    search,
    sort,
    ...stringifyFilters(filters, "procedure_templates"),
  };

  const { data, isFetching } = useGetProcedureTemplatesQuery({
    ...queryParams,
    page,
    per_page: tableSettings?.per_page,
  });

  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);
  const hasFilter = filters && filters.length > 0;
  const notFound =
    data?.data?.length === 0 &&
    !isFetching &&
    search?.length &&
    (search?.length > 0 || hasFilter);

  const isEmpty =
    data?.data?.length === 0 && !isFetching && !hasFilter && !isLoading;

  const isEmptyList = isEmpty && !notFound;
  const isNoResultList = isEmpty && !isEmptyList;

  const handleDeleteSuccess = useCallback(() => {
    addSnackBar({
      title: t("procedures.bulk_delete.success", {
        count: selected.length,
      }),
      type: "success",
    });

    setSelected([]);
  }, [addSnackBar, selected.length, t]);

  if (isNoResultList) {
    return <NoResults />;
  }

  if (isEmpty) {
    return <Empty />;
  }

  return (
    <>
      <TopBar
        entity="procedure_templates"
        columns={columns}
        tableSettings={tableSettings}
        filterCount={filters?.length || 0}
        setTableSettings={applySettings}
        getOptions={() => []}
        getSearch={() => []}
        showToggleFilters={true}
        withExport={false}
        initialFilters={storedFilters}
      />

      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || areTableSettingsFetching}
        highlight={search}
        notFound={notFound as boolean}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        bulkActionsNode={
          <BulkActions
            selectionModel={selected}
            onSuccess={handleDeleteSuccess}
          />
        }
        getRowBorder={(row) =>
          isWorkOrderOverdue(row.due_date, row.status?.name)
            ? "var(--color_corp_red)"
            : undefined
        }
        getRowIcon={(_row) => (
          <RowDecoratorImage src={undefined} width="16px" height="16px" />
        )}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("procedures.title_singular") as string}
      />
    </>
  );
};
