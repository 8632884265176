import { useTranslation } from "react-i18next";
import { getUser } from "shared/components/Auth";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useGetFormFieldsQuery } from "modules/formFields";

import {
  workOrderValidationSchema,
  getInitialValues,
  getOptionalFields,
  getWorkOrderFormatForService,
} from "../../lib/helpers/form";
import { useAddWorkOrderMutation } from "modules/workOrders/state/workOrdersApi";
import { WorkOrderEditor } from "../WorkOrderEditor";
import { usePermission } from "app/providers/PermissionsProvider";
import { totangoStatusFormat } from "modules/workOrders/lib/helpers/totangoFormat";

export const WorkOrderCreate = ({
  isShow,
  onClose,
  onOpen,
  defaultValues = {},
}) => {
  const { t } = useTranslation();
  const { woPartsAddPermit, woTimesAddPermit, woOtherCostAddPermit } =
    usePermission();
  const currentUser = getUser();
  const { addSnackBar } = useSnackBar();
  const [addWorkOrder, addWorkOrderRes] = useAddWorkOrderMutation();

  const { data } = useGetFormFieldsQuery({
    type: "work_orders",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(data);

  const onCloseModal = () => {
    addWorkOrderForm.resetForm();
    onClose();
  };

  const handlePrimaryButtonClick = (data) => {
    const id = data.pm_schedule_id ? data.pm_schedule_id : data.id;
    onOpen({ type: data.pm_schedule_id ? "pm" : "wo", id });
  };

  const modifiedDefaultValues = Object.fromEntries(
    Object.entries(defaultValues).filter(
      ([key]) =>
        optionalFields.hasOwnProperty(`${key}_id`) ||
        optionalFields.hasOwnProperty(`${key}_ids`)
    )
  );

  const addWorkOrderForm = useForm({
    initialValues: getInitialValues({
      type: "create",
      defaultValues: modifiedDefaultValues,
    }),
    validationSchema: workOrderValidationSchema(t, optionalFields),
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const workOrder = getWorkOrderFormatForService(body, true);

      addWorkOrder(workOrder)
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("work_orders.snack.successCreationTitle", {
              module: data.pm_schedule_id ? "PM" : "Work order",
            }),
            content: t("work_orders.snack.successCreationMessage", {
              name: data.title,
            }),
            type: "success",
            primaryButtonLabel: t("work_orders.snack.view", {
              module: data.pm_schedule_id ? "PM" : "work order",
            }),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => handlePrimaryButtonClick(data),
          });

          if (data.pm_schedule_id) {
            window.totango.track("New PM Created", "Web - PMs");
          } else {
            const status = data.status.name;
            window.totango.track("New WO Created", "Web - Work Orders");
            window.totango.track(
              `WO Set to ${totangoStatusFormat(status)}`,
              "Web - Work Orders"
            );
          }
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("title", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("work_orders.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  return (
    <EditorModal
      isOpen={isShow}
      label={t("work_orders.createNew")}
      onClose={onCloseModal}
      onSubmit={addWorkOrderForm.handleSubmit}
      disabledSubmit={
        !addWorkOrderForm.dirty ||
        !!Object.values(addWorkOrderForm.errors).length
      }
      form={addWorkOrderForm}
      isSubmitLoading={addWorkOrderRes.isLoading || addWorkOrderRes.isSuccess}
    >
      <WorkOrderEditor
        type="create"
        form={addWorkOrderForm}
        userRole={currentUser?.role}
        optionalFields={optionalFields}
        permissions={{
          parts: woPartsAddPermit,
          time: woTimesAddPermit,
          cost: woOtherCostAddPermit,
        }}
      />
    </EditorModal>
  );
};
