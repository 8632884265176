import { SliceConfig, SliceData } from "../types";
import { Cell } from "recharts";

export const buildCells = (
  data: SliceData[],
  sliceConfigs: Record<string, SliceConfig>
) => {
  return data?.map((entry, index) => {
    const config = sliceConfigs[entry.key] ?? { fill: "#BFBFC3" };
    return <Cell key={`cell-${index}`} fill={config.fill} />;
  });
};
