import * as Styled from "./PartsKPIs.styled";
import { useTranslation } from "react-i18next";
import { WosWaitingForParts } from "./components/PartsKPIsDonutChart";
import { PartsKPIsTextItem } from "./components/PartsKPIsTextItem";
import { formatCurrency } from "../../../../shared/lib/helpers/common";
import { useLocaleSettings } from "../../../reporting/lib/useLocaleSettings";
import { useSliceConfigs } from "./components/PartsKPIsDonutChart/PartsKPIsSliceConfigs";
import {
  PartsKPIsProps,
  ValueByPartType,
  WaitingForPartsRecord,
} from "./components/types";
import { ValueByPartTypeChart } from "./components/PartsKPIsDonutChart/ValueByPartTypeChart";
import i18n from "../../../../app/i18n";

/** The KPI panel at the top of the Work Order list page */
export const PartsKPIs = ({ data, isLoading }: PartsKPIsProps) => {
  const { t } = useTranslation();
  const { partTypeConfigs, wosWaitingForPartsConfig } = useSliceConfigs();
  const { currency } = useLocaleSettings();

  if (!isLoading && (!data || Object.keys(data).length !== 6)) {
    return <PartsKPIsFallback />;
  }

  return (
    <Styled.Wrapper>
      <PartsKPIsTextItem
        value={formatCurrency(data?.current_inventory_value, currency)}
        label={t("parts.kpis.currentInventoryValue")}
        isLoading={isLoading}
      />
      <PartsKPIsTextItem
        value={data?.below_min_count}
        label={t("parts.kpis.belowMinCount")}
        description={
          t("parts.kpis.belowMinCountDescription", {
            count: data?.total_part_count,
          }) ?? ""
        }
        isLoading={isLoading}
      />
      <PartsKPIsTextItem
        value={formatCurrency(data?.cost_to_restock, currency)}
        label={t("parts.kpis.costToRestock")}
        isLoading={isLoading}
      />
      <WosWaitingForParts
        count={
          t("parts.kpis.waitingForParts.topic", {
            count: data?.wos_waiting_for_parts.wos_waiting_for_parts || 0,
          }) as string
        }
        description={
          t("parts.kpis.waitingForParts.description", {
            count: data?.wos_waiting_for_parts.total_work_orders || 0,
          }) as string
        }
        label={t("parts.kpis.waitingForParts.title")}
        data={buildWaitingChartData(data?.wos_waiting_for_parts)}
        sliceConfigs={wosWaitingForPartsConfig}
        isLoading={isLoading}
      />
      <ValueByPartTypeChart
        label={t("parts.kpis.partTypeValue")}
        data={buildPartTypeChartData(data?.value_by_part_type)}
        sliceConfigs={partTypeConfigs(data?.value_by_part_type)}
        isLoading={isLoading}
      />
    </Styled.Wrapper>
  );
};

const buildPartTypeChartData = (
  values: ValueByPartType[] = []
): { key: string; percentage: number }[] => {
  if (values.length === 0) {
    return [];
  }

  const newValues = Array.from(values).sort(
    (a, b) => b.percentage - a.percentage
  );
  const firstValues = newValues.splice(0, 8);

  const map = firstValues.map((partType) => ({
    key: partType.part_type,
    value: partType.value,
    percentage: Number(partType.percentage),
  }));

  map.push({
    key: i18n.t("parts.kpis.other"),
    value: newValues.reduce(
      (acc, curr) => acc + parseFloat(curr.value.toString()),
      0
    ),
    percentage: newValues.reduce(
      (acc, curr) => acc + parseFloat(curr.percentage.toString()),
      0
    ),
  });

  return map;
};

const buildWaitingChartData = (
  waiting: WaitingForPartsRecord | undefined
): { key: string; percentage: number }[] => {
  if (!waiting) {
    return [];
  }
  return [
    {
      key: "ready",
      percentage: 100.0 - waiting.percentage,
    },
    {
      key: "waiting",
      percentage: waiting.percentage,
    },
  ];
};

export const PartsKPIsFallback = () => {
  const { t } = useTranslation();
  return <Styled.Error>{t("parts.kpis.error")}</Styled.Error>;
};
