import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTableSettings } from "modules/fieldConfiguration/lib/useTableSettings";

import { useDisplayDate } from "shared/lib/hooks";
import { UserAvatarGroup } from "shared/components/UserAvatarGroup";
import { Priority } from "shared/components/Priority";
import { Status } from "../components/Status";
import { Progression } from "../components/Progression";
import { Schedule } from "../components/Schedule";
import { PMTitle } from "../components/PMTitle";
import { convertTimeToCompleteHoursToDays, getComingUpDate } from "./helpers";
import { usePermission } from "app/providers/PermissionsProvider";
import { LocationPath } from "shared/components/LocationPath";
import { ModalLinksList } from "shared/components/ModalLinksList";
import { ModalLink } from "shared/components/ModalLink";

export const usePMsTableTools = ({ onOpen, updatePM, updatingPMs }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const { pmsCanPauseResumePms } = usePermission();

  const {
    result: { data: tableSettings, isFetching: areTableSettingsFetching },
    applySettings,
  } = useTableSettings("pm_schedule");

  const columns = useMemo(() => {
    const settings = tableSettings?.data.payload;

    return [
      {
        field: "title",
        basicField: "template_work_order",
        name: t("preventive_maintenances.columns.title"),
        width: 400,
        sortable: true,
        sortQueryParam: "template_work_order_title",
        renderCell: ({ template_work_order, id, status }, value) => (
          <PMTitle
            onClick={() => onOpen(id)}
            id={id}
            value={value}
            status={status}
            updateStatus={updatePM}
            priority={template_work_order.priority}
            updatingPMs={updatingPMs}
            isToggleActive={pmsCanPauseResumePms}
          />
        ),
        primary: true,
        order: 0,
      },
      {
        field: "status",
        name: t("preventive_maintenances.columns.status"),
        width: 200,
        sortable: true,
        sortQueryParam: "status",
        renderCell: ({ status }) => <Status value={status} />,
        order: settings?.status?.order,
        hidden: !settings?.status.visible,
        fastFilter: true,
      },
      {
        field: "coming_up",
        name: t("preventive_maintenances.columns.coming_up"),
        width: 200,
        sortable: true,
        filter: false,
        sortQueryParam: "next_order_date",
        renderCell: ({ status, next_order_date, type, has_uncompleted_wo }) =>
          getComingUpDate(
            status,
            next_order_date,
            type,
            has_uncompleted_wo,
            false
          ),
        order: settings?.coming_up?.order,
        hidden: !settings?.coming_up.visible,
      },
      {
        field: "progression",
        name: t("preventive_maintenances.columns.progression"),
        width: 250,
        sortable: false,
        filter: false,
        renderCell: ({ status, progression }) => (
          <Progression
            progression={progression}
            status={status}
            width="167px"
          />
        ),
        order: settings?.progression?.order,
        hidden: !settings?.progression.visible,
      },
      {
        field: "next_date",
        name: t("preventive_maintenances.columns.next_date"),
        width: 200,
        sortable: true,
        sortQueryParam: "next_order_date",
        renderCell: ({ status, next_order_date, type, has_uncompleted_wo }) =>
          displayDate(
            getComingUpDate(
              status,
              next_order_date,
              type,
              has_uncompleted_wo,
              true
            ),
            true
          ),
        order: settings?.next_date?.order,
        hidden: !settings?.next_date.visible,
      },
      {
        field: "priority",
        basicField: "template_work_order",
        name: t("preventive_maintenances.columns.priority"),
        width: 200,
        sortable: true,
        sortQueryParam: "priority",
        renderCell: ({ template_work_order }) => (
          <Priority value={template_work_order.priority} />
        ),
        order: settings?.priority.order,
        hidden: !settings?.priority.visible,
        fastFilter: true,
        initialSort: "desc",
      },
      {
        field: "categories",
        basicField: "template_work_order",
        sortQueryParam: "template_work_order_categories_name",
        name: t("preventive_maintenances.columns.categories"),
        width: 200,
        sortable: true,
        formatValue: (raw) => raw.map(({ name }) => name).join(", "),
        hidden: !settings?.categories?.visible,
        order: settings?.categories?.order,
        fastFilter: true,
      },
      {
        field: "assign_to",
        name: t("preventive_maintenances.columns.assignedTo"),
        width: 200,
        sortable: true,
        sortQueryParam: "template_work_order_assign_to_name",
        renderCell: (row) => (
          <UserAvatarGroup
            users={row.template_work_order.technicians}
            max={4}
            compact
          />
        ),
        order: settings?.assign_to?.order,
        hidden: !settings?.assign_to.visible,
        fastFilter: true,
      },
      {
        field: "vendors",
        basicField: "template_work_order",
        name: t("preventive_maintenances.columns.vendors"),
        width: 200,
        sortable: true,
        sortQueryParam: "template_work_order_vendors_name",
        formatValue: (vendors) => (
          <ModalLinksList
            type="vendor"
            items={vendors}
            layout="inline"
            separator=", "
          />
        ),
        order: settings?.vendors?.order,
        hidden: !settings?.vendors.visible,
      },
      {
        field: "asset",
        basicField: "template_work_order",
        sortQueryParam: "template_work_order_asset_name",
        name: t("preventive_maintenances.columns.asset"),
        width: 200,
        sortable: true,
        formatValue: (asset) =>
          asset ? (
            <ModalLink modalParams={{ type: "asset", id: asset.id }}>
              {asset.name}
            </ModalLink>
          ) : null,
        hidden: !settings?.asset?.visible,
        order: settings?.asset?.order,
      },
      {
        field: "asset_code",
        basicField: "template_work_order",
        sortQueryParam: "template_work_order_asset_asset_code",
        name: t("preventive_maintenances.columns.asset_code"),
        width: 200,
        sortable: true,
        renderCell: (row) => {
          if (!row.template_work_order?.asset?.asset_code) return null;

          return (
            <>
              <ModalLink
                modalParams={{
                  type: "asset",
                  id: row.template_work_order.asset.id,
                }}
              >
                {row.template_work_order.asset.asset_code}
              </ModalLink>
            </>
          );
        },
        hidden: !settings?.asset_code?.visible,
        order: settings?.asset_code?.order,
      },
      {
        field: "location",
        basicField: "template_work_order",
        sortQueryParam: "template_work_order_location_name",
        name: t("preventive_maintenances.columns.location"),
        width: 340,
        sortable: true,
        renderCell: ({ template_work_order }) => (
          <LocationPath location={template_work_order?.location} />
        ),
        hidden: !settings?.location?.visible,
        order: settings?.location?.order,
      },
      {
        field: "id",
        name: t("preventive_maintenances.columns.id"),
        width: 200,
        sortable: true,
        formatValue: (value) => `#${value}`,
        order: settings?.id.order,
        hidden: !settings?.id.visible,
      },
      {
        field: "description",
        basicField: "template_work_order",
        name: t("preventive_maintenances.columns.description"),
        width: 250,
        sortable: true,
        sortQueryParam: "template_work_order_description",
        order: settings?.description.order,
        hidden: !settings?.description.visible,
      },
      {
        field: "start_date",
        name: t("preventive_maintenances.columns.start_date"),
        width: 200,
        renderCell: ({ start_date }) => displayDate(start_date, true),
        sortable: true,
        order: settings?.start_date.order,
        hidden: !settings?.start_date.visible,
      },
      {
        field: "schedule",
        name: t("preventive_maintenances.columns.schedule"),
        width: 200,
        sortable: true,
        renderCell: ({ type, repeat_frequency }) => (
          <Schedule type={type} frequency={repeat_frequency} />
        ),
        order: settings?.schedule.order,
        hidden: !settings?.schedule.visible,
      },
      {
        field: "time_to_complete",
        name: t("preventive_maintenances.columns.time_to_complete"),
        width: 200,
        sortable: true,
        formatValue: (value) => convertTimeToCompleteHoursToDays(value),
        order: settings?.time_to_complete?.order,
        hidden: !settings?.time_to_complete?.visible,
      },
      {
        field: "created_at",
        name: t("preventive_maintenances.columns.createdAt"),
        width: 300,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.created_at.order,
        hidden: !settings?.created_at.visible,
      },
      {
        field: "updated_at",
        name: t("preventive_maintenances.columns.updatedAt"),
        width: 300,
        sortable: true,
        formatValue: (value) => displayDate(value, true),
        order: settings?.updated_at.order,
        hidden: !settings?.updated_at.visible,
      },
      {
        field: "created_by_name",
        name: t("preventive_maintenances.columns.createdBy"),
        width: 300,
        sortable: true,
        order: settings?.created_by_name?.order,
        hidden: !settings?.created_by_name?.visible,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
      },
      {
        field: "updated_by_name",
        name: t("preventive_maintenances.columns.updatedBy"),
        width: 300,
        sortable: true,
        order: settings?.updated_by_name?.order,
        hidden: !settings?.updated_by_name?.visible,
        formatValue: (value) =>
          value && `${value.name || ""} ${value.surname || ""}`,
      },
    ]
      .filter(({ field }) => settings?.[field]?.visible_global !== false)
      .sort((a, b) => {
        return a.order - b.order;
      });
  }, [
    t,
    tableSettings?.data.payload,
    displayDate,
    onOpen,
    updatingPMs,
    updatePM,
    pmsCanPauseResumePms,
  ]);

  return {
    columns,
    tableSettings: areTableSettingsFetching ? undefined : tableSettings?.data,
    areTableSettingsFetching,
    applySettings,
  };
};
