import { LocationImport } from "./LocationImport/LocationImport";
import { PageWrapper } from "../../../shared/components/PageWrapper/index.jsx";
import { Title } from "../../../widgets/AdministrationManageModule/AdministrationManageModule.styled.js";
import { ModuleBox, ModuleContainer, ModuleTitle } from "./styles";

export const ImportModule = () => {
  return (
    <PageWrapper>
      <Title>Import Data</Title>
      <ModuleContainer>
        <LocationImport />
        <ModuleSkeleton text={"Test Asset"} />
        <ModuleSkeleton text={"Test Vendor"} />
        <ModuleSkeleton text={"Test Work Orders"} />
        <ModuleSkeleton text={"Test PMs"} />
        <ModuleSkeleton text={"Test Parts"} />
        <ModuleSkeleton text={"Test Categories"} />
      </ModuleContainer>
    </PageWrapper>
  );
};

const ModuleSkeleton = ({ text }) => {
  return (
    <ModuleBox>
      <ModuleTitle>{text}</ModuleTitle>
    </ModuleBox>
  );
};
