import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import {
  useUpdateDowntimeTrackingSettingMutation,
  useGetDowntimeTrackingSettingsQuery,
} from "modules/downtimeTracking";
import { usePermission } from "app/providers/PermissionsProvider";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

export const AssetsAdminSettings = () => {
  const { enableAssetCode } = useFlags();

  const AssetAdminConfigurationFields = useMemo(() => {
    const configFields = [
      "enabled",
      "location",
      "asset_types",
      "categories",
      "model",
      "manufacturer",
      "serial_number",
      "purchase_date",
      "qr_code",
      "parent",
      "assign_to",
      "vendors",
      "parts",
      "urls",
      "warranty_exp_date",
      "life_expectancy",
      "purchase_cost",
      "replacement_cost",
      "salvage_value",
    ];

    if (enableAssetCode) {
      configFields.push("asset_code");
    }

    return configFields;
  }, [enableAssetCode]);

  const { adminSettingsAssetPermit } = usePermission() as {
    adminSettingsAssetPermit: boolean;
  };

  const { data } = useGetAdminConfigurationQuery({ type: "asset" });
  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });
  const [updateAdminConfiguration] = useUpdateDowntimeTrackingSettingMutation();

  if (!data || !data?.data) return null;

  const assetData = {
    ...data?.data?.payload,
    enabled: {
      required: downtimeTrackingSettings?.enabled,
    },
  };

  const currentConfiguration = getConfigurationFields(
    AssetAdminConfigurationFields,
    assetData
  );

  const updateDowntimeTrackingSettings = (data: {
    enabled?: { required: boolean };
  }) => {
    updateAdminConfiguration({
      targetType: "asset",
      body: { enabled: data?.enabled?.required },
    });
  };

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="asset"
      additionalFields={[
        {
          name: "enabled",
          type: "track_downtime",
          onUpdate: updateDowntimeTrackingSettings,
        },
      ]}
      withDescription={false}
      hasPermission={adminSettingsAssetPermit}
    />
  );
};
