import styled from "styled-components";
import { Text } from "react-aria-components";
import { CSSProperties, ReactNode } from "react";

interface BodyTextProps {
  children: ReactNode;
  style?: CSSProperties;
}

export const BodyText = ({ children, style }: BodyTextProps) => (
  <StyledText style={style}>{children}</StyledText>
);

const StyledText = styled(Text)`
  font-size: 14px;
`;
