import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DowntimeType, getStatusIcon } from "modules/downtimeTracking";

import { Input } from "shared/components/Input";
import { DateTimePicker } from "shared/components/DatePicker";

import { Container, StyledInlineLabel } from "./ChangeStatusEditor.styled";
import { getTimezoneDateFormats } from "shared/lib/helpers/index.js";
import { parseISO, min, max, formatISO } from "date-fns";

export const ChangeStatusEditor = ({
  form,
  type,
  endedAtRequired,
  workOrderId,
  workOrderName,
  firstEventStart,
  recentOnlineEventStart,
  workOrderCompletedAt,
}) => {
  const { t } = useTranslation();
  const statusIcon = useMemo(
    () => getStatusIcon(form.values.status, t),
    [form.values.status, t]
  );

  const completedWorkOrderStart = workOrderCompletedAt ? firstEventStart : null;

  const minStartDateTime =
    completedWorkOrderStart || // Completed Work Order View
    recentOnlineEventStart || // Uncompleted Work Order View
    firstEventStart; // Asset View

  const maxEndDateTime =
    workOrderCompletedAt || // Completed Work Order View
    formatISO(new Date()); // Asset View

  const { dateTimePickerFormat } = getTimezoneDateFormats();
  return (
    <Container>
      <StyledInlineLabel label={t("assets.details.status")} labelWidth={110}>
        {statusIcon}
      </StyledInlineLabel>
      {form.values.status === "offline" && (
        <StyledInlineLabel
          label={t("assets.bulkActions.status.fields.type")}
          labelWidth={110}
        >
          <DowntimeType form={form} name="downtime_type" />
        </StyledInlineLabel>
      )}
      {type === "manual" && (
        <>
          <StyledInlineLabel
            label={t("assets.downtime_tracking.manualForm.from")}
            labelWidth={110}
            isRequired
          >
            <DateTimePicker
              name="started_at"
              selected={form.values.started_at}
              onChange={(val) => form.handleSetValue("started_at", val)}
              errorMessage={form.touched.started_at && form.errors.started_at}
              dateFormat={dateTimePickerFormat}
              showIcon
              minDateTime={minStartDateTime}
              maxDateTime={formatISO(
                min(
                  [
                    new Date(),
                    form.values.ended_at && parseISO(form.values.ended_at),
                  ].filter((i) => !!i)
                )
              )}
              timeIntervals={5}
            />
          </StyledInlineLabel>
          {endedAtRequired && (
            <StyledInlineLabel
              label={t("assets.downtime_tracking.manualForm.to")}
              labelWidth={110}
              isRequired
            >
              <DateTimePicker
                name="ended_at"
                selected={form.values.ended_at}
                onChange={(val) => form.handleSetValue("ended_at", val)}
                errorMessage={form.touched.ended_at && form.errors.ended_at}
                dateFormat={dateTimePickerFormat}
                showIcon
                minDateTime={formatISO(
                  max(
                    [
                      parseISO(minStartDateTime),
                      form.values.started_at &&
                        parseISO(form.values.started_at),
                    ].filter((i) => !!i)
                  )
                )}
                maxDateTime={maxEndDateTime}
                timeIntervals={5}
              />
            </StyledInlineLabel>
          )}
          {workOrderId ? (
            <StyledInlineLabel
              label={t("assets.downtime_tracking.manualForm.workOrder")}
              labelWidth={110}
            >
              {workOrderName}
            </StyledInlineLabel>
          ) : null}
        </>
      )}
      <StyledInlineLabel
        label={t("assets.bulkActions.status.fields.note")}
        labelWidth={110}
      >
        <Input
          name="note"
          value={form.values.note}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("assets.bulkActions.status.placeholders.note")}
          errorMessage={form.touched.note && form.errors.note}
          isTextArea
        />
      </StyledInlineLabel>
    </Container>
  );
};
