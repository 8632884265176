import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "shared/lib/hooks";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { ProceduresContext } from "../components/ProcedureModal/context/ProceduresProvider";
import { useUpdateProcedureItemMutation } from "../state/proceduresApi";
import { IProcedureItemValue } from "../types";
import { IProcedureItem } from "../types";
import * as Sentry from "@sentry/react";

/**
 * Monitors item for changes, then sends update request after specified delay.
 *
 * @param item The item that's being updated.
 * @param payload The value being sent to the server to update.
 * @param value The raw value. This is used to determine when changes occur.
 * @param delay_ms The delay after which to trigger the HTTP call to the server.
 */
export const useUpdateProcedureItem = (
  item: IProcedureItem,
  payload: IProcedureItemValue,
  value: string | number | boolean | void,
  delay_ms = 1000
) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const { id } = item;
  const debouncedValue = useDebounce(value, delay_ms);

  const [triggerUpdate] = useUpdateProcedureItemMutation();
  const { dispatch } = useContext(ProceduresContext);

  const updateProcedureItem = useCallback(async () => {
    if (JSON.stringify(item.value) === JSON.stringify(payload)) return;

    // Use weak equality here to allow for `undefined`
    if (value == null && item.value == null) return;

    if (
      item.item_type === "multiple_choice" &&
      item.value.selected === payload.selected
    ) {
      return;
    }

    dispatch({
      type: "isSaving",
      value: true,
    });

    try {
      await triggerUpdate({
        id,
        procedure_item: {
          value: {
            ...payload,
          },
        },
      }).unwrap();
    } catch (e) {
      Sentry.addBreadcrumb({
        category: "procedures",
        message: "Failure while attempting to edit ProcedureTemplateItem",
        data: payload,
      });
      Sentry.captureException(e);

      const {
        data: { message },
      } = e as { data: { message: string } };

      dispatch({
        type: "error",
        value: t("procedures.modal_feedback.error"),
      });

      addSnackBar({
        title: t("procedures.snackbars.progress_updated.error.title"),
        content: message,
        type: "error",
      });
    }

    dispatch({ type: "isSaving", value: false });
  }, [
    addSnackBar,
    dispatch,
    id,
    item.item_type,
    item.value,
    payload,
    t,
    triggerUpdate,
    value,
  ]);

  useEffect(() => {
    if (value !== debouncedValue) {
      dispatch({
        type: "savePending",
        value: true,
      });
    }
  }, [debouncedValue, dispatch, value]);

  useEffect(() => {
    updateProcedureItem();
  }, [debouncedValue]); // eslint-disable-line react-hooks/exhaustive-deps
};
