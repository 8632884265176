import { useGetReportKPIsQuery } from "modules/reporting/state/reportingApi";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { ReportKeyValue } from "../types/ReportKey";
import { StringifiedFilters } from "../types/StringifiedFilters";

export const useGetKPIs = <T extends object>(
  reportKey: ReportKeyValue,
  reportPeriod?: ReportPeriod,
  stringifiedFilters: StringifiedFilters = {}
) => {
  const state = useGetReportKPIsQuery({
    reportId: reportKey,
    start_date: reportPeriod?.startDate,
    end_date: reportPeriod?.endDate,
    ...stringifiedFilters,
  });

  return {
    ...state,
    data: state.data as T | undefined,
  };
};
