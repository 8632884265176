import { useGetTableDataQuery } from "modules/reporting/state/reportingApi";
import { Response } from "types/Response";
import { usePageParam, useSortParam } from "shared/lib/hooks";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { ReportKeyValue } from "../types/ReportKey";
import { StringifiedFilters } from "../types/StringifiedFilters";

export const useGetTableData = <T extends object[]>(
  reportKey: ReportKeyValue,
  reportPeriod?: ReportPeriod,
  stringifiedFilters: StringifiedFilters = {}
) => {
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const state = useGetTableDataQuery({
    reportId: reportKey,
    start_date: reportPeriod?.startDate,
    end_date: reportPeriod?.endDate,
    per_page: 25,
    page,
    sort,
    ...stringifiedFilters,
  });

  return {
    ...state,
    data: state.data as Response<T> | undefined,
  };
};
