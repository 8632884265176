import { IProcedureItem } from "modules/procedures/types";

export const useGetProcedureItemComplete = () => {
  return (item: IProcedureItem): boolean => {
    if (item == null) return false;

    switch (item.item_type) {
      case "multiple_choice":
        return item?.value?.selected != null;

      case "checkbox":
        return item?.value?.checked === true;

      case "date":
        return item?.value?.date != null;

      case "text":
        return item?.value?.data != null && item?.value?.data !== "";

      default:
        return item?.value?.data != null;
    }
  };
};
