/* eslint-disable @typescript-eslint/no-empty-function */
import {
  SearchFilterOptions,
  useSearchAssetOptions,
  useSearchAssetTypeOptions,
  useSearchCategoryOptions,
  useSearchLocationOptions,
  useSearchPartTypeOptions,
  useSearchStatusOptions,
  useSearchTechnicianOptions,
  useSearchVendorOptions,
} from "modules/filters/hooks/searchFilterOptions";
import {
  FilterPriorityOptions,
  FilterTypeOptions,
} from "modules/filters/configs/constants";
import { TopBar } from "widgets/TopBar";
import { ReactNode } from "react";
import { ReportKeyValue } from "modules/reporting/types/ReportKey";
import { useReportFilterOptions } from "modules/reporting/lib/useReportFilterOptions";
import { EntityType } from "modules/reporting/types/Entity";
import { REPORT_FILTER_MAP } from "modules/reporting/lib/reportFilterMap";

interface ReportActionBarProps {
  entity: EntityType;
  reportKey: ReportKeyValue;
  buttons: ReactNode;
  initialFilters: unknown[];
}

export const ReportActionBar = ({
  entity,
  reportKey,
  buttons,
  initialFilters,
}: ReportActionBarProps) => {
  const statusSearch = useSearchStatusOptions();
  const assetsSearch = useSearchAssetOptions();
  const vendorsSearch = useSearchVendorOptions();
  const categoriesSearch = useSearchCategoryOptions();
  const locationsSearch = useSearchLocationOptions();
  const techniciansSearch = useSearchTechnicianOptions();
  const assetTypesSearch = useSearchAssetTypeOptions();
  const partTypesSearch = useSearchPartTypeOptions();

  const fieldMap: Record<string, SearchFilterOptions> = {
    type: { filterOptions: FilterTypeOptions },
    priority: { filterOptions: FilterPriorityOptions },
    assign_to: techniciansSearch,
    categories: categoriesSearch,
    location: locationsSearch,
    asset: assetsSearch,
    vendors: vendorsSearch,
    status: statusSearch,
    asset_types: assetTypesSearch,
    part_type: partTypesSearch,
  };

  const filterOptions = useReportFilterOptions(entity);
  const filterKeys = REPORT_FILTER_MAP[reportKey] || [];
  const filters = filterKeys.map((key) => filterOptions[key]);

  return (
    <TopBar
      entity={entity}
      reportKey={reportKey}
      columns={filters}
      withExport={false}
      buttons={buttons}
      getOptions={(f: string) => fieldMap[f]?.filterOptions ?? []}
      getSearch={(f: string) => fieldMap[f]?.setSearch ?? (() => {})}
      initialFilters={initialFilters}
    />
  );
};
