import { Pie, PieChart } from "recharts";
import Skeleton from "react-loading-skeleton";
import { LoadingShape } from "./animations";
import {
  chartSize,
  Label,
  margin,
  PieChartWrapper,
  pieProps,
} from "../../PartsKPIs.styled";

export const DonutChartSkeleton = () => {
  return (
    <PieChartWrapper>
      <PieChart
        width={chartSize}
        height={chartSize}
        margin={{
          top: margin,
          bottom: margin,
          left: margin,
          right: margin,
        }}
      >
        <Pie
          {...pieProps}
          dataKey="value"
          data={[{ value: 1, name: "" }]}
          activeIndex={0}
          activeShape={<LoadingShape />}
        />
      </PieChart>
      <Label>
        <Skeleton width="14ch" />
      </Label>
    </PieChartWrapper>
  );
};
