import { ReactNode, useCallback, useContext } from "react";
import styled from "styled-components";
import { RadioButtonGroupContext } from "./RadioButtonGroup";

export type RadioButtonValue = string | number | null;

interface RadioButtonProps {
  children: ReactNode;
  value: RadioButtonValue;
  activeColor?: string;
  onSelect: (value: RadioButtonValue) => void;
}

export const RadioButton = ({
  children,
  onSelect,
  value,
  activeColor,
}: RadioButtonProps) => {
  const { value: activeValue, disabled } = useContext(RadioButtonGroupContext);

  const handleMouseDown = useCallback(() => {
    if (disabled) return;
    if (value === activeValue) {
      onSelect(null);
    } else {
      onSelect(value);
    }
  }, [activeValue, disabled, onSelect, value]);

  return (
    <RadioButtonContainer
      disabled={disabled}
      active={activeValue === value}
      activeColor={activeColor}
      onMouseDown={handleMouseDown}
    >
      {children}
    </RadioButtonContainer>
  );
};

const RadioButtonContainer = styled.button<{
  active?: boolean;
  activeColor?: string;
}>`
  width: 100%;
  border: ${({ active, activeColor }) =>
    active
      ? `2px solid ${
          activeColor
            ? `hsl(from ${activeColor} h s calc(l - 20));`
            : "var(--color_primary_dark)"
        }`
      : "1px solid var(--color_grey200)"};
  background-color: ${({ active, activeColor }) =>
    active
      ? `${activeColor || "var(--color_primary_hover)"};`
      : "var(--color_background_light)"};

  &:hover {
    border-color: ${({ activeColor }) =>
      activeColor
        ? `hsl(from ${activeColor} h s calc(l - 10));`
        : "var(--color_primary_dark);"}
    background-color:  ${({ activeColor }) =>
      activeColor
        ? `hsl(from ${activeColor} h s calc(l + 10));`
        : "var(--color_primary_hover);"}
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:disabled {
    background-color: ${({ active }) =>
      active ? "var(--color_primary_hover)" : "var(--color_background_light)"};
    border: var(--color_primary_dark_40);
    box-shadow: none;
    cursor: initial;
  }
`;
