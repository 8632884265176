import * as Yup from "yup";
import { emailRegexp } from "shared/lib/constants/forms";

export const addContactValidationSchema = (t, existingContacts, index) =>
  Yup.object().shape({
    name: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.maxNumber", { limit: 255 }))
      .test("is-unique", t("contacts.validation.name"), function (value) {
        return !existingContacts.find(
          (c, i) => c.name === value && i !== index && !c._destroy
        );
      }),
    role: Yup.string()
      .nullable()
      .max(255, t("validation.maxNumber", { limit: 255 })),
    email: Yup.string()
      .matches(emailRegexp, t("contacts.validation.email"))
      .email(t("contacts.validation.email"))
      .max(320, t("validation.maxNumber", { limit: 320 })),
    phone: Yup.string().max(50, t("validation.maxNumber", { limit: 50 })),
    extension: Yup.string()
      .nullable()
      .matches(/^\d+$/, t("contacts.validation.extension.number"))
      .max(
        parseInt("9".repeat(65)),
        t("validation.stringMaxLength", {
          stringMaxLength: 65,
        })
      )
      .test(
        "phone test",
        "phone is filled if extension exist",
        (value, testContext) => {
          if (value) {
            return testContext.parent.phone
              ? true
              : testContext.createError({
                  path: "phone",
                  message: t("contacts.validation.extension.phone"),
                });
          }
          return true;
        }
      ),
    is_primary: Yup.boolean(),
  });
