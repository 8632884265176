import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "shared/components/Select";
import { Label, VerticalItem } from "./styled";
import { ItemProps } from "./types";

interface Option {
  value: string;
  label: string;
}

export const MultipleChoice = ({ item, onChange }: ItemProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(
    item.value?.selected as string
  );

  useUpdateProcedureItem(item, { selected }, selected);

  const onSelectionChange = (_name: string, selection: Option) => {
    const { value } = selection || {};
    setSelected(value);
    onChange(value);
  };

  const options = useMemo(
    () => item.value.choices?.map((item) => ({ value: item, label: item })),
    [item.value.choices]
  );

  const value = useMemo(() => {
    if (selected == null) {
      return options?.find((option) => option.value === item.value.selected);
    }

    return { label: selected, value: selected };
  }, [item.value.selected, options, selected]);

  return (
    <VerticalItem>
      <Label>{item?.name}</Label>
      <Select
        value={value}
        options={options}
        setValue={onSelectionChange}
        isSearchable={false}
        placeholder={t("procedures.items.multiple_choice.select_option")}
      />
    </VerticalItem>
  );
};
