import { getBaseApiUrl } from "shared/lib/apiTools/getBaseApiUrl";
import { formatDateValue, downloadFile } from "shared/lib/helpers";
import { getTagList } from "shared/lib/apiTools/getTagList";
import { CMMSAPI } from "app/services";

export const partsApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getParts: builder.query({
      query: (params) => ({
        url: "/parts",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Part", id: "PartList" },
        ...getTagList("Part", result?.data),
      ],
    }),
    getPartTypes: builder.query({
      query: (params) => ({
        url: "/part_types",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        "PartTypes",
        ...getTagList("PartTypes", result?.data),
      ],
      transformErrorResponse: (response) => response.data,
    }),
    getAdminPartTypes: builder.query({
      query: (params) => ({
        url: "/admin/part_types",
        params,
      }),
      providesTags: (result) => [
        { type: "AdminPartTypes", id: "AdminPartTypesList" },
        ...getTagList("AdminPartTypes", result?.data),
      ],
      transformErrorResponse: (response) => response.data,
    }),
    addPartTypes: builder.mutation({
      query: (body) => ({
        url: "/admin/part_types",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "AdminPartTypes", id: "AdminPartTypesList" }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    getPartType: builder.query({
      query: (id) => `/admin/part_types/${id}`,
      providesTags: (result, error, { id }) => [{ type: "AdminPartTypes", id }],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    updatePartType: builder.mutation({
      query: ({ id, body }) => ({
        url: `/admin/part_types/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["AdminPartTypes"],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
    deletePartType: builder.mutation({
      query: (id) => ({
        url: `/admin/part_types/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "AdminPartTypes", id }],
    }),
    getStorerooms: builder.query({
      query: (params) => ({
        url: "/storerooms",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "Storeroom", id: "StoreroomList" },
        ...getTagList("Storeroom", result?.data),
      ],
    }),
    getUnits: builder.query({
      query: (params) => ({
        url: "/measurement_units",
        params,
      }),
      providesTags: (result) => [
        { type: "MeasurementUnit", id: "MeasurementUnitList" },
        ...getTagList("MeasurementUnit", result?.data),
      ],
    }),
    addPart: builder.mutation({
      query: (body) => ({
        url: "parts",
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Part", id: "PartList" }, { type: "PartKpis" }],
      transformErrorResponse: (response) => response.data,
    }),
    getPart: builder.query({
      query: (id) => `/parts/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "PartDetails", id: query },
      ],
    }),
    updatePart: builder.mutation({
      query: ({ id, body }) => ({
        url: `parts/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Part", id },
        { type: "PartDetails", id },
        { type: "PartKpis" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deletePart: builder.mutation({
      query: (id) => ({
        url: `/parts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [
        { type: "Part", id: "PartList" },
        { type: "PartKpis" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteParts: builder.mutation({
      query: (ids) => ({
        url: `/bulk`,
        method: "POST",
        body: {
          ops: ids.map((id) => ({
            url: getBaseApiUrl(`/parts/${id}`, false),
            method: "DELETE",
          })),
          sequential: true,
        },
      }),
      invalidatesTags: (result, error, ids) => [
        { type: "Part", id: "PartList" },
        { type: "PartKpis" },
        ...ids.map((id) => ({ type: "Part", id })),
      ],
    }),
    clonePart: builder.mutation({
      query: ({ id, body }) => ({
        url: `/parts/${id}/clone`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Part", id: "PartList" }, { type: "PartKpis" }],
      transformErrorResponse: (response) => response.data,
    }),
    exportParts: builder.mutation({
      queryFn: async (params, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `parts/download_report`,
          method: "POST",
          params,
          responseHandler: (response) => response.blob(),
        });
        const filename = formatDateValue(new Date()) + "-parts.xls";
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
    getPartTransactions: builder.query({
      query: (params) => ({
        url: `part_transactions`,
        params,
      }),
      providesTags: (result) => [
        { type: "PartTransactions", id: "PartTransactionsList" },
        ...getTagList("PartTransactions", result?.data),
      ],
    }),
    getPartTransaction: builder.query({
      query: (id) => `part_transactions/${id}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, query) => [
        { type: "PartTransactions", id: query },
      ],
    }),

    addTransaction: builder.mutation({
      query: ({ id, body }) => ({
        url: `/parts/${id}/add_transaction`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Part", id },
        { type: "PartDetails", id },
        { type: "PartTransactions", id },
        { type: "PartKpis" },
      ],
      transformErrorResponse: (response) => response.data,
    }),
    updateTransaction: builder.mutation({
      query: ({ id, body }) => ({
        url: `/part_transactions/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        "WorkOrderDetails",
        "PreventiveMaintenanceDetails",
        "Part",
        "PartDetails",
        "PartTransactions",
        "PartKpis",
      ],
      transformErrorResponse: (response) => response.data,
    }),
    deleteTransaction: builder.mutation({
      query: (id) => ({
        url: `/part_transactions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [
        "WorkOrderDetails",
        "PreventiveMaintenanceDetails",
        "Part",
        "PartDetails",
        "PartTransactions",
        "PartKpis",
      ],
    }),
    getPartsKPIs: builder.query({
      query: (params) => ({
        url: "/parts/kpis",
        params,
      }),
      transformResponse(response, meta, arg) {
        return response.data;
      },
      providesTags: (result) => [{ type: "Entity" }, { type: "PartKpis" }],
    }),
  }),
});

export const {
  useGetPartsQuery,
  useGetPartTypesQuery,
  useGetAdminPartTypesQuery,
  useGetPartTypeQuery,
  useAddPartTypesMutation,
  useUpdatePartTypeMutation,
  useDeletePartTypeMutation,
  useGetStoreroomsQuery,
  useGetUnitsQuery,
  useAddPartMutation,
  useGetPartQuery,
  useUpdatePartMutation,
  useDeletePartMutation,
  useDeletePartsMutation,
  useClonePartMutation,
  useExportPartsMutation,
  useAddTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
  useGetPartTransactionsQuery,
  useLazyGetPartTransactionQuery,
  useGetPartsKPIsQuery,
} = partsApi;
