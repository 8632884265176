import React from "react";
import { HeaderContainer, Label, TopHeader, SubHeader } from "./Modals.styled";
import { heading } from "@test-data";

interface EditorHeaderProps {
  label?: string;
  action?: React.ReactNode;
  variant?: "dark" | "light";
  subHeader?: string;
}

export const EditorHeader = ({
  label = "Editor",
  action = null,
  variant = "dark",
  subHeader,
}: EditorHeaderProps) => {
  return (
    <HeaderContainer>
      <TopHeader variant={variant}>
        <Label variant={variant} data-testid={heading.editorModal.id}>
          {label}
        </Label>
        {action}
      </TopHeader>
      {subHeader && <SubHeader variant={variant}>{subHeader}</SubHeader>}
    </HeaderContainer>
  );
};
