import { usePermission } from "app/providers/PermissionsProvider";
import { useInitProcedureTemplateEditMutation } from "modules/procedures/state/proceduresApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useSetModal } from "widgets/ModalController";
import { ModalParams } from "widgets/ModalController/types";
import { ProcedureTemplateCreate } from "./ProcedureTemplateCreate";
import { ProcedureTemplateEdit } from "./ProcedureTemplateEdit";
import { ProcedureTemplateView } from "./ProcedureTemplateView";

interface ProcedureTemplateModalProps {
  params: ModalParams;
}

export const ProcedureTemplateModal = ({
  params,
}: ProcedureTemplateModalProps) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  const { type, id, state = "view" } = params;
  const { procedureTemplateCreatePermit, procedureTemplateViewPermit } =
    usePermission();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const setModal = useSetModal();
  const onClose = () => setModal();
  const onView = () => setModal({ type, id });

  const [initUpdate] = useInitProcedureTemplateEditMutation();
  const handleEdit = async () => {
    try {
      const { data } = await initUpdate(id).unwrap();
      setSessionId(data?.session_id);
      setModal({ type, id, state: "edit" });
    } catch (error) {
      addSnackBar({
        type: "warning",
        title: t("procedures.snackbars.conflict.title"),
        content: t("procedures.snackbars.conflict.content"),
      });
    }
  };

  if (id === "new" && procedureTemplateCreatePermit) {
    return <ProcedureTemplateCreate isOpen={true} onClose={onClose} />;
  } else if (state === "view" && procedureTemplateViewPermit) {
    return (
      <ProcedureTemplateView
        isOpen={true}
        onClose={onClose}
        onEdit={handleEdit}
        procedureTemplateId={id}
      />
    );
  } else if (state === "edit") {
    return (
      <ProcedureTemplateEdit
        isOpen={true}
        onClose={onView}
        procedureTemplateId={id}
        sessionId={sessionId as string}
      />
    );
  }

  return null;
};
