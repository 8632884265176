import React, { useState } from "react";
import styled, { css } from "styled-components";

export const FulfilledStatus = ({
  url,
  name,
  ActionIconNode,
  label,
  onClick,
  isAction,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <FulfilledContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={isAction ? onClick : () => null}
    >
      <Img src={url} alt={name} isHover={hover} />
      <ImageOverlay>
        {isAction && (
          <React.Fragment>
            <ImageAction>
              <ActionIconNode />
            </ImageAction>
            <ImageTitle>{label}</ImageTitle>
          </React.Fragment>
        )}
      </ImageOverlay>
    </FulfilledContainer>
  );
};

export const FulfilledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.2s ease-in-out;
  ${({ isHover }) =>
    isHover &&
    css`
      transform: translate(-50%, -50%) scale(1.2);
    `}
`;
export const ImageOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color_primary_text_70);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  ${FulfilledContainer}:hover & {
    opacity: 1;
  }
`;

export const ImageAction = styled.div`
  color: var(--color_text_light);
  text-align: center;
  height: 21px;
  & > svg {
    width: 21px;
    height: 21px;
  }
`;

export const ImageTitle = styled.div`
  color: #fff;
  font-size: 14px;
  text-align: center;
`;
