import React from "react";
import { useTranslation } from "react-i18next";

import { Pagination } from "features/Pagination";
import {
  getStatusIcon,
  getTimePeriod,
  getNoteText,
} from "modules/downtimeTracking";

import { useDisplayDate } from "shared/lib/hooks";

import {
  Box,
  Container,
  Status,
  Row,
  Details,
  Note,
  SubduedText,
} from "./DowntimeHistoryList.styled";

export const DowntimeHistoryList = ({
  data,
  paginationData,
  currentPage,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  if (!data) return;

  return (
    <Container>
      {data.data?.map(
        (
          {
            id,
            status,
            note,
            event_key: eventKey,
            started_at: startedAt,
            downtime_type: downtimeType,
            ended_at: endedAt,
            created_by_name: createdByName,
          },
          index
        ) => {
          const isFirst = index === data.data.length - 1;

          const statusIcon = getStatusIcon(status, t);
          const noteText = getNoteText(status, downtimeType, note, eventKey);

          let timeText = "";
          if (!isFirst) {
            timeText = getTimePeriod(startedAt, endedAt);
          }

          return (
            <Box key={id}>
              <Row>
                <Details>
                  <Status>
                    <span>{statusIcon}</span>
                    <span>{timeText}</span>
                  </Status>
                  <SubduedText>
                    {startedAt ? displayDate(startedAt, true) : ""}
                  </SubduedText>
                </Details>
              </Row>
              <Row>
                <Note>{noteText}</Note>
                {createdByName && (
                  <Note>
                    <SubduedText>{createdByName}</SubduedText>
                  </Note>
                )}
              </Row>
            </Box>
          );
        }
      )}
      <Pagination
        paginationData={paginationData}
        label={t("assets.title")}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </Container>
  );
};
