import { ReportKey } from "modules/reporting/types/ReportKey";
import { BacklogByAssigneeReportChart } from "./BacklogByAssigneeReportChart";
import { BacklogByAssigneeReportTable } from "./BacklogByAssigneeReportTable";
import { useBacklogByAssigneeReportKPIs } from "./useBacklogByAssigneeKPIs";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  BacklogByAssigneeChartData,
  BacklogByAssigneeTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { useRef } from "react";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";
import { ReportActionBar } from "../../ReportActionBar";
import { getFiltersByKey } from "modules/filters/lib/filterStorage";
import { useReportTracking } from "modules/reporting/lib/useReportTracking";

const reportKey = ReportKey.BacklogByAssignee;
const entity = Entity.workOrders;

export const BacklogByAssigneeReport = () => {
  const { t } = useTranslation();
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const storedFilters = getFiltersByKey(reportKey) ?? [];
  const [filters] = useFiltersParam(storedFilters);
  const stringifiedFilters = stringifyFilters(filters, entity);

  const kpiRes = useBacklogByAssigneeReportKPIs(stringifiedFilters);
  const chartRes = useGetChartData<BacklogByAssigneeChartData[]>(
    reportKey,
    undefined,
    {},
    stringifiedFilters
  );
  const tableRes = useGetTableData<BacklogByAssigneeTableData[]>(
    reportKey,
    undefined,
    stringifiedFilters
  );
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    undefined,
    stringifiedFilters
  );

  useReportTracking("Maintenance Backlog by Assignee");

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider
      value={{ reportPeriod: reportPeriodCurrent, setReportPeriod: () => null }}
    >
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <BacklogByAssigneeReportChart
            data={chartRes.data}
            isFetching={chartRes.isFetching}
          />
        }
        actionBar={
          <ReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
            reportKey={reportKey}
            initialFilters={storedFilters}
          />
        }
        table={
          <BacklogByAssigneeReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf")}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod: reportPeriodCurrent,
          kpis: kpiRes.data,
        }}
        charts={[
          {
            title: t(`reporting.${reportKey}.title`) as string,
            chart: (
              <div style={{ width: "1000px", height: "600px" }}>
                <BacklogByAssigneeReportChart
                  data={chartRes.data}
                  isFetching={chartRes.isFetching}
                />
              </div>
            ),
          },
        ]}
      />
    </ReportPageProvider>
  );
};
