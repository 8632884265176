import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  AdminFieldsConfiguration,
  useAddAdminConfigurationMutation,
  useListRows,
} from "modules/adminFieldsConfiguration";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useForm } from "shared/lib/hooks/useForm";
import { AdminSettingsFormHeader } from "shared/components/AdminSettingsFormHeader";
import { NotAllowed } from "shared/components/NotAllowed";
import styled from "styled-components";
import { AdditionalField } from "modules/adminFieldsConfiguration/components/AdditionalFieldConfiguration/AdditionalFieldConfiguration";

interface FieldsAdminSettingsProps {
  initialConfiguration: Record<string, unknown>;
  type?: string;
  additionalFields?: AdditionalField[];
  withDescription?: boolean;
  hasPermission: boolean;
}

export const FieldsAdminSettings = ({
  initialConfiguration,
  type = "",
  additionalFields,
  withDescription,
  hasPermission,
}: FieldsAdminSettingsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addSnackBar } = useSnackBar();
  const [addAdminConfiguration] = useAddAdminConfigurationMutation();

  const addForm = useForm({
    initialValues: initialConfiguration,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (additionalFields) {
        additionalFields.forEach((field) => {
          if (field.onUpdate) {
            field.onUpdate({ [field.name]: values[field.name] });
          } else {
            addAdminConfiguration({
              body: { payload: { [field.name]: values[field.name] } },
              params: { type: field.type },
            });
          }
        });
      }

      try {
        await addAdminConfiguration({
          body: { payload: values },
          params: { type },
        }).unwrap();

        navigate(-1);
      } catch ({ message }) {
        addSnackBar({
          title: t("settings.failed"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: "Close",
        });
      }
    },
  });

  // Filter out entry from addForm.values if the key is in the additionalFields array
  const values = Object.keys(addForm.values).reduce(
    (acc: Record<string, unknown>, key) => {
      if (!additionalFields?.find((field) => field.name === key)) {
        acc[key] = addForm.values[key];
      }

      return acc;
    },
    {}
  );

  const rows = useListRows(values, type);

  const closeHandler = () => navigate("..");

  return (
    <Wrapper>
      <AdminSettingsFormHeader
        title={t(`${type}s.title`)}
        onSubmit={addForm.handleSubmit}
        onCancel={closeHandler}
        onClose={closeHandler}
        form={addForm}
        type="form"
      />
      {hasPermission ? (
        <AdminFieldsConfiguration
          rows={rows}
          handleSetValue={addForm.handleSetValue}
          values={addForm.values}
          module={t(`${type}s.title`).toLocaleLowerCase()}
          additionalFields={additionalFields}
          withDescription={withDescription as boolean}
        />
      ) : (
        <NotAllowed linkTo="/admin-settings" />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
