import { Sector, SectorProps } from "recharts";
import { ChartLoadingAnimation } from "../../../../../reporting/components/charts/ChartLoadingAnimation";
import { expandActiveBy } from "../../PartsKPIs.styled";

export const LoadingShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
}: SectorProps) => (
  <ChartLoadingAnimation>
    <Sector {...{ cx, cy, innerRadius, startAngle, endAngle, outerRadius }} />
  </ChartLoadingAnimation>
);
/** Makes the active (hovered) sector slightly larger */
export const ActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: SectorProps) => {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius ? outerRadius + expandActiveBy : undefined}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};
