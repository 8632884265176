import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { useState } from "react";
import { Label } from "./styled";
import { DateTimePicker } from "shared/components/DatePicker";
import { getTimezoneDateFormats } from "shared/lib/helpers";
import { VerticalItem } from "./styled";
import { ItemProps } from "./types";

export const DateInput = ({ item, onChange }: ItemProps) => {
  const { timezone, dateTimePickerFormat } = getTimezoneDateFormats();
  const [value, setValue] = useState<string | Date | void>(item?.value?.date);

  useUpdateProcedureItem(item, { date: value as string }, value as string, 500);

  const handleChange = (val: string | void) => {
    setValue(val);
    onChange(val);
  };

  return (
    <VerticalItem>
      <Label>{item.name}</Label>
      <DateTimePicker
        isClearable
        name={item.name}
        onChange={handleChange}
        dateFormat={dateTimePickerFormat}
        showIcon
        selected={value as string}
        timeZone={timezone}
      />
    </VerticalItem>
  );
};
