import { ReportKey, ReportKeyValue } from "../types/ReportKey";

type AssetFilterKey =
  | "name"
  | "assetCode"
  | "id"
  | "qrCode"
  | "assetTypes"
  | "assignTo"
  | "warrantyExpDate"
  | "location";
type WorkOrderFilterKey =
  | "title"
  | "id"
  | "status"
  | "priority"
  | "createdAt"
  | "type"
  | "assignTo"
  | "categories"
  | "location"
  | "asset"
  | "assetCode"
  | "vendors"
  | "createdBy"
  | "dueDate"
  | "completedAt";
type PartFilterKey = "partType" | "partName";
type FilterKey = AssetFilterKey | WorkOrderFilterKey | PartFilterKey;

export const REPORT_FILTER_MAP: Record<ReportKeyValue, FilterKey[]> = {
  [ReportKey.BacklogByAssignee]: [
    "type",
    "dueDate",
    "priority",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.BacklogByPriority]: [
    "status",
    "categories",
    "assignTo",
    "type",
    "dueDate",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.BacklogByStatus]: [
    "priority",
    "categories",
    "assignTo",
    "type",
    "dueDate",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.MaintenanceHistoryByAssignee]: [
    "type",
    "dueDate",
    "priority",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.HistoryByCost]: [
    "title",
    "id",
    "type",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
    "createdBy",
  ],
  [ReportKey.OntimeVsOverdue]: [
    "type",
    "dueDate",
    "priority",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.PartsInventory]: ["partType", "partName"],
  [ReportKey.ReactiveVsPreventive]: [
    "type",
    "dueDate",
    "priority",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
  ],
  [ReportKey.CreatedVsCompleted]: [
    "title",
    "id",
    "status",
    "createdAt",
    "type",
    "assignTo",
    "categories",
    "location",
    "asset",
    "assetCode",
    "vendors",
    "createdBy",
  ],
  [ReportKey.AssetCostsAndDowntime]: [
    "name",
    "id",
    "assetCode",
    "qrCode",
    "assetTypes",
    "assignTo",
    "warrantyExpDate",
    "location",
  ],
  [ReportKey.AssetCosts]: [
    "name",
    "id",
    "assetCode",
    "qrCode",
    "assetTypes",
    "assignTo",
    "warrantyExpDate",
    "location",
  ],
  [ReportKey.CompletedByCategory]: [
    "completedAt",
    "categories",
    "location",
    "asset",
    "assetCode",
  ],
};
