import { Label, SuperLabel } from "../../../PartsKPIs.styled";
import { Hint } from "../../../../../../../shared/components/Typography";

export const PercentLabel = ({
  label,
  percentage,
  description,
}: {
  label?: string;
  percentage?: string;
  description?: string;
}) => {
  return (
    <div>
      {!!percentage && <SuperLabel>{percentage}</SuperLabel>}
      {label && <Label>{label}</Label>}
      {description && <Hint>{description}</Hint>}
    </div>
  );
};

export const DefaultLabel = ({
  count,
  label,
  description,
}: {
  count?: number;
  label?: string;
  description?: string | null;
}) => {
  return (
    <div>
      {!!count && <SuperLabel>{count}</SuperLabel>}
      {label && <Label>{label}</Label>}
      {description && <Hint>{description}</Hint>}
    </div>
  );
};
