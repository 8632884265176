import { ReportKey } from "modules/reporting/types/ReportKey";
import { MaintenanceHistoryByCostReportChart } from "./MaintenanceHistoryByCostReportChart";
import { MaintenanceHistoryByCostReportTable } from "./MaintenanceHistoryByCostReportTable";
import { useMaintenanceHistoryByCostReportKPIs } from "./useMaintenanceHistoryByCostKPIs";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { ReportBucket } from "modules/reporting/types/ReportBucket";
import { ChartWithBucketOptionsWrapper } from "modules/reporting/components/ReportBucketOptions";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import {
  MaintenanceHistoryByCostReportChartData,
  MaintenanceHistoryByCostTableData,
} from "./types";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { getBucketedChartsList } from "modules/reporting/lib/getBucketedChartsList";
import { useRef } from "react";
import { useFiltersParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { Entity } from "modules/reporting/types/Entity";
import { ReportActionBar } from "../../ReportActionBar";
import { getFiltersByKey } from "modules/filters/lib/filterStorage";
import { useReportTracking } from "modules/reporting/lib/useReportTracking";

const reportKey = ReportKey.HistoryByCost;
const entity = Entity.workOrders;

export const MaintenanceHistoryByCostReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const [bucket, setBucket] = usePersistentState<ReportBucket>(
    "report_bucket",
    "day"
  );
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);

  const storedFilters = getFiltersByKey(reportKey) ?? [];
  const [filters] = useFiltersParam(storedFilters);
  const stringifiedFilters = stringifyFilters(filters, entity);

  const kpiRes = useMaintenanceHistoryByCostReportKPIs(
    reportPeriod,
    stringifiedFilters
  );
  const chartRes = useGetChartData<MaintenanceHistoryByCostReportChartData[]>(
    reportKey,
    reportPeriod,
    { bucket: "day" },
    stringifiedFilters
  );
  const tableRes = useGetTableData<MaintenanceHistoryByCostTableData[]>(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );
  const downloadXLS = useDownloadReportXLS(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );

  useReportTracking("Maintenance History by Cost");

  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <ChartWithBucketOptionsWrapper {...{ bucket, setBucket }}>
            <MaintenanceHistoryByCostReportChart
              bucket={bucket}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </ChartWithBucketOptionsWrapper>
        }
        actionBar={
          <ReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
            entity={entity}
            reportKey={reportKey}
            initialFilters={storedFilters}
          />
        }
        table={
          <MaintenanceHistoryByCostReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={getBucketedChartsList(t).map((b) => ({
          title: t(`reporting.${reportKey}.title`) as string,
          subtitle: b.label,
          chart: (
            <div style={{ width: "1000px", height: "420px" }}>
              <MaintenanceHistoryByCostReportChart
                bucket={b.bucket}
                data={chartRes.data}
                isFetching={false}
              />
            </div>
          ),
        }))}
      />
    </ReportPageProvider>
  );
};
