import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DescriptionBox,
  Title,
  Description,
  DividerStyled,
  StyledSwitch,
} from "../AdminFieldsConfiguration/AdminFieldsConfiguration.styled";

export interface AdditionalField {
  name: string;
  type: string;
  onUpdate?: (value: Record<string, unknown>) => void;
}

interface AdditionalFieldConfigurationProps {
  field: AdditionalField;
  activated: boolean;
  onToggle: (fieldName: string) => void;
  withDescription?: boolean;
}

export const AdditionalFieldConfiguration = ({
  field,
  activated,
  onToggle,
  withDescription = true,
}: AdditionalFieldConfigurationProps) => {
  const { t } = useTranslation();
  const { type } = field || {};

  return (
    <>
      <Title>{t(`settings.labels.${type}.title`)}</Title>
      <StyledSwitch onClick={() => onToggle(field.name)} activated={activated}>
        {t(`settings.labels.${type}.label`)}
      </StyledSwitch>

      {withDescription && (
        <DescriptionBox>
          <Description>{t(`settings.labels.${type}.description`)}</Description>
        </DescriptionBox>
      )}

      <DividerStyled />
    </>
  );
};

AdditionalFieldConfiguration.propTypes = {
  activated: PropTypes.bool,
  onSetRequired: PropTypes.func,
  field: PropTypes.string,
  type: PropTypes.string,
  withDescription: PropTypes.bool,
};
