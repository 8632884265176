import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Select, SelectCreatable } from "shared/components/Select";
import { FileManager } from "modules/fileManager";
import { notificationOptions, useGetAllAssetsQuery } from "modules/assets";
import { useGetAllVendorsQuery, VendorCreate } from "modules/vendors";
import { LocationCreate, useGetAllLocationsQuery } from "modules/locations";
import { useGetAssetTypesQuery } from "modules/assetTypes";
import { useGetPartsQuery } from "modules/parts";

import { useGetLocaleSettings, useSearch } from "shared/lib/hooks";
import { QrCodeInput } from "shared/components/QrCodeInput";
import { HyperlinkManager } from "shared/components/HyperlinkManager";
import {
  getOptions,
  getAssignToOptions,
  getTimezoneDateFormats,
} from "shared/lib/helpers";
import { useGetTechniciansQuery } from "modules/users";
import {
  getAssetsOptions,
  getLocationsOptions,
  getVendorsOptions,
  setAsset,
} from "shared/lib/helpers/data";
import { SingleDatePicker } from "shared/components/DatePicker";
import { PartCreate } from "modules/parts/components/PartCreate";

import { useFlags } from "launchdarkly-react-client-sdk";

export const AssetEditor = ({
  form,
  optionalFields,
  generateQr,
  setGenerateQr,
  assetId,
  defaultAssets = null,
  defaultOnAssetsSearch,
}) => {
  const { enableAssetCode } = useFlags();
  const { t } = useTranslation();
  const { currency } = useGetLocaleSettings();

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    Object.hasOwn(optionalFields, "location_id"),
    { sort: "path_name" },
    "path_name"
  );

  const [assetTypes = { data: [] }, onAssetTypesSearch] = useSearch(
    useGetAssetTypesQuery,
    Object.hasOwn(optionalFields, "asset_types_ids")
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    Object.hasOwn(optionalFields, "parent_id") && !defaultAssets,
    assetId
      ? {
          "filter[id_not_eq]": assetId,
          // Excluding assets lower in the hierarchy in the same asset family
          "filter[ancestry_does_not_match]": `%25/${assetId}/%25`,
        }
      : {}
  );

  const [vendors = [], onVendorsSearch] = useSearch(
    useGetAllVendorsQuery,
    Object.hasOwn(optionalFields, "vendors_ids")
  );

  const [parts = { data: [] }, onPartsSearch] = useSearch(
    useGetPartsQuery,
    Object.hasOwn(optionalFields, "parts_ids")
  );

  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    Object.hasOwn(optionalFields, "assign_to"),
    {},
    "full_name"
  );

  const params = {
    location: form.values.location,
    asset: form.values.parent,
    vendors: form.values.vendors,
  };
  const locationsOptions = getLocationsOptions(locations, params, "path");
  const assetsOptions = getAssetsOptions(assets, params);
  const vendorsOptions = getVendorsOptions(vendors, params);
  const assetTypesOptions = getOptions(assetTypes);
  const partsOptions = getOptions(parts);
  const assignToOptions = getAssignToOptions(technicians);

  const onAssignToSearch = (value) => {
    onTechniciansSearch(value);
  };

  const dateFormat = getTimezoneDateFormats().onlyDateFormat;
  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("assets.fields.placeholders.name")}
        errorMessage={form.touched.name && form.errors.name}
        height={50}
        fontSize={20}
      />
      {Object.hasOwn(optionalFields, "asset_code") && enableAssetCode && (
        <InlineLabel
          label={t("assets.fields.labels.asset_code")}
          labelWidth={110}
          isRequired={optionalFields.asset_code.required}
        >
          <Input
            name="asset_code"
            value={form.values.asset_code}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.asset_code")}
            errorMessage={form.touched.asset_code && form.errors.asset_code}
          />
        </InlineLabel>
      )}
      <InlineLabel
        label={t("assets.fields.labels.description")}
        labelWidth={110}
      >
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("assets.fields.placeholders.description")}
          errorMessage={form.touched.description && form.errors.description}
          isTextArea
        />
      </InlineLabel>
      {Object.hasOwn(optionalFields, "location_id") && (
        <InlineLabel
          label={t("assets.fields.labels.location_id")}
          labelWidth={110}
          isRequired={optionalFields.location_id.required}
        >
          <SelectCreatable
            options={locationsOptions}
            name="location"
            value={form.values.location}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            errorMessage={form.touched.location && form.errors.location}
            optionCreateModal={<LocationCreate />}
            onSearch={onLocationsSearch}
            menuPlacement="bottom"
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "asset_types_ids") && (
        <InlineLabel
          label={t("assets.fields.labels.asset_types_ids")}
          labelWidth={110}
          isRequired={optionalFields.asset_types_ids.required}
        >
          <Select
            options={assetTypesOptions}
            name="asset_types"
            value={form.values.asset_types}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            errorMessage={form.touched.asset_types && form.errors.asset_types}
            onSearch={onAssetTypesSearch}
            menuPlacement="bottom"
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "model") && (
        <InlineLabel
          label={t("assets.fields.labels.model")}
          labelWidth={110}
          isRequired={optionalFields.model.required}
        >
          <Input
            name="model"
            value={form.values.model}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.model")}
            errorMessage={form.touched.model && form.errors.model}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "manufacturer") && (
        <InlineLabel
          label={t("assets.fields.labels.manufacturer")}
          labelWidth={110}
          isRequired={optionalFields.manufacturer.required}
        >
          <Input
            name="manufacturer"
            value={form.values.manufacturer}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.manufacturer")}
            errorMessage={form.touched.manufacturer && form.errors.manufacturer}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "serial_number") && (
        <InlineLabel
          label={t("assets.fields.labels.serial_number")}
          labelWidth={110}
          isRequired={optionalFields.serial_number.required}
        >
          <Input
            name="serial_number"
            value={form.values.serial_number}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.serial_number")}
            errorMessage={
              form.touched.serial_number && form.errors.serial_number
            }
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "purchase_date") && (
        <InlineLabel
          label={t("assets.fields.labels.purchase_date")}
          labelWidth={110}
          isRequired={optionalFields.purchase_date.required}
        >
          <SingleDatePicker
            name="purchase_date"
            dateFormat={dateFormat}
            showIcon
            selected={form.values.purchase_date}
            onChange={(date) => form.handleSetValue("purchase_date", date)}
            onBlur={form.handleBlur}
            errorMessage={
              form.touched.purchase_date && form.errors.purchase_date
            }
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "qr_code_attributes") && (
        <InlineLabel
          label={t("assets.fields.labels.qr_code_attributes")}
          labelWidth={110}
          isRequired={optionalFields.qr_code_attributes.required}
        >
          <QrCodeInput
            name="qr_code_attributes"
            value={form.values.qr_code_attributes}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            setValue={form.setFieldValue}
            generateQr={generateQr}
            setGenerateQr={setGenerateQr}
            errorMessage={
              form.touched.qr_code_attributes && form.errors.qr_code_attributes
            }
            validateField={form.validateField}
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "parent_id") && (
        <InlineLabel
          label={t("assets.fields.labels.parent_id")}
          labelWidth={110}
          isRequired={optionalFields.parent_id.required}
        >
          <Select
            options={assetsOptions}
            name="parent"
            value={form.values.parent}
            setValue={(name, asset) => {
              setAsset(name, asset, locations, assets, form);
            }}
            onBlur={form.handleBlur}
            errorMessage={form.touched.parent && form.errors.parent}
            onSearch={defaultOnAssetsSearch || onAssetsSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "assign_to") && (
        <InlineLabel
          label={t("assets.fields.labels.assign_to")}
          labelWidth={110}
          isRequired={optionalFields.assign_to.required}
        >
          <Select
            options={assignToOptions}
            name="assign_to"
            value={form.values.assign_to}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.assign_to && form.errors.assign_to}
            onSearch={onAssignToSearch}
            onTouch={form.handleSetTouched}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "urls_attributes") && (
        <InlineLabel
          label={t("assets.fields.labels.urls_attributes")}
          labelWidth={110}
          isRequired={optionalFields.urls_attributes.required}
        >
          <HyperlinkManager
            name="urls_attributes"
            value={form.values.urls_attributes}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            setValue={form.handleSetValue}
            errors={form.touched.urls_attributes && form.errors.urls_attributes}
            required={optionalFields.urls_attributes.required}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "vendors_ids") && (
        <InlineLabel
          label={t("assets.fields.labels.vendors_ids")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids.required}
        >
          <SelectCreatable
            options={vendorsOptions}
            name="vendors"
            value={form.values.vendors}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            errorMessage={form.touched.vendors && form.errors.vendors}
            onSearch={onVendorsSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
            optionCreateModal={<VendorCreate />}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "parts_ids") && (
        <InlineLabel
          label={t("assets.fields.labels.parts_ids")}
          labelWidth={110}
          isRequired={optionalFields.parts_ids.required}
        >
          <SelectCreatable
            options={partsOptions}
            name="parts"
            value={form.values.parts}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            errorMessage={form.touched.parts && form.errors.parts}
            onSearch={onPartsSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
            optionCreateModal={<PartCreate />}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "warranty_exp_date") && (
        <InlineLabel
          label={t("assets.fields.labels.warranty_exp_date")}
          labelWidth={110}
          isRequired={optionalFields.warranty_exp_date.required}
        >
          <SingleDatePicker
            name="warranty_exp_date"
            dateFormat={dateFormat}
            showIcon
            selected={form.values.warranty_exp_date}
            onChange={(date) => form.handleSetValue("warranty_exp_date", date)}
            onBlur={form.handleBlur}
            errorMessage={
              form.touched.warranty_exp_date && form.errors.warranty_exp_date
            }
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "warranty_exp_date") && (
        <InlineLabel
          label={t("assets.fields.labels.warranty_expiration_notification")}
          labelWidth={110}
          isRequired={optionalFields.warranty_exp_date.required}
        >
          <Select
            options={notificationOptions}
            name="warranty_expiration_notification"
            value={form.values.warranty_expiration_notification}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            errorMessage={
              form.touched.warranty_expiration_notification &&
              form.errors.warranty_expiration_notification
            }
            onSearch={() => null}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "life_expectancy") && (
        <InlineLabel
          label={t("assets.fields.labels.life_expectancy")}
          labelWidth={110}
          isRequired={optionalFields.life_expectancy.required}
        >
          <Input
            name="life_expectancy"
            value={form.values.life_expectancy}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.life_expectancy")}
            errorMessage={
              form.touched.life_expectancy && form.errors.life_expectancy
            }
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "purchase_cost") && (
        <InlineLabel
          label={t("assets.fields.labels.purchase_cost")}
          labelWidth={110}
          isRequired={optionalFields.purchase_cost.required}
        >
          <Input
            name="purchase_cost"
            value={form.values.purchase_cost}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.purchase_cost")}
            errorMessage={
              form.touched.purchase_cost && form.errors.purchase_cost
            }
            startAddon={currency}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "replacement_cost") && (
        <InlineLabel
          label={t("assets.fields.labels.replacement_cost")}
          labelWidth={110}
          isRequired={optionalFields.replacement_cost.required}
        >
          <Input
            name="replacement_cost"
            value={form.values.replacement_cost}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.replacement_cost")}
            errorMessage={
              form.touched.replacement_cost && form.errors.replacement_cost
            }
            startAddon={currency}
          />
        </InlineLabel>
      )}
      {Object.hasOwn(optionalFields, "salvage_value") && (
        <InlineLabel
          label={t("assets.fields.labels.salvage_value")}
          labelWidth={110}
          isRequired={optionalFields.salvage_value.required}
        >
          <Input
            name="salvage_value"
            value={form.values.salvage_value}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("assets.fields.placeholders.salvage_value")}
            errorMessage={
              form.touched.salvage_value && form.errors.salvage_value
            }
            startAddon={currency}
          />
        </InlineLabel>
      )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
