import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { CreatedVsCompletedKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { StringifiedFilters } from "modules/reporting/types/StringifiedFilters";

const reportKey = ReportKey.CreatedVsCompleted;

export const useCreatedVsCompletedReportKPIs = (
  reportPeriod?: ReportPeriod,
  stringifiedFilters?: StringifiedFilters
) => {
  const { data, ...rest } = useGetKPIs<CreatedVsCompletedKPIs>(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return {
    ...rest,
    data: [
      {
        value: formatNumber(data?.created, language),
        label: t(`reporting.${reportKey}.kpis.created`),
      },
      {
        value: formatPercent(data?.completed_on_time_percentage, language),
        label: t(`reporting.${reportKey}.kpis.completed_on_time_percentage`),
      },
      {
        value: formatNumber(data?.completed, language),
        label: t(`reporting.${reportKey}.kpis.completed`),
      },
      {
        value: formatPercent(data?.completed_percentage, language),
        label: t(`reporting.${reportKey}.kpis.completed_percentage`),
      },
      {
        value:
          typeof data?.mttr === "number"
            ? t(`reporting.mttr`, {
                mttr: data.mttr / 60,
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })
            : "-",
        label: t(`reporting.mttr_label`),
      },
    ],
  };
};
