import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocale from "./locales/en/en";
import esLocale from "./locales/es/es";
import frLocale from "./locales/fr/fr";
import deLocale from "./locales/de/de";
import ptLocale from "./locales/pt/pt";
import { getUser } from "shared/components/Auth";

const getUserLanguage = () => {
  const user = getUser();

  return user?.language;
};

i18n.use(initReactI18next).init({
  lng: getUserLanguage() || "en",
  defaultNS: "common",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: enLocale,
    es: esLocale,
    fr: frLocale,
    de: deLocale,
    pt: ptLocale,
  },
});

export default i18n;
