import { StyleSheet, View, Text } from "@react-pdf/renderer";
import {
  CheckedTemplate,
  UncheckedTemplate,
} from "modules/workOrders/components/WorkOrdersPdfPage/ProcedurePdfTemplates";
import { normalizeLineEndings } from "../BulkPdfExport/BulkPdfExport.styled";

interface PDFChecklistProps {
  items: {
    text: string;
    order: string;
    checked: string;
  }[];
  isPm?: boolean;
}

export const PDFChecklist = ({ items, isPm = false }: PDFChecklistProps) => {
  return (
    <View style={styles.checklist}>
      {items.map((item, index) => {
        const Checkbox =
          item.checked === "true" ? CheckedTemplate : UncheckedTemplate;

        return (
          <View key={index} style={styles.checklistItem}>
            {!isPm && Checkbox}
            <Text>{`${item.order}. ${normalizeLineEndings(item.text)}`}</Text>
          </View>
        );
      })}
    </View>
  );
};

export const styles = StyleSheet.create({
  checklist: {
    flexDirection: "column",
    gap: 4,
    marginRight: 2,
  },
  checklistItem: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    fontSize: 10,
  },
});
