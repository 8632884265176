import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { ReactiveVsPreventiveKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { StringifiedFilters } from "modules/reporting/types/StringifiedFilters";

const reportKey = ReportKey.ReactiveVsPreventive;

export const useReactiveVsPreventiveReportKPIs = (
  reportPeriod?: ReportPeriod,
  stringifiedFilters?: StringifiedFilters
) => {
  const { data, ...rest } = useGetKPIs<ReactiveVsPreventiveKPIs>(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return {
    ...rest,
    data: [
      {
        value: formatPercent(data?.reactive, language),
        label: t(`reporting.${reportKey}.kpis.reactive`),
      },
      {
        value: formatPercent(data?.on_time_percentage, language),
        label: t(`reporting.${reportKey}.kpis.on_time_percentage`),
      },
      {
        value: formatPercent(data?.preventive, language),
        label: t(`reporting.${reportKey}.kpis.preventive`),
      },
      {
        value: formatNumber(data?.pms_created, language),
        label: t(`reporting.${reportKey}.kpis.pms_created`),
      },
      {
        value: formatNumber(data?.completed, language),
        label: t(`reporting.${reportKey}.kpis.completed`),
      },
      {
        value:
          typeof data?.mttr === "number"
            ? t(`reporting.mttr`, {
                mttr: data.mttr / 60,
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })
            : "-",
        label: t(`reporting.mttr_label`),
      },
    ],
  };
};
