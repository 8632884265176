import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { getUser } from "shared/components/Auth";
import { EditorModal } from "shared/components/Modals";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { usePermission } from "app/providers/PermissionsProvider";
import {
  workOrderValidationSchema,
  getInitialValues,
  getOptionalFields,
  getWorkOrderFormatForService,
} from "../../lib/helpers/form";
import { WorkOrderEditor } from "../WorkOrderEditor";
import {
  useUpdateWorkOrderMutation,
  useGetWorkOrderQuery,
} from "modules/workOrders/state/workOrdersApi";

const hiddenFields = [
  "title",
  "priority",
  "categories_ids",
  "assign_to",
  "type",
  "due_date",
  "pm_schedule_attributes",
  "set_asset_to_offline",
  "checklist",
];

export const WorkOrderEdit = ({ isShow, onClose, onOpen, workOrderId }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const { canUpdateWO } = usePermission();
  const [updateWorkOrder, updateWorkOrderRes] = useUpdateWorkOrderMutation();

  const { data: fields } = useGetFormFieldsQuery({
    type: "work_orders",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(fields);
  const currentUser = getUser();

  const { data } = useGetWorkOrderQuery(workOrderId ?? skipToken);

  const workOrderDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data }),
    [data]
  );

  const { setValues, ...updateWorkOrderForm } = useForm({
    initialValues: workOrderDataForForm,
    validationSchema: workOrderValidationSchema(t, optionalFields),
    willUpdatedValues: workOrderDataForForm,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      const workOrder = getWorkOrderFormatForService(body);
      updateWorkOrder({ id: workOrderId, body: workOrder })
        .unwrap()
        .then(({ data }) => {
          onOpen(workOrderId);
        })
        .catch(({ message }) => {
          if (message.name?.[0] === "has already been taken") {
            setFieldError("title", t("m11", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("work_orders.snack.failUpdateTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  if (!canUpdateWO(data?.created_by_id, data?.technicians, data?.status))
    return null;

  const disabledFields =
    currentUser.role === "technician" &&
    data?.status?.name !== "completed" &&
    data?.technicians?.some((technician) => technician.id === currentUser.id)
      ? hiddenFields
      : [];

  const onCloseModal = () => {
    updateWorkOrderForm.resetForm();
    onClose();
  };

  return (
    <EditorModal
      isOpen={isShow}
      label={t("work_orders.edit", { id: workOrderId })}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updateWorkOrderForm.handleSubmit}
      disabledSubmit={
        !updateWorkOrderForm.isWasChanged || !updateWorkOrderForm.isFormValid
      }
      isSubmitLoading={
        updateWorkOrderRes.isLoading || updateWorkOrderRes.isSuccess
      }
      form={updateWorkOrderForm}
    >
      <WorkOrderEditor
        type="edit"
        form={updateWorkOrderForm}
        userRole={currentUser?.role}
        optionalFields={optionalFields}
        disabledFields={disabledFields}
      />
    </EditorModal>
  );
};
