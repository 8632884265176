import { ReactNode, createContext } from "react";
import styled from "styled-components";
import { RadioButtonValue } from "./RadioButton";

interface RadioButtonGroupProps {
  value: RadioButtonValue;
  disabled?: boolean;
  children: ReactNode;
}

interface Context {
  disabled: boolean;
  value: RadioButtonValue | null;
}

export const RadioButtonGroupContext = createContext<Context>({
  disabled: false,
  value: null,
});

export const RadioButtonGroup = ({
  value,
  disabled = false,
  children,
}: RadioButtonGroupProps) => {
  return (
    <RadioButtonGroupContext.Provider
      value={{
        value,
        disabled: disabled as boolean,
      }}
    >
      <Container>{children}</Container>
    </RadioButtonGroupContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`;
