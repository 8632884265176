import { Text, Image, View } from "@react-pdf/renderer";
import {
  pageStyle,
  normalizeLineEndings,
} from "shared/components/BulkPdfExport/BulkPdfExport.styled.js";
import { useGetProcedureItemValue } from "shared/lib/hooks/useGetProcedureItemValue";
import CheckedIcon from "shared/assets/icon/pdf/checked.png";
import UncheckedIcon from "shared/assets/icon/pdf/unchecked.png";
import { t } from "i18next";

const CheckboxTemplate = ({ item }) => {
  const isChecked = item?.value?.checked === true;
  return isChecked ? CheckedTemplate : UncheckedTemplate;
};

const CheckboxWithName = ({ choice, value, name }) => {
  return (
    <View style={pageStyle.checkboxWithValue}>
      {choice === value ? CheckedTemplate : UncheckedTemplate}
      <Text>{normalizeLineEndings(name)}</Text>
    </View>
  );
};

const InspectionTemplate = ({ item }) => {
  const values = ["pass", "flag", "fail"];
  const choice = item.value?.data;
  return (
    <View style={pageStyle.checkboxesWithValues}>
      {values.map((value) => {
        const name = t(`procedures.items.inspection.${value}`);
        return CheckboxWithName({ choice, value, name });
      })}
    </View>
  );
};

const YesNoNaTemplate = ({ item }) => {
  const values = ["yes", "no", "na"];
  const rawChoice = item.value?.data;
  const choice =
    typeof rawChoice === "string" ? rawChoice.replace("/", "") : "";
  return (
    <View style={pageStyle.checkboxesWithValues}>
      {values.map((value) => {
        const name = t(`procedures.items.yes_no_na.${value}`);
        return CheckboxWithName({ choice, value, name });
      })}
    </View>
  );
};

const MultipleChoiceTemplate = ({ item }) => {
  const values = item.value.choices;
  const choice = item.value.selected;
  return (
    <View style={pageStyle.checkboxesWithValues}>
      {values.map((value) => {
        const name = value;
        return CheckboxWithName({ choice, value, name });
      })}
    </View>
  );
};

const DefaultTemplate = ({ item }) => {
  const getProcedureItemValue = useGetProcedureItemValue();
  let itemValue = getProcedureItemValue(item);
  if (typeof itemValue === "string") {
    itemValue = normalizeLineEndings(itemValue);
  }
  return <Text style={pageStyle.procedureItemBorder}>{itemValue}</Text>;
};

export const PROCEDURE_ITEM_TEMPLATES = {
  checkbox: CheckboxTemplate,
  inspection: InspectionTemplate,
  yes_no_na: YesNoNaTemplate,
  multiple_choice: MultipleChoiceTemplate,
  default: DefaultTemplate,
};

export const CheckedTemplate = (
  <Image src={CheckedIcon} style={pageStyle.procedureCheckbox} />
);
export const UncheckedTemplate = (
  <Image src={UncheckedIcon} style={pageStyle.procedureCheckbox} />
);
