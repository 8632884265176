import React, { createContext, useContext } from "react";
import { shutdown } from "@intercom/messenger-js-sdk";

export const setUser = (user) => {
  return localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const dropUser = () => {
  shutdown();
  localStorage.removeItem("filters");
  return localStorage.removeItem("user");
};

export const isAuthenticated = () => {
  const user = getUser();
  return user !== null && user !== undefined;
};

const AuthContext = createContext({});
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    dropUser();
  };

  const signUp = (user) => {
    setUser(user);
  };

  return (
    <AuthContext.Provider value={{ login, logout, signUp }}>
      {children}
    </AuthContext.Provider>
  );
};
