import * as Yup from "yup";
import { getFormDataFromJSON } from "shared/lib/helpers/formData";
import {
  WorkOrderFormOptionalFields,
  getChecklistSchema,
  defaultStatus,
} from "../../../workOrders/lib/helpers/form";
import {
  getSelectSchema,
  getStringSchema,
  getMultiSelectSchema,
  getDescriptionSchema,
} from "shared/lib/helpers/validation";
import { getAssignToIds, getAssignToValues } from "shared/lib/helpers/common";

const RequestFormOptionalFields = [
  "description",
  "priority",
  "location_id",
  "asset_id",
  "categories_ids",
  "due_date",
  "assign_to",
  "vendors_ids",
  "status_id",
];

const RequestCreateInitialValues = {
  title: "",
  description: "",
  notify: false,
  priority: "none",
  categories: [],
  due_date: "",
  uploads_attributes: [],
  location: null,
  asset: null,
  vendors: [],
  assign_to: [],
  status: null,
};

export const getInitialValues = ({ type, data }) => {
  if (type === "create" || !data) {
    return RequestCreateInitialValues;
  }

  const {
    title,
    description,
    notify = false,
    priority,
    location,
    asset,
    categories = [],
    due_date,
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teams = [],
    technicians = [],
    vendors = [],
    images = [],
    documents = [],
    procedure_templates = [],
  } = data;

  const requestEditForm = {
    title: title ?? "",
    description: description ?? "",
    priority: priority ?? "none",
    categories: categories.map(({ id, name }) => ({ value: id, label: name })),
    due_date: due_date ?? "",
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
    location: location ? { value: location.id, label: location.path } : null,
    asset: asset ? { value: asset.id, label: asset.name } : null,
    vendors: vendors.map(({ id, name }) => ({ value: id, label: name })),
    assign_to: getAssignToValues(technicians),
    procedure_template_ids: procedure_templates?.map(({ value }) => value),
  };

  if (type === "approve") {
    requestEditForm.checklist = [];
    requestEditForm.set_asset_to_offline = false;
    requestEditForm.type = "reactive";
    requestEditForm.status = defaultStatus;
  }

  if (type === "edit") {
    requestEditForm.notify = notify;
  }

  return requestEditForm;
};

export const requestValidationSchema = (t, optionalFields) => {
  const RequestFormDefaultSchema = {
    title: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
    description: getDescriptionSchema(t),
    notify: Yup.boolean(),
    priority: Yup.string(),
    categories: Yup.array(),
    due_date: Yup.string(),
    uploads_attributes: Yup.array(),
    set_asset_to_offline: Yup.boolean(),
    vendors: Yup.array(),
    location: Yup.object().nullable(),
    asset: Yup.object().nullable(),
    assign_to: Yup.array(),
    status: Yup.object().nullable(),
  };

  const checklistSchema = {
    checklist: Yup.array(
      Yup.object({
        text: Yup.string(),
        order: Yup.string(),
        checked: Yup.string(),
      }).test("Required text test", "Required", (value, testContext) => {
        return value.text
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      })
    ),
  };

  const schema = Object.keys(optionalFields).reduce(
    (updatedSchema, fieldKey) => {
      const field = optionalFields[fieldKey];

      if (["priority", "due_date"].includes(fieldKey)) {
        updatedSchema[fieldKey] = getStringSchema(t, field);
      }

      if (fieldKey === "asset_id") {
        updatedSchema["asset"] = getSelectSchema(t, field);
      }

      if (fieldKey === "location_id") {
        updatedSchema["location"] = getSelectSchema(t, field);
      }

      if (fieldKey === "vendors_ids") {
        updatedSchema["vendors"] = getMultiSelectSchema(t, field);
      }
      if (fieldKey === "categories_ids") {
        updatedSchema["categories"] = getMultiSelectSchema(t, field);
      }
      if (fieldKey === "assign_to") {
        updatedSchema["assign_to"] = getMultiSelectSchema(t, field);
      }

      if (fieldKey === "checklist") {
        updatedSchema[fieldKey] = getChecklistSchema(t, field);
      }
      if (fieldKey === "description") {
        updatedSchema[fieldKey] = getDescriptionSchema(t, field);
      }

      if (fieldKey === "status_id") {
        updatedSchema["status"] = getSelectSchema(t, field);
      }

      return updatedSchema;
    },
    RequestFormDefaultSchema
  );

  return Yup.object({ ...schema, ...checklistSchema });
};

export const getOptionalFields = ({ fields, type }) => {
  if (!fields || fields?.status !== "success") {
    return {};
  }

  const optionalFields =
    type === "approve"
      ? WorkOrderFormOptionalFields
      : RequestFormOptionalFields;

  const { data } = fields;

  return optionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};

export const getRequestFormatForService = (form) => {
  const {
    uploads_attributes,
    location,
    asset,
    vendors,
    categories,
    assign_to,
    type,
    due_date,
    procedure_templates,
    status,
    ...otherFields
  } = form;

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  //const { teams_ids, technicians_ids } = getAssignToIds(assign_to);
  const { technicians_ids } = getAssignToIds(assign_to);

  const data = getFormDataFromJSON({
    ...otherFields,
    location_id: location?.value || "",
    asset_id: asset?.value || "",
    vendors_ids: vendors.map(({ value }) => value),
    categories_ids: categories.map(({ value }) => value),
    procedure_template_ids: procedure_templates?.map(({ value }) => value),
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teams_ids,
    technicians_ids,
    due_date,
    status_id: status?.value || "",
  });

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj?.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    } else if (fileObj?.id && type === "approve") {
      data.append(`uploads_attributes[${index}][attachment_id]`, fileObj?.id);
    }
  });

  if (type === "approve") data.append("type", "reactive");

  return data;
};
