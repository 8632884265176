import { useState } from "react";
import { Pie, PieChart } from "recharts";
import { formatPercent } from "modules/reporting/lib/formatters";
import { useTranslation } from "react-i18next";
import { ActiveSlice, PartsKPIsDonutChartProps } from "./types";
import { DonutChartSkeleton } from "./DonutSkeleton";
import { ActiveShape } from "./animations";
import {
  chartMargins,
  chartSize,
  PieChartWrapper,
  pieProps,
} from "../../PartsKPIs.styled";
import { buildCells } from "./shared/helpers";
import { DefaultLabel, PercentLabel } from "./shared/Labels";

export const WosWaitingForParts = ({
  label,
  description,
  data,
  count,
  isLoading,
  sliceConfigs,
}: PartsKPIsDonutChartProps) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const [active, setActive] = useState<ActiveSlice>();

  if (isLoading) {
    return <DonutChartSkeleton />;
  }

  return (
    <PieChartWrapper>
      <PieChart width={chartSize} height={chartSize} margin={chartMargins}>
        <Pie
          {...pieProps}
          data={data}
          dataKey="percentage"
          nameKey="key"
          onMouseOver={(data, index) =>
            setActive({
              ...data,
              index,
            })
          }
          onMouseLeave={() => setActive(undefined)}
          activeIndex={active?.index}
          activeShape={<ActiveShape />}
        >
          {buildCells(data, sliceConfigs)}
        </Pie>
      </PieChart>
      {active && active.payload ? (
        <PercentLabel
          label={sliceConfigs[active.payload.key]?.label}
          percentage={formatPercent(active.payload.percentage / 100, language)}
          description={description}
        />
      ) : (
        <DefaultLabel
          count={count as number}
          label={label}
          description={description}
        />
      )}
    </PieChartWrapper>
  );
};
