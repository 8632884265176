import { useDownloadXLSMutation } from "modules/reporting/state/reportingApi";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { useTranslation } from "react-i18next";
import { ReportKeyValue } from "../types/ReportKey";
import { StringifiedFilters } from "../types/StringifiedFilters";

export const useDownloadReportXLS = (
  reportKey: ReportKeyValue,
  reportPeriod?: ReportPeriod,
  stringifiedFilters: StringifiedFilters = {}
) => {
  const { t } = useTranslation();
  const [downloadXLS] = useDownloadXLSMutation();
  const { addSnackBar } = useSnackBar();

  return () =>
    downloadXLS({
      reportId: reportKey,
      start_date: reportPeriod?.startDate,
      end_date: reportPeriod?.endDate,
      ...stringifiedFilters,
    })
      .unwrap()
      .catch(() => {
        addSnackBar({
          content: t(`reporting.exportMenu.error`),
          type: "warning",
        });
      });
};
