import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { useState } from "react";
import { Label } from "./styled";
import { useTranslation } from "react-i18next";
import { RadioButtonGroup, RadioButton } from "shared/components/RadioButton";
import { VerticalItem } from "./styled";
import { ItemProps } from "./types";

export const Inspection = ({ item, onChange }: ItemProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(item.value?.data as string);

  useUpdateProcedureItem(item, { data: value }, value);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <VerticalItem>
      <Label>{item.name}</Label>
      <RadioButtonGroup value={value}>
        <RadioButton value="pass" onSelect={(v) => handleChange(v as string)}>
          {t("procedures.items.inspection.pass")}
        </RadioButton>
        <RadioButton
          value="flag"
          onSelect={(v) => handleChange(v as string)}
          activeColor="var(--color_corp_yellow_light)"
        >
          {t("procedures.items.inspection.flag")}
        </RadioButton>
        <RadioButton
          value="fail"
          onSelect={(v) => handleChange(v as string)}
          activeColor="var(--color_corp_red_light)"
        >
          {t("procedures.items.inspection.fail")}
        </RadioButton>
      </RadioButtonGroup>
    </VerticalItem>
  );
};
