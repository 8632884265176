import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FileManager } from "modules/fileManager";
import { AssetCreate, useGetAllAssetsQuery } from "modules/assets";
import { LocationCreate, useGetAllLocationsQuery } from "modules/locations";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetPartsQuery } from "modules/parts";
import { ContactsList } from "modules/contacts";

import { useGetLocaleSettings, useSearch } from "shared/lib/hooks";
import { HyperlinkManager } from "shared/components/HyperlinkManager";
import { getOptions } from "shared/lib/helpers";
import { Switch } from "shared/components/Switch";
import { StarRatings } from "shared/components/StarRatings";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Select, SelectCreatable } from "shared/components/Select";
import { usePermission } from "app/providers/PermissionsProvider";
import { getAssetsOptions, getLocationsOptions } from "shared/lib/helpers/data";
import { PartCreate } from "modules/parts/components/PartCreate";

export const VendorEditor = ({ form, optionalFields }) => {
  const { t } = useTranslation();
  const { currency } = useGetLocaleSettings();

  const { vendorCreateContactPermit } = usePermission();

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    optionalFields.hasOwnProperty("locations_ids"),
    { sort: "path_name" },
    "path_name"
  );

  const [categories = { data: [] }, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    optionalFields.hasOwnProperty("categories_ids")
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    optionalFields.hasOwnProperty("assets_ids")
  );

  const [parts = { data: [] }, onPartsSearch] = useSearch(
    useGetPartsQuery,
    optionalFields.hasOwnProperty("parts_ids")
  );

  const locationsOptions = getLocationsOptions(locations, {}, "path");
  const assetsOptions = getAssetsOptions(assets, {});
  const categoriesOptions = getOptions(categories);
  const partsOptions = getOptions(parts);

  const showParts = useMemo(() => {
    if (optionalFields.hasOwnProperty("parts_ids")) {
      if (optionalFields.parts_ids.required) {
        return true;
      }

      return form.values.parts_supplier;
    }

    return false;
  }, [optionalFields, form.values.parts_supplier]);

  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="name"
        value={form.values.name}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("vendors.fields.placeholders.name")}
        errorMessage={form.touched.name && form.errors.name}
        height={50}
        fontSize={20}
      />
      {optionalFields.hasOwnProperty("tax_id") && (
        <InlineLabel
          label={t("vendors.fields.labels.tax_id")}
          labelWidth={110}
          isRequired={optionalFields.tax_id.required}
          tooltipContent={t("vendors.fields.tooltip")}
        >
          <Input
            name="tax_id"
            value={form.values.tax_id}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("vendors.fields.placeholders.tax_id")}
            errorMessage={form.touched.tax_id && form.errors.tax_id}
          />
        </InlineLabel>
      )}
      <InlineLabel
        label={t("vendors.fields.labels.description")}
        labelWidth={110}
      >
        <Input
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("vendors.fields.placeholders.description")}
          errorMessage={form.touched.description && form.errors.description}
          isTextArea
        />
      </InlineLabel>
      {vendorCreateContactPermit &&
        optionalFields.hasOwnProperty("vendor_contacts_attributes") && (
          <InlineLabel
            label={t("vendors.fields.labels.vendor_contacts_attributes")}
            labelWidth={110}
            isRequired={optionalFields.vendor_contacts_attributes.required}
            tooltipContent={t("vendors.fields.tooltip")}
          >
            <ContactsList
              name="vendor_contacts_attributes"
              value={form.values.vendor_contacts_attributes}
              setValue={form.handleSetValue}
              required={optionalFields.vendor_contacts_attributes.required}
              errorMessage={
                form.touched.vendor_contacts_attributes &&
                form.errors.vendor_contacts_attributes
              }
            />
          </InlineLabel>
        )}
      {optionalFields.hasOwnProperty("urls_attributes") && (
        <InlineLabel
          label={t("vendors.fields.labels.urls_attributes")}
          labelWidth={110}
          isRequired={optionalFields.urls_attributes.required}
        >
          <HyperlinkManager
            name="urls_attributes"
            value={form.values.urls_attributes}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            setValue={form.handleSetValue}
            errors={form.touched.urls_attributes && form.errors.urls_attributes}
            required={optionalFields.urls_attributes.required}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("hourly_rate") && (
        <InlineLabel
          label={t("vendors.fields.labels.hourly_rate")}
          labelWidth={110}
          isRequired={optionalFields.hourly_rate.required}
        >
          <Input
            name="hourly_rate"
            value={form.values.hourly_rate}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("vendors.fields.placeholders.hourly_rate")}
            errorMessage={form.touched.hourly_rate && form.errors.hourly_rate}
            startAddon={currency}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("address") && (
        <InlineLabel
          label={t("vendors.fields.labels.address")}
          labelWidth={110}
          isRequired={optionalFields.address.required}
        >
          <Input
            name="address"
            value={form.values.address}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("vendors.fields.placeholders.address")}
            errorMessage={form.touched.address && form.errors.address}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("locations_ids") && (
        <InlineLabel
          label={t("vendors.fields.labels.locations_ids")}
          labelWidth={110}
          isRequired={optionalFields.locations_ids.required}
        >
          <SelectCreatable
            options={locationsOptions}
            name="locations"
            value={form.values.locations}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            errorMessage={form.touched.locations && form.errors.locations}
            optionCreateModal={<LocationCreate />}
            onSearch={onLocationsSearch}
            menuPlacement="bottom"
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      <InlineLabel label="" labelWidth={110}>
        <Switch
          activated={form.values.assign_to_work_orders}
          onClick={() =>
            form.handleSetValue(
              "assign_to_work_orders",
              !form.values.assign_to_work_orders
            )
          }
          children={t("vendors.fields.labels.assign_to_work_orders")}
        />
      </InlineLabel>
      <InlineLabel label="" labelWidth={110}>
        <Switch
          activated={form.values.parts_supplier}
          onClick={() =>
            form.handleSetValue("parts_supplier", !form.values.parts_supplier)
          }
          children={t("vendors.fields.labels.parts_supplier")}
        />
      </InlineLabel>
      {optionalFields.hasOwnProperty("categories_ids") && (
        <InlineLabel
          label={t("vendors.fields.labels.categories_ids")}
          labelWidth={110}
          isRequired={optionalFields.categories_ids.required}
        >
          <Select
            options={categoriesOptions}
            name="categories"
            value={form.values.categories}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            errorMessage={form.touched.categories && form.errors.categories}
            onSearch={onCategoriesSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("assets_ids") && (
        <InlineLabel
          label={t("vendors.fields.labels.assets_ids")}
          labelWidth={110}
          isRequired={optionalFields.assets_ids.required}
        >
          <SelectCreatable
            options={assetsOptions}
            name="assets"
            value={form.values.assets}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            errorMessage={form.touched.assets && form.errors.assets}
            optionCreateModal={<AssetCreate />}
            onSearch={onAssetsSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
          />
        </InlineLabel>
      )}
      {showParts && (
        <InlineLabel
          label={t("vendors.fields.labels.parts_ids")}
          labelWidth={110}
          isRequired={optionalFields.parts_ids.required}
        >
          <SelectCreatable
            options={partsOptions}
            name="parts"
            value={form.values.parts}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            errorMessage={form.touched.parts && form.errors.parts}
            onSearch={onPartsSearch}
            onTouch={form.handleSetTouched}
            noOptionsMessage={t("noOptions")}
            optionCreateModal={<PartCreate />}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("rating") && (
        <InlineLabel
          label={t("vendors.fields.labels.rating")}
          labelWidth={110}
          isRequired={optionalFields.rating.required}
        >
          <StarRatings
            name="rating"
            value={form.values.rating}
            onChange={form.handleSetValue}
            width="34px"
          />
        </InlineLabel>
      )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
