import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Header } from "widgets/Header";
import {
  BacklogByPriorityReportWidget,
  BacklogByStatusReportWidget,
  MaintenanceHistoryByCostReportWidget,
  WorkOrderCompletionReportWidget,
  BacklogByAssigneeReportWidget,
  ReportListWidget,
  ReactiveVsPreventiveReportWidget,
  CreatedVsCompletedReportWidget,
  AssetCostsAndDowntimeReportWidget,
  AssetCostsReportWidget,
  WorkOrderCompletedByCategoryReportWidget,
  MaintenanceHistoryByAssigneeReportWidget,
  PartsInventoryReportWidget,
} from "modules/reporting";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { usePermission } from "app/providers/PermissionsProvider";
import { ReportPeriodSelect } from "shared/components/ReportPeriodSelect";
import { reportPeriodOptions } from "modules/reporting/lib/reportPeriodOptions";
import { ReactElement } from "react";
import { useGetDowntimeTrackingSettingsQuery } from "modules/downtimeTracking";

export const ReportListPage = () => {
  const [reportPeriod, setReportPeriod] = useReportPeriod();

  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });
  const downtimeTrackingEnabled = downtimeTrackingSettings?.enabled ?? false;

  const { t } = useTranslation();
  const allWidgets: { [key: string]: ReactElement } = {
    [ReportKey.BacklogByStatus]: <BacklogByStatusReportWidget />,
    [ReportKey.BacklogByAssignee]: <BacklogByAssigneeReportWidget />,
    [ReportKey.BacklogByPriority]: <BacklogByPriorityReportWidget />,
    [ReportKey.CreatedVsCompleted]: <CreatedVsCompletedReportWidget />,
    [ReportKey.ReactiveVsPreventive]: <ReactiveVsPreventiveReportWidget />,
    [ReportKey.MaintenanceHistoryByAssignee]: (
      <MaintenanceHistoryByAssigneeReportWidget />
    ),
    [ReportKey.AssetCostsAndDowntime]: <AssetCostsAndDowntimeReportWidget />,
    [ReportKey.AssetCosts]: <AssetCostsReportWidget />,
    [ReportKey.HistoryByCost]: <MaintenanceHistoryByCostReportWidget />,
    [ReportKey.OntimeVsOverdue]: <WorkOrderCompletionReportWidget />,
    [ReportKey.CompletedByCategory]: (
      <WorkOrderCompletedByCategoryReportWidget />
    ),
    [ReportKey.PartsInventory]: <PartsInventoryReportWidget />,
  };
  const navigate = useNavigate();
  const {
    enableWoByAssigneeReportPage,
    enableMaintenanceHistoryByAssigneeReportPage,
    enablePartsInventoryReports,
    enableAssetCostsDowntimeReport,
  } = useFlags();
  const {
    reportsAssigneePermit,
    reportsPriorityPermit,
    reportsStatusPermit,
    reportsCostPermit,
    reportsOverduePermit,
  } = usePermission();
  const buildWidget = (reportKey: string) => {
    return (
      <ReportListWidget
        label={t(`reporting.${reportKey}.title`)}
        onClickExpand={() => navigate(`/reporting/${reportKey}`)}
      >
        {allWidgets[reportKey]}
      </ReportListWidget>
    );
  };

  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <Header
        title={t("reporting.title")}
        action={
          <PeriodSelectWrapper>
            <ReportPeriodSelect
              selected={reportPeriod}
              onChange={setReportPeriod}
              options={reportPeriodOptions}
            />
          </PeriodSelectWrapper>
        }
      />
      <ReportList>
        {reportsStatusPermit && buildWidget(ReportKey.BacklogByStatus)}
        {reportsPriorityPermit && buildWidget(ReportKey.BacklogByPriority)}
        {enableWoByAssigneeReportPage &&
          reportsAssigneePermit &&
          buildWidget(ReportKey.BacklogByAssignee)}
        {buildWidget(ReportKey.CreatedVsCompleted)}
        {buildWidget(ReportKey.ReactiveVsPreventive)}
        {reportsOverduePermit && buildWidget(ReportKey.OntimeVsOverdue)}
        {enableMaintenanceHistoryByAssigneeReportPage &&
          buildWidget(ReportKey.MaintenanceHistoryByAssignee)}
        {buildWidget(ReportKey.CompletedByCategory)}
        {reportsCostPermit && buildWidget(ReportKey.HistoryByCost)}
        {enableAssetCostsDowntimeReport &&
          (downtimeTrackingEnabled
            ? buildWidget(ReportKey.AssetCostsAndDowntime)
            : buildWidget(ReportKey.AssetCosts))}
        {enablePartsInventoryReports &&
          reportsAssigneePermit &&
          buildWidget(ReportKey.PartsInventory)}
      </ReportList>
    </ReportPageProvider>
  );
};

const ReportList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  grid-auto-rows: 425px;
  padding: 16px 40px;

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const PeriodSelectWrapper = styled.div`
  width: 325px;
`;
