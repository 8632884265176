import { useTranslation } from "react-i18next";
import { InlineLabel } from "shared/components/widgets";
import { Select } from "shared/components/Select";
import styled from "styled-components";
import { useFlags } from "launchdarkly-react-client-sdk";

export const LanguageEditor = ({ form }) => {
  const { t } = useTranslation();
  const { enableGermanPortuguese } = useFlags();

  const languageOptions = [
    {
      options: [
        { value: "en", label: t("profile.language.options.en") },
        { value: "fr", label: t("profile.language.options.fr") },
        { value: "es", label: t("profile.language.options.es") },
      ],
    },
  ];

  if (enableGermanPortuguese) {
    languageOptions[0].options.push(
      { value: "de", label: t("profile.language.options.de") },
      { value: "pt", label: t("profile.language.options.pt") }
    );
  }

  return (
    <Container>
      <InlineLabel label={t("profile.language.title")} labelWidth={110}>
        <Select
          options={languageOptions}
          name="language"
          value={form.values.language}
          setValue={form.handleSetValue}
          onBlur={form.handleBlur}
          noOptionsMessage={t("noOptions")}
          onTouch={form.handleSetTouched}
          isSearchable={false}
          menuPlacement="bottom"
          placeholder={t("profile.language.title")}
          ariaLabel={t("profile.language.title")}
        />
      </InlineLabel>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 30px;
`;
