import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { useLocaleSettings } from "modules/reporting/lib/useLocaleSettings";
import { MaintenanceHistoryByCostKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { StringifiedFilters } from "modules/reporting/types/StringifiedFilters";

const reportKey = ReportKey.HistoryByCost;

export const useMaintenanceHistoryByCostReportKPIs = (
  reportPeriod?: ReportPeriod,
  stringifiedFilters?: StringifiedFilters
) => {
  const { data, ...rest } = useGetKPIs<MaintenanceHistoryByCostKPIs>(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );
  const { t } = useTranslation();
  const { language, currency } = useLocaleSettings();
  const keys: (keyof MaintenanceHistoryByCostKPIs)[] = [
    "labor_cost",
    "avg_labor_cost",
    "parts_cost",
    "avg_parts_cost",
    "other_cost",
    "avg_other_cost",
    "total_cost",
    "avg_total_cost",
  ];
  return {
    ...rest,
    data: keys.map((i) => ({
      value: formatCurrency(data?.[i], language, currency ?? "USD"),
      label: t(`reporting.${reportKey}.kpis.${i}`),
    })),
  };
};
