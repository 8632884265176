import styled, { css } from "styled-components";
import { H2 } from "shared/components/Typography";
import { Divider } from "shared/components/Divider";

export type HeaderVariant = "dark" | "light" | "success";

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`;

export const ModalContentWrapper = styled.div<{ noScroll: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: ${({ noScroll }) => (noScroll ? "visible" : "hidden")};
`;

export const TopHeader = styled.div<{ variant: HeaderVariant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;

  ${({ variant }) =>
    variant === "light" &&
    css`
      background: var(--color_background_light);
      height: 50px;
      padding: 18px 16px 0;
      box-sizing: border-box;
    `}

  ${({ variant }) =>
    (variant === "dark" || variant === "success") &&
    css`
      background: var(--color_background);
      height: 40px;
      padding: 8px 15px;
    `}
`;

export const SubHeader = styled.div<{ variant: HeaderVariant }>`
  background-color: ${({ variant }) =>
    variant === "success"
      ? "var(--color_background_success);"
      : "var(--color_background_light);"}
  transition: background-color 0.33s ease-in-out;
  padding: 12px 32px;
  display: flex;
  gap: 8px;
  position: relative;
  border-bottom: 1px solid var(--color_grey200) 16;

  &::after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    width: 100%;
    height: 8px;
    bottom: -8px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.16) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const BodyWrapper = styled.div`
  width: 560px;
  padding: 40px 30px;
`;

export const Label = styled.p<{ variant: HeaderVariant }>`
  font-weight: 600;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ variant }) =>
    variant === "light" &&
    css`
      font-size: 20px;
      line-height: 1.3;
      color: var(--color_grey900);
    `}

  ${({ variant }) =>
    (variant === "dark" || variant === "success") &&
    css`
      font-size: 16px;
      line-height: 115%;
      color: var(--color_text_header);
    `}
`;

export const ActionBlock = styled.div`
  display: flex;
  & > button {
    margin-right: 8px;
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const ViewHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HintWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ViewHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewName = styled(H2)`
  margin-bottom: 20px;
`;

export const HeaderDividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
