import { useGetUserPermissionsQuery } from "modules/users/state/usersApi";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../../shared/components/Auth/AuthProvider";

const PermissionsContext = createContext({});
/** @returns {Record<string, boolean | (args) => boolean>} */
export const usePermission = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
  const user = getUser();
  const [permissions, setPermissions] = useState(user?.permissions || {});

  const currentUser = getUser();
  const { currentData } = useGetUserPermissionsQuery(currentUser?.id) || {};

  useEffect(() => {
    if (currentData?.data) {
      setPermissions(currentData.data.permissions);
    }
  }, [currentData]);

  const entitiesPermit = permissions["navigationViewEntity"]; // View entities

  const assetsListPermit = permissions["navigationViewAssets"]; //View the list of assets
  const assetsListExportPermit = permissions["assetsListExportXlsx"]; //Export list of assets in XLS file
  const assetsListExportQrCodesPermit = permissions["assetsListExportQrCodes"]; //Export list of asset QR codes
  const assetCreatePermit = permissions["assetsDetailsAdd"]; //Add an asset
  const assetViewPermit = permissions["assetsDetailsView"]; //View single asset details
  const assetUpdatePermit = permissions["assetsDetailsEdit"]; //Edit asset
  const assetDeletePermit = permissions["assetsDetailsDelete"]; //Delete asset
  const assetClonePermit = permissions["assetsDetailsClone"]; //Clone an asset
  const assetsListEditPermit = permissions["assetsListEdit"]; //Edit assets location
  const assetsListDeletePermit = permissions["assetsListDelete"]; //Delete assets
  const assetsViewAssociationsPermit =
    permissions["assetsDetailsViewAssociations"]; //View list of associated work orders
  const assetsViewAssociationsChartPermit =
    permissions["assetsDetailsViewAssociationsChart"]; //View work order history chart
  const assetsViewSubRecordsPermit = permissions["assetsDetailsViewSubRecords"]; //View asset sub records tree
  const assetsAddSubRecordsPermit = permissions["assetsDetailsAddSubRecords"]; //Add asset sub records tree
  const assetsStatusesAddDowntimePermit =
    permissions["assetsStatusesAddDowntime"]; // Add downtime to asset
  const assetsViewDowntimeHistoryPermit =
    permissions["assetsStatusesViewDowntimeHistory"]; // View downtime of asset history

  const locationsListPermit = permissions["navigationViewLocations"]; //View the list of locations
  const locationsListExportQrCodesPermit =
    permissions["locationsListExportQrCodes"]; //Export list of location QR codes
  const locationCreatePermit = permissions["locationsDetailsAdd"]; //Add a location
  const locationViewPermit = permissions["locationsDetailsView"]; //View single location details
  const locationUpdatePermit = permissions["locationsDetailsEdit"]; // Edit location
  const locationDeletePermit = permissions["locationsDetailsDelete"]; //Delete location
  const locationsViewSubRecordsPermit =
    permissions["locationsDetailsViewSubRecords"]; //View location sub records tree
  const locationsAddSubRecordsPermit =
    permissions["locationsDetailsAddSubRecords"]; //Add location sub records tree

  const partsListPermit = permissions["navigationViewParts"]; //View the list of parts
  const partsListExportPermit = permissions["partsListExportXlsx"]; //Export list of parts in XLS file
  const partsListExportQrCodesPermit = permissions["partsListExportQrCodes"]; //Export list of part QR codes
  const partsListDeletePermit = permissions["partsListDelete"]; //Delete parts
  const partCreatePermit = permissions["partsDetailsAdd"]; // Add a part
  const partViewPermit = permissions["partsDetailsView"]; //View single part details
  const partUpdatePermit = permissions["partsDetailsEdit"]; //Edit single part details
  const partDeletePermit = permissions["partsDetailsDelete"]; //Delete a single part
  const partClonePermit = permissions["partsDetailsClone"]; //Clone a part
  const partRestockPermit = permissions["partsDetailsRestock"]; //Restock a part
  const partUpdateCostPermit = permissions["partsDetailsUpdateCost"]; //Update non-stock part cost

  const vendorsListPermit = permissions["navigationViewVendors"]; //View list of vendors
  const vendorsListExportPermit = permissions["vendorsListExportXlsx"]; //Export list of vendors as XLS file
  const vendorsListDeletePermit = permissions["vendorsListDelete"]; //Delete vendors
  const vendorCreatePermit = permissions["vendorsDetailsAdd"]; //Add a vendor
  const vendorViewPermit = permissions["vendorsDetailsView"]; //View details of a single vendor
  const vendorUpdatePermit = permissions["vendorsDetailsEdit"]; //Edit details of a single vendor
  const vendorDeletePermit = permissions["vendorsDetailsDelete"]; //Delete a vendor
  const vendorChangeStatusPermit = permissions["vendorsDetailsChangeStatus"]; //Make a vendor active/inactive

  const vendorContactListPermit = permissions["vendorsContactsView"]; //View the list of vendor contacts
  const vendorCreateContactPermit = permissions["vendorsContactsAdd"]; //Add a new vendor contact
  const vendorUpdateContactPermit = permissions["vendorsContactsEdit"]; //Edit a vendor contact
  const vendorDeleteContactPermit = permissions["vendorsContactsDelete"]; //Delete a vendor contact
  const vendorContactsMakePrimaryPermit =
    permissions["vendorsContactsMakePrimary"]; //Mark a vendor contact as primary

  const requestsListPermit = permissions["navigationViewWorkOrderRequests"]; //View the list of work order requests
  const requestsListExportPermit =
    permissions["workOrderRequestsListExportXlsx"]; //Export list of work order requests in XLS file
  const requestsDeclineListPermit = permissions["workOrderRequestsListDecline"]; //Decline work order requests
  const requestsPutOnHoldListPermit =
    permissions["workOrderRequestsListPutOnHold"]; //Put work order requests on hold
  const requestApproveListPermit = permissions["workOrderRequestsListApprove"]; //Approve work order request in the list of requests
  const requestCreatePermit = permissions["workOrderRequestsDetailsAdd"]; //Create work order request
  const requestViewPermit = permissions["workOrderRequestsDetailsView"]; //View details of work order request
  const requestUpdatePendingPermit =
    permissions["workOrderRequestsDetailsEditPending"]; //Edit work order request in “Pending” status
  const requestUpdatePermit = permissions["workOrderRequestsDetailsEdit"]; //Edit work order request in any status except “Pending”
  const requestDeletePendingPermit =
    permissions["workOrderRequestsDetailsDeletePending"]; //Delete work order request in “Pending” status
  const requestDeletePermit = permissions["workOrderRequestsDetailsDelete"]; //Delete work order request in any status except “Pending”
  const requestApprovePermit = permissions["workOrderRequestsDetailsDecline"]; //Approve work order request
  const requestDeclinePermit = permissions["workOrderRequestsDetailsPutOnHold"]; // Decline work order request
  const requestPutOnHoldPermit = permissions["workOrderRequestsDetailsApprove"]; //Put work order request on hold

  const procedureTemplateListView = permissions["procedureTemplateListView"]; // View the list of Procedure templates
  const procedureTemplateViewPermit = permissions["procedureTemplateShow"]; // View details of a Procedure template
  const procedureTemplateCreatePermit = permissions["procedureTemplateAdd"]; // Create a new Procedure template

  // Delete a Procedure template
  const procedureTemplateDeletePermit = (createdById) =>
    permissions["procedureTemplateDelete"] || createdById === user.id;

  // Delete an item from a Procedure template
  const procedureTemplateItemDeletePermit = (createdById) =>
    permissions["procedureTemplateItemDelete"] || createdById === user.id;

  // Update a Procedure template
  const procedureTemplateEditPermit = (createdById) =>
    permissions["procedureTemplateUpdate"] || createdById === user.id;

  // Add a Procedure to a WO
  const procedureAddPermit = (compareUserId, technicians = []) =>
    permissions["procedureAdd"] ||
    compareUserId === user.id ||
    technicians?.some((technician) => technician.id === user.id);

  // Delete a Procedure from a WO
  const procedureDeletePermit = (createdById) =>
    permissions["procedureDelete"] || createdById === user.id;

  // Update a Procedure item from a WO
  const procedureItemUpdatePermit = (createdById, workOrder) => {
    return (
      permissions["procedureItemUpdate"] ||
      createdById === user.id ||
      workOrder?.technicians?.some((technician) => technician.id === user.id) ||
      workOrder.created_by_id === user.id
    );
  };

  // Complete and reopen a completed Procedure from a WO
  const procedureCompletePermit = (compareUserId, workOrder) =>
    permissions["procedureComplete"] ||
    compareUserId === user.id ||
    workOrder?.technicians?.some((technician) => technician.id === user.id) ||
    workOrder.created_by_id === user.id;

  //Only requests created by user for technician and requester
  const canUpdateRequest = (status, createdById) => {
    if (status === "pending") {
      if (
        !requestUpdatePendingPermit ||
        (["technician", "requester"].includes(user.role) &&
          createdById !== user.id)
      )
        return false;
    } else {
      if (
        !requestUpdatePermit ||
        ["technician", "requester"].includes(user.role)
      ) {
        return false;
      }
    }

    return true;
  };

  const canDeleteRequest = (status, createdById) => {
    if (status === "pending") {
      if (
        !requestDeletePendingPermit ||
        (["technician", "requester"].includes(user.role) &&
          createdById !== user.id)
      ) {
        return false;
      }
      return true;
    }
    if (["on_hold", "declined"].includes(status)) {
      if (["technician", "requester"].includes(user.role)) {
        return false;
      }
      return true;
    }
    if (["approved", "completed"].includes(status)) {
      return false;
    }
  };

  const woListPermit = permissions["navigationViewWorkOrders"]; //View the list of work orders
  const woListExportPermit = permissions["workOrdersListExportXlsx"]; //Export list of work orders in XLS file
  const woListPrintPermit = permissions["workOrdersListExportPDF"]; //Print off work orders
  const woListDeletePermit = permissions["workOrdersListDelete"]; //Delete work orders
  const woListChangeDueDatePermit = permissions["workOrdersListChangeDueDate"]; //Change the due date of work orders
  const woListChangeAssigneePermit =
    permissions["workOrdersListChangeAssignee"]; //Change the assignees of work orders
  const woListChangeStatusPermit = permissions["workOrdersListChangeStatus"]; //Change the status of work order (except to “Completed” if “In review” status is enabled and from “Completed” to any other)
  const woListCompletePermit = permissions["workOrdersListComplete"]; //Change the status to “Completed” if “In review” status is enabled
  const woListChangeCompletedPermit =
    permissions["workOrdersListChangeCompleted"]; //Change the status from “Completed” to any other
  const woListChangePriorityPermit =
    permissions["workOrdersListChangePriority"]; //Change priority of work orders
  const woCreatePermit = permissions["workOrdersDetailsAdd"]; //Create work orders
  const woViewPermit = permissions["workOrdersDetailsView"]; //View details of work orders
  const woViewChecklistPermit = permissions["workOrdersDetailsViewChecklist"]; //View checklist items of work orders
  const woUpdateChecklistPermit = permissions["workOrdersDetailsEditChecklist"]; //Mark checklist items as complete
  const woUpdateCompletedPermit = permissions["workOrdersDetailsEditCompleted"]; //Edit work orders in any status except “Completed”
  const woUpdatePermit = permissions["workOrdersDetailsEdit"]; //Edit work orders in the “Completed” status
  const woDeletePermit = permissions["workOrdersDetailsDelete"]; //Delete work order
  const woExportPDFPermit = permissions["workOrdersDetailsExportPDF"]; //Export work order in pdf file from the detailed view

  // Only assigned work orders or created by user that are not in the “Completed” status for technicians
  const canUpdateWOChecklist = (technicians, createdById, status) => {
    if (!woUpdateChecklistPermit) return false;
    if (user.role === "technician") {
      return (
        technicians.some((technician) => technician.id === user.id) ||
        (user.id === createdById && status.name !== "completed")
      );
    }
    return true;
  };

  //Only work orders created by user that are not in the “Completed” status for technicians
  const canDeleteWO = (createdById, status) => {
    if (!woDeletePermit) return false;
    if (user.role === "technician") {
      return user.id === createdById && status.name !== "completed";
    }
    return true;
  };

  //Only work orders created by user or assigned to user.
  const canUpdateWO = (createdById, technicians, status) => {
    if (status?.name === "completed") return woUpdatePermit;
    if (!woUpdateCompletedPermit) return false;
    if (user.role === "technician") {
      return (
        user.id === createdById ||
        technicians?.some((technician) => technician.id === user.id)
      );
    }
    return true;
  };

  const woCommentListPermit = permissions["workOrdersCommentsView"]; //View history of work order comments
  const woCreateCommentPermit = permissions["workOrdersCommentsAdd"]; //Write a comment on a work order
  const woUpdateCommentPermit = permissions["workOrdersCommentsEdit"]; //Edit a comment on a work order
  const woDeleteCommentPermit = permissions["workOrdersCommentsDelete"]; //Delete a comment from a work order
  const worCommentListPermit = permissions["workOrdersRequestsCommentsView"]; //View history of work order request comments
  const worCreateCommentPermit = permissions["workOrdersRequestsCommentsAdd"]; //Write a comment on a work order request
  const worUpdateCommentPermit = permissions["workOrdersRequestsCommentsEdit"]; //Edit a comment on a work order request
  const worDeleteCommentPermit =
    permissions["workOrdersRequestsCommentsDelete"]; //Delete a comment from a work order request

  const woChangeStatusPermit = permissions["workOrdersDetailsChangeStatus"]; //Change the status of work order
  const woChangeCompleteStatusPermit = permissions["workOrdersDetailsComplete"]; //Change the status to “Completed” if “In review” status is enabled
  const woChangeCompletedStatusPermit =
    permissions["workOrdersDetailsChangeCompleted"]; //Change the status from “Completed” to any other

  const canUpdateStatusWO = (createdById, technicians, status) => {
    if (status?.name === "completed") return woChangeCompletedStatusPermit;
    if (!woChangeStatusPermit) return false;
    if (user.role === "technician") {
      return (
        user.id === createdById ||
        technicians?.some((technician) => technician.id === user.id)
      );
    }
    return true;
  };

  // View history of work order changes
  const woHistoryListPermit = permissions["workOrdersHistoryChangesView"];

  // Work Order Calendar
  const woCalendarPermit = permissions["workOrdersCalendarView"];

  const woCalendarReschedulingPermit =
    permissions["workOrdersCalendarReschedule"];

  const canRescheduleCalendar = (createdById, status) => {
    if (!woCalendarReschedulingPermit) return false;
    if (user.role === "technician") {
      if (+user.id !== +createdById) {
        return "173";
      }
      if (status?.name === "completed") {
        return "174";
      }
    }
    return true;
  };

  // Work Order Parts
  const woPartsViewPermit = permissions["workOrdersPartsView"];
  const woPartsAddPermit = permissions["workOrdersPartsAdd"];
  const woPartsUpdatePermit = permissions["workOrdersPartsEdit"];
  const woPartsDeletePermit = permissions["workOrdersPartsDelete"];

  const canAddPartWO = (createdById, technicians = [], status) => {
    if (!woPartsAddPermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  const canUpdatePartWO = (createdById, technicians = [], status) => {
    if (!woPartsUpdatePermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  const canDeletePartWO = (createdById, technicians = [], status) => {
    if (!woPartsDeletePermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  // work orders time
  const woTimesViewPermit = permissions["workOrdersTimesView"];
  const woTimesAddPermit = permissions["workOrdersTimesAdd"];
  const woTimesUpdatePermit = permissions["workOrdersTimesEdit"];
  const woTimesDeletePermit = permissions["workOrdersTimesDelete"];
  const woStartTimerPermit = permissions["workOrdersTimesStartTimer"];

  const canViewTimeWO = () => {
    if (!woTimesViewPermit) return false;
    if (user.role === "technician") {
      return "partial";
    }
    return true;
  };

  const canAddTimeWO = (technicians, status) => {
    if (!woTimesAddPermit) return false;
    if (user.role === "technician") {
      return (
        technicians?.some((technician) => technician.id === user.id) &&
        status?.name !== "completed"
      );
    }
    return true;
  };

  const canShowUserField = () => {
    if (user.role === "admin" || user.role === "limited_admin") {
      return true;
    }
    return false;
  };

  const canUpdateTimeWO = (createdById, technicians, status) => {
    if (!woTimesUpdatePermit) return false;
    if (user.role === "technician") {
      return (
        technicians?.some((technician) => technician === user.id) &&
        status?.name !== "completed" &&
        user.id === createdById
      );
    }
    return true;
  };
  const canDeleteTimeWO = (createdById, technicians, status) => {
    if (!woTimesDeletePermit) return false;
    if (user.role === "technician") {
      return (
        technicians?.some((technician) => technician.id === user.id) &&
        status?.name !== "completed" &&
        user.id === createdById
      );
    }
    return true;
  };

  const canStartTimerWO = (technicians, status) => {
    if (!woStartTimerPermit) return false;
    if (["technician", "admin", "limited_admin"].includes(user.role)) {
      return (
        technicians?.some((technician) => technician.id === user.id) &&
        status?.name !== "completed"
      );
    }
    return true;
  };

  // work orders cost
  const woOtherCostViewPermit = permissions["workOrdersOtherCostsView"];
  const woOtherCostAddPermit = permissions["workOrdersOtherCostsAdd"];
  const woOtherCostDeletePermit = permissions["workOrdersOtherCostsDelete"];
  const woOtherCostEditPermit = permissions["workOrdersOtherCostsEdit"];

  const canAddOtherCostWo = (createdById, technicians, status) => {
    if (!woOtherCostAddPermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  const canEditOtherCostWo = (createdById, technicians, status) => {
    if (!woOtherCostEditPermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  const canDeleteOtherCostWo = (createdById, technicians, status) => {
    if (!woOtherCostDeletePermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
      return false;
    }
    return true;
  };

  // work order totals view
  const woTotalsViewPermit = permissions["workOrdersTotalsView"];

  const canShowTotalCost = (fields) => {
    if (!woTotalsViewPermit)
      return fields.reduce((acc, item) => {
        acc[item] = false;
        return acc;
      }, {});

    if (user.role === "technician") {
      return fields.reduce((acc, item) => {
        acc[item] = item === "labor" || item === "total" ? false : true;
        return acc;
      }, {});
    }
    return fields.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
  };

  // PMs
  const pmsViewPermit = permissions["navigationViewPms"];
  const pmsListExportPermit = permissions["pmsListExportXlsx"];
  const pmsPrintPermit = permissions["pmsListExportPDF"];
  const pmsCanPauseResumePms = permissions["pmsListControlSchedule"];
  const pmsCanChangeAssigneePms = permissions["pmsListChangeAssignee"];
  const pmsCanChangePriorityPms = permissions["pmsListChangePriority"];

  const canChangeAssigneePms = (createdById, status) => {
    if (!pmsCanChangeAssigneePms) return false;
    if (user.role === "technician") {
      return user.id === createdById && status?.name === "completed";
    }
    return true;
  };

  const canChangePriorityPms = (createdById, status) => {
    if (!pmsCanChangePriorityPms) return false;
    if (user.role === "technician") {
      return user.id === createdById && status?.name === "completed";
    }
    return true;
  };

  const pmsDetailsAddPermit = permissions["pmsDetailsAdd"];
  const pmsDetailsViewPermit = permissions["pmsDetailsView"];
  const pmsDetailsViewChecklistPermit = permissions["pmsDetailsViewChecklist"];
  const pmsDetailsChangeStatusPermit = permissions["pmsDetailsChangeStatus"];
  const pmsDetailsDeletePermit = permissions["pmsDetailsDelete"];
  const pmsDetailsEditPermit = permissions["pmsDetailsEdit"];
  const pmsDetailsExportPdfPermit = permissions["pmsDetailsExportPDF"];

  const canDeletePmsSchedule = (createdById, status) => {
    if (!pmsDetailsDeletePermit) return false;
    if (user.role === "technician") {
      return user.id === createdById && status?.name !== "completed";
    }
    return true;
  };

  const canEditPmsSchedule = (createdById, technicians, status) => {
    if (!pmsDetailsEditPermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        if (user.id === createdById) return true;
        if (technicians?.some((technician) => technician.id === user.id)) {
          return "partial";
        }
        return false;
      }
      return false;
    }
    return true;
  };

  // pms parts

  const pmsPartsViewPermit = permissions["pmsListView"];
  const pmsPartsUpdatePermit = permissions["pmsPartsEdit"];
  const pmsPartsDeletePermit = permissions["pmsPartsDelete"];
  const pmsPartsAddPermit = permissions["pmsPartsAdd"];

  const canAddPartPms = (createdById, technicians = [], status) => {
    if (!pmsPartsAddPermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
    }
    return true;
  };

  const canUpdatePartPms = (createdById, technicians = [], status) => {
    if (!pmsPartsUpdatePermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
    }
    return true;
  };
  const canDeletePartPms = (createdById, technicians = [], status) => {
    if (!pmsPartsDeletePermit) return false;
    if (user.role === "technician") {
      if (status?.name !== "completed") {
        return (
          user.id === createdById ||
          technicians?.some((technician) => technician.id === user.id)
        );
      }
    }
    return true;
  };

  // pms history
  const pmsHistoryPermit = permissions["pmsHistoryView"]; // View work order history list on a given PM
  const pmsHistoryChartPermit = permissions["pmsHistoryChartView"]; // View work order history chart on a given PM

  // users & teams
  const usersListViewPermit = permissions["usersListView"];
  const usersDetailsViewPermit = permissions["usersDetailsView"];
  const usersDetailsEditPermit = permissions["usersDetailsEdit"];
  const usersDetailsInvitePermit = permissions["usersDetailsInvite"];
  const usersDetailsCancelInvitePermit =
    permissions["usersDetailsCancelInvite"];
  const usersDetailsViewInvitePermit = permissions["usersDetailsViewInvite"];
  const usersDetailsResendInvitePermit =
    permissions["usersDetailsResendInvite"];
  const usersDetailsEditInvitePermit = permissions["usersDetailsEditInvite"];
  const usersDetailsRemoveAccessPermit =
    permissions["usersDetailsRemoveAccess"];

  const usersPermit = permissions["navigationViewUsers"];
  const reportsPriorityPermit = permissions["reportsPriority"];
  const reportsStatusPermit = permissions["reportsStatus"];
  const reportsCostPermit = permissions["reportsCost"];
  const reportsAssigneePermit = permissions["reportsAssignee"];
  const reportsOverduePermit = permissions["reportsOverdue"];
  const reportsPermit =
    reportsPriorityPermit ||
    reportsStatusPermit ||
    reportsCostPermit ||
    reportsAssigneePermit ||
    reportsOverduePermit;

  const supportPermit = permissions["navigationViewSupportInfo"];
  const profilePermit = permissions["userProfileView"];

  // notifications

  const notificationsEmailWorkOrderAssetPermission =
    permissions["notificationsEmailWorkOrderAsset"];

  const notificationsEmailWorkOrderAssignedPermission =
    permissions["notificationsEmailWorkOrderAssigned"];

  const notificationsEmailWorkOrderCreatedPermission =
    permissions["notificationsEmailWorkOrderCreated"];
  const notificationsEmailWorkOrderPartPermission =
    permissions["notificationsEmailWorkOrderPart"];
  const notificationsEmailWorkOrderReportPermission =
    permissions["notificationsEmailWorkOrderReport"];
  const notificationsEmailWorkOrderRequestPermission =
    permissions["notificationsEmailWorkOrderRequest"];
  const notificationsEmailWorkOrderToReviewPermission =
    permissions["notificationsEmailWorkOrderToReview"];

  //admin
  const adminSettingsAssetPermit = permissions["adminSettingsAsset"];
  const adminSettingsPartPermit = permissions["adminSettingsPart"];
  const adminSettingsVendorPermit = permissions["adminSettingsVendor"];
  const adminSettingsWorkOrderRequestPermit =
    permissions["adminSettingsWorkOrderRequest"];
  const adminSettingsWorkOrderPermit = permissions["adminSettingsWorkOrder"];
  const adminSettingsUsersPermit = permissions["adminSettingsUsers"];
  const adminSettingsAssetTypesPermit = permissions["adminSettingsAssetTypes"];
  const adminSettingsPartTypesPermit = permissions["adminSettingsPartTypes"];
  const adminSettingsEntitiesPermit = permissions["adminSettingsEntities"];
  const adminSettingsCustomerPermit = permissions["adminSettingsCustomer"];
  const adminSettingsIntegrationPermit = permissions["adminSettingsCustomer"];
  const adminSettingsCategoriesPermit = permissions["adminSettingsCategories"];
  const adminSettingsStoreroomsPermit = permissions["adminSettingsStorerooms"];
  const adminSettingsLocationsPermit = permissions["adminSettingsLocations"];

  const adminSettingsDataImportPermit = permissions["adminSettingsDataImport"];
  const adminPermit =
    adminSettingsAssetPermit ||
    adminSettingsPartPermit ||
    adminSettingsVendorPermit ||
    adminSettingsWorkOrderRequestPermit ||
    adminSettingsWorkOrderPermit ||
    adminSettingsUsersPermit ||
    adminSettingsAssetTypesPermit ||
    adminSettingsPartTypesPermit ||
    adminSettingsEntitiesPermit ||
    adminSettingsCustomerPermit ||
    adminSettingsDataImportPermit;

  const adminManageModulesPermit =
    adminSettingsAssetPermit ||
    adminSettingsPartPermit ||
    adminSettingsVendorPermit ||
    adminSettingsWorkOrderRequestPermit ||
    adminSettingsWorkOrderPermit ||
    adminSettingsAssetTypesPermit ||
    adminSettingsPartTypesPermit;

  const notificationTabForRequestersVisible =
    notificationsEmailWorkOrderAssetPermission ||
    notificationsEmailWorkOrderAssignedPermission ||
    notificationsEmailWorkOrderCreatedPermission ||
    notificationsEmailWorkOrderPartPermission ||
    notificationsEmailWorkOrderReportPermission ||
    notificationsEmailWorkOrderRequestPermission ||
    notificationsEmailWorkOrderToReviewPermission;

  return (
    <PermissionsContext.Provider
      value={{
        entitiesPermit,
        //assets
        assetsListPermit,
        assetsListExportQrCodesPermit,
        assetsListExportPermit,
        assetCreatePermit,
        assetViewPermit,
        assetUpdatePermit,
        assetDeletePermit,
        assetsListEditPermit,
        assetsListDeletePermit,
        assetsViewAssociationsPermit,
        assetsViewAssociationsChartPermit,
        assetClonePermit,
        assetsViewSubRecordsPermit,
        assetsAddSubRecordsPermit,
        assetsStatusesAddDowntimePermit,
        assetsViewDowntimeHistoryPermit,
        //locations
        locationsListPermit,
        locationsListExportQrCodesPermit,
        locationCreatePermit,
        locationViewPermit,
        locationUpdatePermit,
        locationDeletePermit,
        locationsViewSubRecordsPermit,
        locationsAddSubRecordsPermit,
        //parts
        partsListPermit,
        partsListExportQrCodesPermit,
        partCreatePermit,
        partViewPermit,
        partUpdatePermit,
        partDeletePermit,
        partRestockPermit,
        partUpdateCostPermit,
        partsListExportPermit,
        partsListDeletePermit,
        partClonePermit,
        //vendors
        vendorsListPermit,
        vendorsListDeletePermit,
        vendorCreatePermit,
        vendorViewPermit,
        vendorUpdatePermit,
        vendorDeletePermit,
        vendorsListExportPermit,
        vendorChangeStatusPermit,
        //vendor contacts
        vendorContactListPermit,
        vendorCreateContactPermit,
        vendorUpdateContactPermit,
        vendorDeleteContactPermit,
        vendorContactsMakePrimaryPermit,
        //requests
        requestsListPermit,
        requestsListExportPermit,
        requestsDeclineListPermit,
        requestsPutOnHoldListPermit,
        requestApproveListPermit,
        requestCreatePermit,
        requestViewPermit,
        requestUpdatePendingPermit,
        requestUpdatePermit,
        canUpdateRequest,
        canDeleteRequest,
        requestApprovePermit,
        requestDeclinePermit,
        requestPutOnHoldPermit,
        requestDeletePermit,
        //requests comments
        worCommentListPermit,
        worCreateCommentPermit,
        worUpdateCommentPermit,
        worDeleteCommentPermit,
        //work orders
        woListPermit,
        woListExportPermit,
        woListPrintPermit,
        woListDeletePermit,
        woListChangeDueDatePermit,
        woListChangeAssigneePermit,
        woListChangeStatusPermit,
        woListCompletePermit,
        woListChangeCompletedPermit,
        woListChangePriorityPermit,
        woCreatePermit,
        woViewPermit,
        woViewChecklistPermit,
        woUpdateChecklistPermit,
        woUpdateCompletedPermit,
        woUpdatePermit,
        woDeletePermit,
        canUpdateWOChecklist,
        canDeleteWO,
        canUpdateWO,
        canUpdateStatusWO,
        woExportPDFPermit,
        woChangeStatusPermit,
        woChangeCompleteStatusPermit,
        woChangeCompletedStatusPermit,
        //work order comments
        woCommentListPermit,
        woCreateCommentPermit,
        woUpdateCommentPermit,
        woDeleteCommentPermit,
        //work order history
        woHistoryListPermit,
        // work order calendar
        woCalendarPermit,
        canRescheduleCalendar,
        // work order parts
        woPartsViewPermit,
        canUpdatePartWO,
        canAddPartWO,
        canDeletePartWO,

        //work order time
        canViewTimeWO,
        canAddTimeWO,
        canShowUserField,
        canUpdateTimeWO,
        canDeleteTimeWO,
        canStartTimerWO,

        // work order cost
        woOtherCostViewPermit,
        canAddOtherCostWo,
        canEditOtherCostWo,
        canDeleteOtherCostWo,

        //work order shot total cost
        canShowTotalCost,

        //pms
        pmsViewPermit,
        pmsListExportPermit,
        pmsPrintPermit,
        pmsCanPauseResumePms,
        canChangeAssigneePms,
        canChangePriorityPms,
        canEditPmsSchedule,

        // pms details
        pmsDetailsAddPermit,
        pmsDetailsViewPermit,
        pmsDetailsViewChecklistPermit,
        canDeletePmsSchedule,
        pmsDetailsExportPdfPermit,
        pmsDetailsChangeStatusPermit,

        //pms parts
        pmsPartsViewPermit,
        canAddPartPms,
        canUpdatePartPms,
        canDeletePartPms,
        // pms history
        pmsHistoryPermit,
        pmsHistoryChartPermit,

        // procedures
        procedureAddPermit,
        procedureCompletePermit,
        procedureDeletePermit,
        procedureItemUpdatePermit,
        procedureTemplateCreatePermit,
        procedureTemplateDeletePermit,
        procedureTemplateEditPermit,
        procedureTemplateItemDeletePermit,
        procedureTemplateListView,
        procedureTemplateViewPermit,

        //teams
        usersListViewPermit,
        usersDetailsViewPermit,
        usersDetailsEditPermit,
        usersDetailsInvitePermit,
        usersDetailsCancelInvitePermit,
        usersDetailsViewInvitePermit,
        usersDetailsResendInvitePermit,
        usersDetailsEditInvitePermit,
        usersDetailsRemoveAccessPermit,

        usersPermit,
        reportsPriorityPermit,
        reportsStatusPermit,
        reportsCostPermit,
        reportsAssigneePermit,
        reportsOverduePermit,
        reportsPermit,
        supportPermit,
        profilePermit,

        //notifications
        notificationsEmailWorkOrderAssetPermission,
        notificationsEmailWorkOrderAssignedPermission,
        notificationsEmailWorkOrderCreatedPermission,
        notificationsEmailWorkOrderPartPermission,
        notificationsEmailWorkOrderReportPermission,
        notificationsEmailWorkOrderRequestPermission,
        notificationsEmailWorkOrderToReviewPermission,
        notificationTabForRequestersVisible,

        // admin
        adminSettingsAssetPermit,
        adminSettingsPartPermit,
        adminSettingsVendorPermit,
        adminSettingsWorkOrderRequestPermit,
        adminSettingsWorkOrderPermit,
        adminSettingsUsersPermit,
        adminSettingsAssetTypesPermit,
        adminSettingsPartTypesPermit,
        adminSettingsEntitiesPermit,
        adminSettingsCustomerPermit,
        adminSettingsCategoriesPermit,
        adminSettingsDataImportPermit,
        adminPermit,
        adminSettingsIntegrationPermit,
        adminManageModulesPermit,
        adminSettingsLocationsPermit,
        setPermissions,
        adminSettingsStoreroomsPermit,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
