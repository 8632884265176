import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";
// import { getDefaultHeaders } from "../lib/apiTools";
import { getUser } from "shared/components/Auth";
import { getBaseApiUrl } from "../lib/apiTools/getBaseApiUrl";

const baseApiUrl = getBaseApiUrl();
// const defaultHeaders = getDefaultHeaders();

export const createBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: baseApiUrl,
    prepareHeaders: (headers) => {
      // for (const [key, value] of Object.entries(defaultHeaders)) {
      //   headers.set(key, value);
      // }

      headers.set("Access-Control-Allow-Origin", "*");

      const user = getUser();
      if (!user) return headers;

      const { token, entity } = user;

      if (token) {
        headers.set("Authorization", token);
      }

      headers.set("X-Entity-Id", entity ?? 1);

      return headers;
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, {
        encode: false,
        arrayFormat: "brackets",
      });
    },
  });
