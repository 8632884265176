/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from "react";

import {
  useGetPartsQuery,
  useGetPartTypesQuery,
  useGetStoreroomsQuery,
  useGetUnitsQuery,
  useExportPartsMutation,
} from "modules/parts";
import { useGetVendorsQuery } from "modules/vendors";
import { Pagination } from "features/Pagination";
import { useGetAssetsQuery } from "modules/assets";
import { EmptyPartList } from "./EmptyPartList";
import { ErrorPartList } from "./ErrorPartList";
import { Table } from "shared/components/Table";
import {
  useFiltersParam,
  useSearch,
  usePageParam,
  useSortParam,
} from "shared/lib/hooks";
import { TopBar } from "widgets/TopBar";
import { BulkActions } from "../BulkActions";
import { usePartsTableTools } from "../../lib/helpers";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { useSearchParam } from "shared/lib/hooks";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";
import { useTranslation } from "react-i18next";
import { measurementUnits } from "modules/parts/configs/constant";
import { getFiltersByKey } from "modules/filters/lib/filterStorage";
import { ErrorBoundary } from "@sentry/react";
import { PartsKPIs, PartsKPIsFallback } from "../PartsKPIs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetPartsKPIsQuery } from "../../state/partsApi.js";

export const List = ({ onOpenPart = () => {}, withExport, showKpis }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const storedFilters = getFiltersByKey("parts") ?? [];
  const [filters] = useFiltersParam(storedFilters);
  const stringifiedFilters = stringifyFilters(filters, "parts");

  const { columns, tableSettings, applySettings, areTableSettingsFetching } =
    usePartsTableTools({ onOpen: onOpenPart });

  const { data, error, isFetching } = useGetPartsQuery({
    search,
    page,
    per_page: tableSettings?.per_page,
    sort,
    ...stringifiedFilters,
  });
  const queryParams = {
    search,
    sort,
    ...stringifiedFilters,
  };

  const {
    data: kpiData,
    error: kpiError,
    isFetching: kpiIsFetching,
  } = useGetPartsKPIsQuery(queryParams);

  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  useEffect(() => {
    if (data) {
      setSelected([]);
    }
  }, [data]);

  const [partTypes, onPartTypesSearch] = useSearch(useGetPartTypesQuery, true);

  const [storerooms, onStoreroomsSearch] = useSearch(
    useGetStoreroomsQuery,
    true
  );

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);

  const [units, onUnitsSearch] = useSearch(useGetUnitsQuery, true);

  const [vendors, onVendorsSearch] = useSearch(useGetVendorsQuery, true, {
    "filter[parts_supplier_true]": 1,
  });

  const [exportParts] = useExportPartsMutation();

  const getFilterOptions = (field) => {
    if (field === "part_type") {
      return partTypes?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "storeroom") {
      return storerooms?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "assets") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    if (field === "measurement_unit") {
      return units?.data.map(({ id }) => ({
        key: id,
        value: measurementUnits[id].name,
      }));
    }

    if (field === "vendors") {
      return vendors?.data.map(({ id, name }) => ({ key: id, value: name }));
    }

    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "part_type") {
      return onPartTypesSearch;
    }

    if (field === "storeroom") {
      return onStoreroomsSearch;
    }

    if (field === "assets") {
      return onAssetsSearch;
    }

    if (field === "measurement_unit") {
      return onUnitsSearch;
    }
    if (field === "vendors") {
      return onVendorsSearch;
    }

    return () => {};
  };

  const hasFilter = Object.keys(filters).length > 0;
  const empty = data?.data?.length === 0 && !isFetching;
  const notFound = empty && (search?.length > 0 || hasFilter);

  if (error) {
    return <ErrorPartList />;
  } else if (empty && !notFound) {
    return <EmptyPartList />;
  }

  return (
    <>
      {showKpis && (
        <ErrorBoundary fallback={<PartsKPIsFallback />}>
          <PartsKPIs
            data={kpiData}
            error={kpiError}
            isLoading={kpiIsFetching}
          />
        </ErrorBoundary>
      )}
      <TopBar
        entity="parts"
        columns={columns}
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        filterCount={Object.keys(filters).length}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        onExportClick={() => exportParts({ ...stringifiedFilters, search })}
        withExport={withExport}
        initialFilters={storedFilters}
      />
      <Table
        columns={columns}
        rows={data?.data}
        showSkeleton={isLoading || areTableSettingsFetching}
        highlight={search}
        notFound={notFound}
        selectionModel={selected}
        onSelectionModelChange={setSelected}
        entityTranslationKey="parts.select"
        bulkActionsNode={<BulkActions selectionModel={selected} />}
        getRowIcon={(row) => (
          <RowDecoratorImage src={row.images?.[0]?.attachment_urls?.small} />
        )}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("parts.select_other")}
      />
    </>
  );
};
