import { DromoImporter } from "../DromoImporter";
import { SCHEMA_IDS } from "../schemaIds";
import { getUser } from "../../../../shared/components/Auth";
import { ModuleBox, ModuleTitle } from "../styles";

export function LocationImport() {
  const user = getUser();
  user.id = user.id.toString();
  const button = (
    <ModuleBox>
      <ModuleTitle>Locations</ModuleTitle>
    </ModuleBox>
  );
  return (
    <DromoImporter
      schemaId={SCHEMA_IDS.locations}
      settings={{ importIdentifier: "Locations" }}
      buttonText={button}
      user={user}
    />
  );
}
