interface UrlAttribute {
  label: string;
  url: string;
}

export const formatUrl = (url: string): string => {
  if (!url) return "";
  const trimmedUrl = url.trim();

  if (trimmedUrl.startsWith("http://") || trimmedUrl.startsWith("https://")) {
    try {
      return new URL(trimmedUrl).toString();
    } catch {
      return "";
    }
  }

  try {
    return new URL(`https://${trimmedUrl}`).toString();
  } catch {
    return "";
  }
};

export const formatUrls = (
  items: UrlAttribute[] | undefined | null
): UrlAttribute[] => {
  if (!items) return [];

  return items.map((item) => ({
    label: item.label,
    url: formatUrl(item.url),
  }));
};
