import { useMemo } from "react";
import { FieldsAdminSettings } from "widgets/FieldsAdminSettings";
import {
  useGetAdminConfigurationQuery,
  getConfigurationFields,
} from "modules/adminFieldsConfiguration";
import { usePermission } from "app/providers/PermissionsProvider";
import { useFlags } from "launchdarkly-react-client-sdk";

export const WorkOrdersAdminSettings = () => {
  const { enableProcedures, enableAssetCode } = useFlags();

  const WorkOrderAdminConfigurationFields = useMemo(() => {
    const configFields = [
      "description",
      "priority",
      "categories",
      "assign_to",
      "due_date",
      "location",
      "asset",
      "vendors",
      "checklist",
      "in_review",
      "procedures",
      "require_procedure_comment",
    ];

    if (enableAssetCode) {
      configFields.push("asset_code");
    }
    return configFields;
  }, [enableAssetCode]);

  const { adminSettingsPartPermit } = usePermission() as {
    adminSettingsPartPermit: boolean;
  };

  const { data } = useGetAdminConfigurationQuery({ type: "work_order" });
  const { data: statusData } = useGetAdminConfigurationQuery({
    type: "work_order_status",
  });

  const fields = useMemo(
    () =>
      enableProcedures
        ? WorkOrderAdminConfigurationFields
        : WorkOrderAdminConfigurationFields.filter(
            (field) => field !== "procedures"
          ),
    [WorkOrderAdminConfigurationFields, enableProcedures]
  );

  if (!data) return null;

  const workOrderData = { ...data?.data.payload, ...statusData?.data.payload };
  const currentConfiguration = getConfigurationFields(fields, workOrderData);

  return (
    <FieldsAdminSettings
      initialConfiguration={currentConfiguration}
      type="work_order"
      additionalFields={[
        { name: "in_review", type: "work_order_status" },
        {
          name: "require_procedure_comment",
          type: "work_order_procedure_comment",
        },
      ]}
      hasPermission={adminSettingsPartPermit}
    />
  );
};
