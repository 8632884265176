import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "widgets/Header";
import { Container } from "shared/components/Container/Container";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { usePermission } from "app/providers/PermissionsProvider";

import AddIcon from "shared/assets/icon/add.svg?react";
import { List as InventoryPartsList } from "modules/parts/components/List";
import { ModalLink } from "shared/components/ModalLink";
import { useSetModal } from "widgets/ModalController";
import { Button } from "../../shared/components/Button/index";
import GraphIcon from "shared/assets/icon/graph.svg?react";
import { usePersistentState } from "../../modules/reporting/lib/usePersistentState";
import { useFlags } from "launchdarkly-react-client-sdk";

export const PartsPage = () => {
  const { t } = useTranslation();
  const setModal = useSetModal();
  const [showKPIs, setShowKPIs] = usePersistentState("partsKpis", false);
  const { enablePartsKpis } = useFlags();
  const { partCreatePermit, partsListPermit, partsListExportPermit } =
    usePermission();

  return (
    <TablePageLayout>
      {partsListPermit && (
        <>
          <Header
            title={t("parts.title")}
            searchPlaceholder={t("parts.searchPlaceholder")}
            action={
              <>
                {enablePartsKpis && (
                  <Button
                    variant="tertiary"
                    icon={<GraphIcon />}
                    onClick={() => setShowKPIs(!showKPIs)}
                  >
                    {t("work_orders.kpis.button")}
                  </Button>
                )}
                {partCreatePermit && (
                  <ModalLink
                    type="button"
                    icon={<AddIcon />}
                    modalParams={{ type: "part", id: "new" }}
                  >
                    {t("parts.addButton")}
                  </ModalLink>
                )}
              </>
            }
            horizontallySpaciousPage
          />
          <Container>
            <InventoryPartsList
              onOpenPart={(id) => setModal({ type: "part", id })}
              withExport={partsListExportPermit}
              showKpis={showKPIs}
            />
          </Container>
        </>
      )}
    </TablePageLayout>
  );
};
