import { useUpdateProcedureItem } from "modules/procedures/hooks/useUpdateProcedureItem";
import { useState } from "react";
import { FormCheckbox } from "shared/components/FormCheckbox";
import { HorizontalItem, Label } from "./styled";
import { ItemProps } from "./types";

export const Checkbox = ({ item, onChange }: ItemProps) => {
  const [value, setValue] = useState(item.value?.checked);

  useUpdateProcedureItem(item, { checked: value }, value as boolean, 500);

  const handleChange = (newValue: boolean) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <HorizontalItem onClick={() => handleChange(!value)}>
      <FormCheckbox
        checked={value}
        name={item.name}
        onClick={() => handleChange(!value)}
        label={<Label>{item.name}</Label>}
      />
    </HorizontalItem>
  );
};
