export const RESPONSE_MESSAGE = {
  pm_is_linked:
    "Cannot delete this PM Schedule as it has associated work orders that do not meet the criteria.",
  connected_record:
    "Cannot delete record because it is dependent on other records",
  already_taken: "has already been taken",
  offline_asset:
    'Asset linked to this work order has status "Offline". Please update the status of the asset if needed to display the data correctly.',
};

export const enum ERROR_CODES {
  RequireCommentIncompleteProcedure = "REQUIRE_COMMENT_INCOMPLETE_PROCEDURE",
  AssetOffline = "ASSET_OFFLINE",
}
