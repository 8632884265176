import * as Yup from "yup";
import { emailRegexp } from "../constants/forms.js";
import { formatUrl } from "./formatUrls.ts";

export const getMultiSelectSchema = (t, field = { required: false }) =>
  Yup.array()
    .of(Yup.object())
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value && value.length > 0
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getStringSchema = (t, field = { required: false, max: "" }) =>
  Yup.string()
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    })
    .test(
      "maxLength",
      t("validation.stringMaxLength", {
        stringMaxLength: field.max,
      }),
      (value, testContext) => {
        if (field.max) {
          return !value || value?.length <= field.max
            ? true
            : testContext.createError({
                path: `${testContext.path}`,
                message: t("validation.stringMaxLength", {
                  stringMaxLength: field.max,
                }),
              });
        }
        return true;
      }
    );

export const getUrlSchema = (t, field = { required: false }) =>
  Yup.array(
    Yup.object({
      label: Yup.string(),
      url: Yup.string()
        .transform((url) => formatUrl(url))
        .url(t("validation.url")),
    })
  ).test("Required test", "Required", (value, testContext) => {
    if (field.required) {
      return value && value.length > 0
        ? true
        : testContext.createError({
            path: `${testContext.path}`,
            message: t("validation.required"),
          });
    }
    return true;
  });

export const getSelectSchema = (t, field = { required: false }) =>
  Yup.object({ label: Yup.string(), value: Yup.string() })
    .nullable()
    .test("Required test", "Required", (value, testContext) => {
      if (field?.required) {
        return !!value && !!value.value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getCostSchema = (t) =>
  Yup.number()
    .typeError(t("validation.positive"))
    .min(0, t("validation.positive"))
    .max(
      99999999999999999999.99,
      t("validation.fractionalNumberMaxLength", {
        integerLength: 20,
        decimalLength: 2,
      })
    )
    .test(
      "is valid",
      t("validation.fractionalNumberMaxLength", {
        integerLength: 20,
        decimalLength: 2,
      }),
      (value) =>
        value ? /^[0-9]{0,20}[.]{0,1}[0-9]{0,2}$/.test(String(value)) : true
    );

export const getDescriptionSchema = (t, field = { required: false }) =>
  Yup.string()
    .max(1000, t("validation.stringMaxLength", { stringMaxLength: 1000 }))
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getNameSchema = (t, field = { required: false }) =>
  Yup.string()
    .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 }))
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getEmailSchema = (t, field = { required: false }) =>
  Yup.string()
    .max(255, t(`validation.stringMaxLength`, { stringMaxLength: 255 }))
    .matches(emailRegexp, t("contacts.validation.email"))
    .test(" Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });
