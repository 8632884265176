import { usePermission } from "app/providers/PermissionsProvider";
import { useTranslation } from "react-i18next";
import { ModalLink } from "shared/components/ModalLink";
import ProceduresIcon from "shared/assets/icon/menu/Procedures.svg?react";
import { Empty as EmptyComponent } from "shared/components/Empty";

export const Empty = () => {
  const { t } = useTranslation();
  const { procedureTemplateCreatePermit } = usePermission();

  return (
    <EmptyComponent
      icon={<ProceduresIcon />}
      heading={t("procedures.empty.heading")}
      description={
        procedureTemplateCreatePermit && (
          <>
            {t("empty.descriptionUse")}
            <ModalLink modalParams={{ type: "procedureTemplate", id: "new" }}>
              {t("procedures.empty.link")}
            </ModalLink>
            {t("empty.descriptionButton")}
          </>
        )
      }
      spacious
    />
  );
};
