import { useTranslation } from "react-i18next";
import { SettingsList } from "shared/components/SettingsList";
import { PageWrapper } from "shared/components/PageWrapper";
import { AdditionalFieldConfiguration } from "../AdditionalFieldConfiguration";
import {
  Wrapper,
  DescriptionBox,
  Title,
} from "./AdminFieldsConfiguration.styled";
import { AdditionalField } from "../AdditionalFieldConfiguration/AdditionalFieldConfiguration";

interface Row {
  required: boolean;
  visible: boolean;
  name: string;
  field: string;
  id: string;
}

interface AdminFieldsConfigurationProps {
  rows: Row[];
  handleSetValue: (name: string, value: unknown) => void;
  values: Record<string, { visible: boolean; [key: string]: unknown }>;
  module: string;
  additionalFields?: AdditionalField[];
  withDescription: boolean;
}

export const AdminFieldsConfiguration = ({
  rows = [],
  handleSetValue,
  values,
  module = "",
  additionalFields,
  withDescription,
}: AdminFieldsConfigurationProps) => {
  const { t } = useTranslation();

  const onSetVisible = (field: string) => {
    handleSetValue(field, {
      ...(values[field] as object),
      visible: !values[field]?.visible,
      ...(values[field]?.visible ? { required: false } : {}),
    });
  };

  const handleFieldToggled = (field: string) => {
    handleSetValue(field, {
      ...values[field],
      required: !values[field]?.required,
    });
  };

  const getIsChecked = (name: string, field: string): boolean =>
    values[name]?.[field] as boolean;

  const getIsVisible = (field: string) => !values[field]?.visible;

  return (
    <PageWrapper>
      <Wrapper>
        {additionalFields &&
          additionalFields.map((field) => (
            <AdditionalFieldConfiguration
              field={field}
              activated={getIsChecked(field.name, "required")}
              onToggle={handleFieldToggled}
              withDescription={withDescription}
            />
          ))}
        <Title>{t("settings.subtitle")}</Title>
        <DescriptionBox>
          {t("settings.subdescription", { module })}
        </DescriptionBox>
        <SettingsList
          rows={rows}
          onSetVisible={onSetVisible}
          onSetRequired={handleFieldToggled}
          getIsChecked={getIsChecked}
          getIsVisible={getIsVisible}
        />
      </Wrapper>
    </PageWrapper>
  );
};
