import { BulkDeletion } from "features/BulkDeletion";
import { useDestroyProcedureTemplatesMutation } from "modules/procedures/state/proceduresApi";
import { useTranslation } from "react-i18next";

interface BulkActionsProps {
  selectionModel: number[];
  onSuccess: () => void;
}

export const BulkActions = ({
  selectionModel,
  onSuccess,
}: BulkActionsProps) => {
  const { t } = useTranslation();
  const [deleteProcedureTemplates] = useDestroyProcedureTemplatesMutation();

  return (
    <BulkDeletion
      ids={selectionModel}
      confirmation={{
        title: t("procedures.bulk_delete.title", {
          count: selectionModel.length,
        }),
        description: t("procedures.bulk_delete.description", {
          count: selectionModel.length,
        }),
        confirmButtonLabel: t("delete"),
      }}
      onDelete={deleteProcedureTemplates}
      onSuccess={onSuccess}
    />
  );
};
