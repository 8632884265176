import * as Yup from "yup";
import { t } from "i18next";
import { getFormDataFromJSON } from "shared/lib/helpers/formData";
import {
  getSelectSchema,
  getMultiSelectSchema,
  getStringSchema,
  getDescriptionSchema,
} from "shared/lib/helpers/validation";
import { getAssignToValues, getAssignToIds } from "shared/lib/helpers";
import { getOtherCostFormatForService } from "modules/workOrderOtherCosts";

export const WorkOrderFormOptionalFields = [
  "description",
  "priority",
  "categories_ids",
  "assign_to",
  "due_date",
  "location_id",
  "asset_id",
  "vendors_ids",
  "checklist",
  "procedures",
];

export const defaultStatus = {
  value: 1,
  label: t("work_orders.status.open"),
};

const WorkOrderCreateInitialValues = {
  title: "",
  description: "",
  priority: "none",
  categories: [],
  type: "reactive",
  due_date: "",
  location: null,
  asset: null,
  set_asset_to_offline: false,
  vendors: [],
  uploads_attributes: [],
  checklist: [],
  status: defaultStatus,
  work_order_times_attributes: [],
  assign_to: [],
  part_transactions_attributes: [],
  work_order_other_costs_attributes: [],
  procedure_templates: [],
};

export const getInitialValues = ({ type, data, defaultValues }) => {
  if (type === "create" || !data) {
    return { ...WorkOrderCreateInitialValues, ...defaultValues };
  }

  const {
    title = "",
    description = "",
    priority = "none",
    location,
    asset,
    type: workOrderType,
    categories = [],
    due_date = "",
    technicians = [],
    vendors = [],
    images = [],
    documents = [],
    checklist = [],
    pm_schedule,
    status,
    procedures = [],
    procedure_templates = [],
  } = data;

  const workOrderEditForm = {
    title,
    description,
    priority,
    categories: categories.map(({ id, name }) => ({ value: id, label: name })),
    type: workOrderType,
    due_date: due_date ?? "",
    location: location ? { value: location.id, label: location.path } : null,
    asset: asset ? { value: asset.id, label: asset.name } : null,
    vendors: vendors.map(({ id, name }) => ({ value: id, label: name })),
    set_asset_to_offline: false,
    uploads_attributes: images
      .map((image) => ({ ...image, isImage: true }))
      .concat(documents),
    checklist: checklist || [],
    assign_to: getAssignToValues(technicians),
    status: status ? { value: status.id, label: status.name } : null,
    procedures: procedures.map(({ id, name }) => ({
      value: id,
      label: name,
      concrete_procedure: true,
    })),
  };

  if (workOrderType === "preventive" && pm_schedule) {
    workOrderEditForm.pm_schedule_attributes = {
      ...pm_schedule,
      start_date: pm_schedule?.start_date,
    };

    const templates = procedure_templates?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    workOrderEditForm.procedures = [
      ...workOrderEditForm.procedures,
      ...templates,
    ];
  }

  return workOrderEditForm;
};

const getDueDateSchema = (t, field = { required: false }) =>
  Yup.string()
    .nullable()
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return !!value || testContext.parent.type !== "reactive"
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getMultiArrayOfNumberSchema = (field, secondName, t) =>
  Yup.array()
    .of(Yup.number())
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return (value && value.length > 0) ||
          (testContext.parent[secondName] &&
            testContext.parent[secondName].length > 0)
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getChecklistSchema = (t, field) =>
  Yup.array(
    Yup.object({
      text: Yup.string(),
      checked: Yup.boolean(),
      order: Yup.string(),
    })
  )
    .nullable()
    .test("Required test", "Required", (value, testContext) => {
      if (field.required) {
        return value && value.length > 0
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      }
      return true;
    });

export const getScheduleSchema = (t) =>
  Yup.object({
    type: Yup.string(),
    start_date: Yup.date(),
    time_to_complete: Yup.number(),
  }).test("Required test", "Required", (value, testContext) => {
    if (!value) {
      return true;
    }
    if (value.type) {
      return value.start_date
        ? true
        : testContext.createError({
            path: `${testContext.path}.start_date`,
            message: t("validation.required"),
          });
    }
    return true;
  });

export const workOrderValidationSchema = (t, optionalFields) => {
  const WorkOrderFormDefaultSchema = {
    title: Yup.string()
      .required(t("validation.required"))
      .max(255, t("validation.stringMaxLength", { stringMaxLength: 255 })),
    description: getDescriptionSchema(t),
    priority: Yup.string(),
    categories: Yup.array(),
    type: Yup.string().required(t("validation.required")),
    due_date: Yup.string(),
    vendors: Yup.array(),
    location: Yup.object().nullable(),
    asset: Yup.object().nullable(),
    uploads_attributes: Yup.array(),
    set_asset_to_offline: Yup.boolean(),
    pm_schedule_attributes: getScheduleSchema(t),
    assign_to: Yup.array(),
    status: Yup.object().nullable(),
  };

  const checklistSchema = {
    checklist: Yup.array(
      Yup.object({
        text: Yup.string(),
        order: Yup.string(),
        checked: Yup.string(),
      }).test("Required text test", "Required", (value, testContext) => {
        return value.text
          ? true
          : testContext.createError({
              path: `${testContext.path}`,
              message: t("validation.required"),
            });
      })
    ),
  };

  const schema = Object.keys(optionalFields).reduce(
    (updatedSchema, fieldKey) => {
      const field = optionalFields[fieldKey];

      if (["priority"].includes(fieldKey)) {
        updatedSchema[fieldKey] = getStringSchema(t, field);
      }

      if (fieldKey === "asset_id") {
        updatedSchema["asset"] = getSelectSchema(t, field);
      }

      if (fieldKey === "location_id") {
        updatedSchema["location"] = getSelectSchema(t, field);
      }

      if (fieldKey === "categories_ids") {
        updatedSchema["categories"] = getMultiSelectSchema(t, field);
      }

      if (fieldKey === "vendors_ids") {
        updatedSchema["vendors"] = getMultiSelectSchema(t, field);
      }

      if (fieldKey === "assign_to") {
        updatedSchema["assign_to"] = getMultiSelectSchema(t, field);
      }
      if (fieldKey === "due_date") {
        updatedSchema[fieldKey] = getDueDateSchema(t, field);
      }
      if (fieldKey === "checklist") {
        updatedSchema[fieldKey] = getChecklistSchema(t, field);
      }
      if (fieldKey === "description") {
        updatedSchema[fieldKey] = getDescriptionSchema(t, field);
      }

      return updatedSchema;
    },
    WorkOrderFormDefaultSchema
  );

  return Yup.object({ ...schema, ...checklistSchema });
};

export const getOptionalFields = (fieldsData) => {
  if (!fieldsData || fieldsData?.status !== "success") {
    return {};
  }

  const { data } = fieldsData;

  return WorkOrderFormOptionalFields.reduce((acc, optionalField) => {
    const field = data.find(({ name }) => name === optionalField);
    if (field) {
      const { required } = field;
      acc[optionalField] = { required };
    }
    return acc;
  }, {});
};

const addScheduleForFormData = (data, schedule) => {
  data.append("pm_schedule_attributes[type]", schedule.type);
  data.append(
    "pm_schedule_attributes[time_to_complete]",
    schedule.time_to_complete
  );
  data.append("pm_schedule_attributes[start_date]", schedule.start_date);
  data.append("is_pm_template", true);

  if (schedule.type !== "one_time") {
    data.append(
      "pm_schedule_attributes[repeat_occurrence]",
      schedule.repeat_occurrence
    );
    data.append(
      "pm_schedule_attributes[repeat_frequency]",
      schedule.repeat_frequency
    );
  }

  if (schedule.type === "persistent") {
    if (schedule.repeat_frequency === "week") {
      schedule.on_week_days.forEach((item) => {
        data.append(`pm_schedule_attributes[on_week_days][]`, item);
      });
    }
    if (schedule.repeat_frequency === "month") {
      data.append(
        "pm_schedule_attributes[on_month_day]",
        schedule.on_month_day
      );
      if (schedule.repeat_occurrence === "custom") {
        schedule.in_months.forEach((item) => {
          data.append(`pm_schedule_attributes[in_months][]`, item);
        });
      }
    }
  }
};

export const getWorkOrderFormatForService = (form, withPMAtrributes) => {
  const {
    uploads_attributes,
    pm_schedule_attributes,
    work_order_times_attributes,
    part_transactions_attributes,
    work_order_other_costs_attributes,
    location,
    asset,
    vendors,
    categories,
    assign_to,
    status,
    due_date,
    procedure_templates,
    procedures,
    ...otherFields
  } = form;

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  //const { teams_ids, technicians_ids } = getAssignToIds(assign_to);
  const { technicians_ids } = getAssignToIds(assign_to);

  const data = getFormDataFromJSON({
    ...otherFields,
    location_id: location?.value || "",
    asset_id: asset?.value || "",
    vendors_ids: vendors.map(({ value }) => value),
    categories_ids: categories.map(({ value }) => value),
    procedure_template_ids: procedure_templates?.map(({ value }) => value),
    procedure_ids: procedures?.map(({ value }) => value),
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // teams_ids,
    technicians_ids,
    status_id: status?.value || "",
    due_date,
  });

  uploads_attributes.forEach((fileObj, index) => {
    if (fileObj.isRemove) {
      data.append(`uploads_attributes[${index}][remove]`, fileObj.id);
    } else if (fileObj.file) {
      data.append(`uploads_attributes[${index}][upload]`, fileObj.file);
    }
  });

  if (part_transactions_attributes) {
    part_transactions_attributes.forEach((item) => {
      data.append(`part_transactions_attributes[][part_id]`, item.part.value);
      data.append(`part_transactions_attributes[][quantity]`, item.quantity);
    });
  }

  if (otherFields.type === "reactive") {
    if (work_order_times_attributes) {
      work_order_times_attributes.forEach((item) => {
        const { user, ...others } = item;
        Object.keys(others).forEach((objectKey) => {
          data.append(
            `work_order_times_attributes[][${objectKey}]`,
            others[objectKey]
          );
        });
        data.append(`work_order_times_attributes[][user_id]`, user.value);
      });
    }
    if (work_order_other_costs_attributes) {
      work_order_other_costs_attributes.forEach((item) => {
        const otherCostsData = getOtherCostFormatForService(item);
        for (const pair of otherCostsData.entries()) {
          data.append(
            `work_order_other_costs_attributes[][${pair[0]}]`,
            pair[1]
          );
        }
      });
    }
  }

  if (pm_schedule_attributes && withPMAtrributes)
    addScheduleForFormData(data, pm_schedule_attributes);

  return data;
};

export const getStatusesOptions = (data, statusConfiguration) => {
  if (!data || !statusConfiguration) return [];
  const isShowReview = !!statusConfiguration.data.payload?.in_review?.required;
  const result = isShowReview
    ? data
    : data.filter((status) => status.name !== "in_review");
  return [...result].sort((a, b) => a.id - b.id);
};
