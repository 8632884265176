import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { OntimeVsOverdueKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { StringifiedFilters } from "modules/reporting/types/StringifiedFilters";

const reportKey = ReportKey.OntimeVsOverdue;

export const useOntimeVsOverdueReportKPIs = (
  reportPeriod?: ReportPeriod,
  stringifiedFilters?: StringifiedFilters
) => {
  const { data, ...rest } = useGetKPIs<OntimeVsOverdueKPIs>(
    reportKey,
    reportPeriod,
    stringifiedFilters
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return {
    ...rest,
    data: [
      {
        value: formatNumber(data?.on_time, language),
        label: t(`reporting.${reportKey}.kpis.on_time`),
      },
      {
        value: formatPercent(data?.on_time_percentage, language),
        label: t(`reporting.${reportKey}.kpis.on_time_percentage`),
      },
      {
        value: formatNumber(data?.overdue, language),
        label: t(`reporting.${reportKey}.kpis.overdue`),
      },
      {
        value: formatNumber(data?.no_due_date, language),
        label: t(`reporting.${reportKey}.kpis.no_due_date`),
      },
      {
        value: formatNumber(data?.completed, language),
        label: t(`reporting.${reportKey}.kpis.completed`),
      },
      {
        value:
          typeof data?.mttr === "number"
            ? t(`reporting.mttr`, {
                mttr: data.mttr / 60,
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              })
            : "-",
        label: t(`reporting.mttr_label`),
      },
    ],
  };
};
