import { StyleSheet, Font } from "@react-pdf/renderer";
import styled from "styled-components";
import BoldFont from "../../assets/fonts/SourceSans3-Bold.ttf";
import RegularFont from "../../assets/fonts/SourceSans3-Regular.ttf";
import SemiBoldFont from "../../assets/fonts/SourceSans3-SemiBold.ttf";

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 32px;
`;

export const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const COLORS = {
  black: "#000000",
  lightGray: "#BFBFC3",
  gray: "#737278",
};

Font.register({
  family: "Source Sans 3",
  fonts: [
    {
      src: RegularFont,
    },
    {
      src: SemiBoldFont,
      fontWeight: "semibold",
    },
    {
      src: BoldFont,
      fontWeight: "bold",
    },
  ],
});

// minPresenceAhead works best when it is a prop on a self-closing component (such as <View />)
// with an empty (<>) parent container
export const minPresenceAhead = {
  filesSection: 100,
  section: 75,
  subSection: 50,
};

// Source Sans 3 does not properly render \r
export const normalizeLineEndings = (text) => {
  if (typeof text === "string") {
    return text.replace(/\r\n/g, "\n");
  }

  return "";
};

// pt values & flex by default in react-pdf, px not recognized (https://react-pdf.org/styling)
export const pageStyle = StyleSheet.create({
  border: {
    borderBottom: `1.5 solid ${COLORS.black}`,
    marginTop: 16,
    marginBottom: 16,
  },
  checkboxesWithValues: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: 8,
  },
  checkboxWithValue: {
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
  },
  checkIcon: {
    width: 8,
    height: 8,
    marginLeft: 1,
  },
  column: {
    flexBasis: "45%",
    gap: 4,
  },
  columns: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "0 10",
  },
  columnDivider: {
    width: 1,
    backgroundColor: COLORS.black,
  },
  description: {
    gap: 16,
  },
  detailsField: {
    flexDirection: "row",
    fontSize: 10,
  },
  detailsHeading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    marginBottom: 4,
  },
  detailsLabelLeft: {
    width: 85,
    color: COLORS.gray,
  },
  detailsLabelRight: {
    width: 70,
    color: COLORS.gray,
  },
  detailsMessage: {
    color: COLORS.gray,
    fontWeight: "semibold",
    fontSize: 8,
    marginTop: 2,
  },
  detailsValue: {
    flex: 1,
    fontWeight: "semibold",
  },
  documentIcon: {
    alignItems: "center",
    width: 77,
    height: 67,
    border: `1 solid ${COLORS.lightGray}`,
    borderRadius: 12,
    paddingTop: 18,
    textAlign: "center",
    gap: 4,
  },
  h1: {
    fontSize: 20,
    fontWeight: "bold",
  },
  h2: {
    fontSize: 14,
    fontWeight: "semibold",
    marginBottom: 4,
  },
  h2NoMargin: {
    fontSize: 14,
    fontWeight: "semibold",
  },
  h3: {
    fontSize: 11,
    fontWeight: "semibold",
  },
  icon: {
    width: 10,
    height: 10,
    position: "relative",
    bottom: 0.5,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
  },
  image: {
    width: 77,
    height: 67,
  },
  imageRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5 5",
  },
  logo: {
    width: 20,
    height: 20,
  },
  page: {
    fontFamily: "Source Sans 3",
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },
  procedures: {
    flexDirection: "column",
    gap: 4,
  },
  procedureHeading: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    marginTop: 4,
    marginBottom: 3,
  },
  procedureCheckbox: {
    width: 10,
    height: 10,
  },
  procedureItem: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
  },
  procedureItemDefault: {
    alignItems: "flex-end",
  },
  procedureItemName: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    fontSize: 10,
  },
  procedureItemNameWo: {
    marginRight: 30,
    alignSelf: "center",
  },
  procedureItemBorder: {
    borderBottom: `0.5 solid ${COLORS.black}`,
  },
  procedureWidth: {
    width: 240,
  },
  procedureMessage: {
    color: COLORS.gray,
    fontWeight: "semibold",
    fontSize: 8,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowDetailed: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
    fontSize: 10,
  },
  section: {
    flexDirection: "column",
  },
  textBold: {
    fontWeight: "bold",
  },
  textSemiBold: {
    fontWeight: "semibold",
  },
  textBase: {
    fontSize: 10,
  },
  textSm: {
    fontSize: 8,
  },
  textGrey: {
    color: COLORS.gray,
  },
  vendors: {
    flexDirection: "column",
    gap: 4,
  },
});
