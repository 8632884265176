import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

interface Config {
  showGenericErrorSnack?: boolean;
}

export const useCaptureExceptionWithBreadcrumb = ({
  showGenericErrorSnack = false,
}: Config) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();

  return (exception: unknown, message: string, breadcrumb: object) => {
    Sentry.addBreadcrumb({
      category: "error",
      message: message,
      data: breadcrumb,
    });
    if (exception instanceof Error) {
      Sentry.captureException(exception);
    } else {
      Sentry.addBreadcrumb({
        data: {
          exception,
        },
      });
      Sentry.captureMessage(
        message == null ? "Captured an error with no message" : message
      );
    }

    if (showGenericErrorSnack) {
      switch ((exception as { status: number }).status) {
        case 403:
          addSnackBar({
            title: t("forbidden.title"),
            content: (
              <>
                <p>{t("forbidden.description_a")}</p>
                <p>{t("forbidden.description_b")}</p>
              </>
            ),
            type: "error",
          });
          break;

        default:
          addSnackBar({
            title: t("fallback.error"),
            content: (
              <>
                <p>{t("fallback.description_a")}</p>
                <p>{t("fallback.description_b")}</p>
              </>
            ),
            type: "error",
          });
          break;
      }
    }
  };
};
