import { getUser } from "shared/components/Auth";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetUserPermissionsQuery,
  useGetUserQuery,
} from "modules/users/state/usersApi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetCustomerQuery } from "modules/customer/state/customerApi";

export const TotangoProvider = () => {
  const location = useLocation();
  const user = getUser() ?? {};

  const totango = window.totango;
  const totango_options = window.totango_options;

  const { data: userData } = useGetUserQuery(user?.id ?? skipToken);
  const { data: customerData } = useGetCustomerQuery(
    user?.customerId ?? skipToken
  );
  const { currentData } = useGetUserPermissionsQuery(user?.id ?? skipToken);

  const currentEntity = currentData?.meta.entity.id;
  const userPermissions = userData?.permissions || [];
  const validRoles = ["admin", "limited_admin", "technician"];
  let validPermission = false;

  for (const permission of userPermissions) {
    if (permission.entity_id === currentEntity) {
      validPermission = validRoles.includes(permission.role);
      break;
    }
  }

  const nonCmEmail =
    userData?.email && !userData.email.includes("@clickmaint.com");

  useEffect(() => {
    if (totango && customerData?.totango_id && validPermission && nonCmEmail) {
      // See https://support.totango.com/hc/en-us/articles/204456359-System-and-core-attributes for possible attributes
      totango_options.user = {
        id: userData?.email,
        name: userData?.full_name,
        title: userData?.job_title,
        email: userData?.email,
        contact_organization: userData?.tenant,
        location: location.pathname,
      };
      totango_options.location = location;
      totango_options.account = {
        id: customerData?.totango_id,
        name: customerData?.name,
      };
      totango.go(totango_options);
    }
  });
};
