import styled, { css } from "styled-components";
import { Button } from "shared/components/Button";

export const ButtonEdit = styled(Button)`
  margin-left: auto;
`;

export const ButtonsDropdown = styled.div`
  display: flex;
  width: 100%;
  margin-right: 12px;
  position: relative;

  & .statusesList {
    width: 256px;
    left: 45%;
    cursor: pointer;
    font-size: 14px;
    color: var(--color_text_color);

    & > :first-child {
      left: 90%;
    }
  }
`;

export const ButtonStyles = css`
  width: 100%;
  border-right: 0;
  line-height: 14px;
  font-size: 12px;
  color: var(--color_text_color);

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledAdditionalButton = styled(Button)`
  ${ButtonStyles};
  border-radius: 4px 0 0 4px;
  justify-content: center;
  gap: 6px;

  ${(props) =>
    props.isSelected &&
    css`
      cursor: initial;
      pointer-events: none;
    `}
`;

export const ArrowButton = styled(Button)`
  border-radius: 0 4px 4px 0;
  padding: 12px 6px;
  position: relative;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Statuses = styled.div`
  width: 100%;
`;
