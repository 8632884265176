import styled, { css } from "styled-components";
import { Button, ButtonProps } from "shared/components/Button/Button";
import { Tooltip } from "react-tooltip";

interface StatusButtonProps extends ButtonProps {
  isSelected: boolean;
  borderColor: string;
  tooltipText?: string;
}

export const StatusButton = (props: StatusButtonProps) => {
  const { tooltipText, ...rest } = props;

  return (
    <>
      {tooltipText && (
        <Tooltip
          id="button-tooltip"
          place="bottom"
          content={tooltipText}
          opacity="1"
        />
      )}
      <StyledButton {...rest} data-tooltip-id="button-tooltip" />
    </>
  );
};

export const ButtonStyles = css`
  width: 100%;
  line-height: 14px;
  font-size: 12px;
  color: var(--color_text_color);
  outline-offset: -2px;

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledButton = styled(Button)`
  ${ButtonStyles};
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border-radius: 0;
  padding: 8px 10px;

  ${({ isSelected, color, borderColor }: StatusButtonProps) =>
    isSelected &&
    css`
      background: ${`var(--color_${color})`};
      outline: 2px solid ${`var(--color_${borderColor})`};
      cursor: initial;
      pointer-events: none;

      &:hover {
        background: ${`var(--color_${color})`};
        outline: 2px solid ${`var(--color_${borderColor})`};
      }
    `}

  ${({ disabled }: StatusButtonProps) =>
    disabled &&
    css`
      filter: grayscale(1);
    `}

  &:first-child {
    border-radius: 4px 0 0 4px;
  }
`;
