import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, Image } from "@react-pdf/renderer";

import {
  minPresenceAhead,
  pageStyle,
  normalizeLineEndings,
} from "shared/components/BulkPdfExport/BulkPdfExport.styled.js";
import { useDisplayDate, useGetLocaleSettings } from "shared/lib/hooks";
import {
  formatCurrency,
  formatDateWithDayOfWeek,
} from "shared/lib/helpers/common";
import { getRepeatsText } from "modules/preventiveMaintenances";
import { getTimes } from "../../../workOrderTime/lib/helpers";

import CheckIcon from "shared/assets/icon/pdf/check.png";
import DocumentIcon from "shared/assets/icon/pdf/document.png";
import CompletedIcon from "shared/assets/icon/pdf/completed.png";
import HoldIcon from "shared/assets/icon/pdf/hold.png";
import OpenIcon from "shared/assets/icon/pdf/open.png";
import ProgressIcon from "shared/assets/icon/pdf/progress.png";
import ReviewIcon from "shared/assets/icon/pdf/review.png";
import WaitingCostIcon from "shared/assets/icon/pdf/waitingCost.png";
import WaitingInvoiceIcon from "shared/assets/icon/pdf/waitingInvoice.png";
import WaitingPartsIcon from "shared/assets/icon/pdf/waitingParts.png";

import CriticalIcon from "shared/assets/icon/pdf/critical.png";
import HighIcon from "shared/assets/icon/pdf/high.png";
import LowIcon from "shared/assets/icon/pdf/low.png";
import MediumIcon from "shared/assets/icon/pdf/medium.png";
import NoneIcon from "shared/assets/icon/pdf/none.png";

import { PDFChecklist } from "shared/components/PDFChecklist";
import { useGetProcedureItemComplete } from "shared/lib/hooks/useGetProcedureItemComplete";
import {
  PROCEDURE_ITEM_TEMPLATES,
  CheckedTemplate,
  UncheckedTemplate,
} from "./ProcedurePdfTemplates";
import { measurementUnits } from "modules/parts/configs/constant";

const StatusMap = {
  open: OpenIcon,
  on_hold: HoldIcon,
  completed: CompletedIcon,
  in_review: ReviewIcon,
  in_progress: ProgressIcon,
  waiting_for_cost: WaitingCostIcon,
  waiting_for_parts: WaitingPartsIcon,
  waiting_for_invoice: WaitingInvoiceIcon,
};

const PriorityMap = {
  none: NoneIcon,
  low: LowIcon,
  medium: MediumIcon,
  high: HighIcon,
  critical: CriticalIcon,
};

export const WorkOrdersPdfPage = ({ resource = {} }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();
  const { currency } = useGetLocaleSettings();
  const getProcedureItemComplete = useGetProcedureItemComplete();

  const {
    title: name,
    id,
    asset,
    description,
    status,
    location,
    due_date,
    created_at,
    type,
    priority,
    images = [],
    documents = [],
    categories = [],
    technicians = [],
    vendors = [],
    comments = [],
    checklist,
    updated_at,
    created_by,
    work_order_request,
    pm_schedule,
    parts_transactions,
    work_order_times,
    other_costs,
    procedures,
    completed_at,
  } = resource;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const StatusIcon = StatusMap[status?.name];
  const PriorityIcon = PriorityMap[priority];

  const createdByName =
    created_by && `${created_by.name || ""} ${created_by.surname || ""}`;

  const worName =
    work_order_request &&
    `${work_order_request?.created_by.name || ""} ${
      work_order_request?.created_by.surname || ""
    }`;
  const worCreated =
    work_order_request && `on ${displayDate(work_order_request.created_at)}`;
  const worNumber =
    work_order_request && work_order_request?.created_by.phone_number;
  const worEmail = work_order_request && work_order_request?.created_by.email;

  let repeatText = "";
  if (pm_schedule) {
    repeatText =
      pm_schedule.type === "one_time"
        ? t(
            "work_orders.form.pm_schedule_attributes.information.work_order_detail",
            {
              value: pm_schedule.next_order_date
                ? formatDateWithDayOfWeek(pm_schedule.next_order_date, true)
                : "",
            }
          )
        : getRepeatsText({
            repeat_occurrence: pm_schedule.repeat_occurrence,
            repeat_frequency: pm_schedule.repeat_frequency,
            on_month_day: pm_schedule.on_month_day,
            on_week_days: pm_schedule.on_week_days,
            in_months: pm_schedule.in_months,
            scheduleType: pm_schedule.type,
          });
  }

  const commentsPresent = comments.length > 0;
  const checklistPresent = checklist && checklist.length > 0;

  return (
    <>
      <View style={pageStyle.row}>
        <View style={pageStyle.iconContainer}>
          <Image src={PriorityIcon} style={pageStyle.icon} />
          <Text style={pageStyle.textSm}>
            {`${t("work_orders.details.priority")} ${t(
              `work_orders.priority.${priority}`
            )}`}
          </Text>
        </View>
        <Text style={[pageStyle.textSm, pageStyle.textBold]}>
          {t("work_orders.pdf.wo_id", { id })}
        </Text>
      </View>
      <View style={pageStyle.section}>
        <View style={pageStyle.description}>
          <Text style={pageStyle.h1}>{name}</Text>
          <Text style={pageStyle.textBase}>
            {normalizeLineEndings(description)}
          </Text>
        </View>
      </View>
      <View
        style={pageStyle.border}
        minPresenceAhead={minPresenceAhead.section}
      />
      <View style={pageStyle.section}>
        <View style={pageStyle.detailsHeading}>
          <Text style={pageStyle.h2NoMargin}>
            {t("modals.view.details.title")}
          </Text>
          <Text style={[pageStyle.detailsMessage, pageStyle.h2NoMargin]}>
            ·
          </Text>
          <Text style={pageStyle.detailsMessage}>
            {`${t("modals.view.details.last_update_message", {
              date: displayDate(updated_at),
            })}`}
          </Text>
        </View>
        <View style={pageStyle.columns}>
          <View style={pageStyle.column}>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.pdf.fields.status")}
              </Text>
              <View style={[pageStyle.iconContainer, pageStyle.detailsValue]}>
                <Image src={StatusIcon} style={pageStyle.icon} />
                <Text>
                  {status?.name && t(`work_orders.status.${status?.name}`)}
                </Text>
              </View>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.type")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {type && t(`work_orders.type.${type}`)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.due_date")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {displayDate(due_date, true)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.categories")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {categories.map(({ name }) => name).join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.location")}
              </Text>
              <Text style={pageStyle.detailsValue}>{location?.path}</Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.pdf.fields.completed_at")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {displayDate(completed_at, true)}
              </Text>
            </View>
          </View>
          <View style={pageStyle.column}>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.asset")}
              </Text>
              <Text style={pageStyle.detailsValue}>{asset?.name}</Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.assign_to")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {technicians
                  .map(({ name, surname }) => `${name} ${surname}`)
                  .join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.pdf.fields.created_by")}
              </Text>
              <Text style={pageStyle.detailsValue}>{createdByName}</Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.pdf.fields.created_at")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {displayDate(created_at)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.vendors")}
              </Text>
              <View style={pageStyle.vendors}>
                {vendors.map((vendor) => (
                  <View key={vendor.id}>
                    <Text style={pageStyle.textSemiBold}>{vendor.name}</Text>
                    {vendor.vendor_contacts.map((contact) => (
                      <View>
                        {!!contact.is_primary && (
                          <View>
                            <Text>{contact?.name}</Text>
                            <Text>{contact?.phone}</Text>
                            <Text>{contact?.email}</Text>
                          </View>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.schedule")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {pm_schedule &&
                  `${t("work_orders.details.pm_schedule_id", {
                    id: pm_schedule.id,
                  })} (${repeatText})`}
              </Text>
            </View>
            {work_order_request && (
              <View style={pageStyle.detailsField}>
                <Text style={pageStyle.detailLabel}>Requested by</Text>
                <Text style={pageStyle.detailsValue}>{worName}</Text>
                <Text>{worCreated}</Text>
                <Text>{worNumber}</Text>
                <Text>{worEmail}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
      {files.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.filesSection}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>
              {t("work_orders.pdf.titles.media_and_files")}
            </Text>
            <View style={pageStyle.detailsField}>
              <View style={pageStyle.imageRow}>
                {images.map((image) => (
                  <Image src={image.url} style={pageStyle.image} />
                ))}
                {documents.map((file) => (
                  <View style={pageStyle.documentIcon}>
                    <Image src={DocumentIcon} style={pageStyle.logo} />
                    <Text style={[pageStyle.textSm, pageStyle.textGrey]}>
                      {file.name}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </>
      )}
      {procedures?.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <Text style={pageStyle.h2NoMargin}>{t("procedures.title")}</Text>
          <View style={pageStyle.procedures}>
            {procedures.map(({ id, name, items, completed_at }) => (
              <>
                <View minPresenceAhead={minPresenceAhead.subSection} />
                <View style={pageStyle.procedureHeading} key={id}>
                  <Text style={pageStyle.h3}>{name}</Text>
                  {completed_at && (
                    <>
                      <Text
                        style={[
                          pageStyle.procedureMessage,
                          pageStyle.h2NoMargin,
                        ]}
                      >
                        ·
                      </Text>
                      <View style={pageStyle.iconContainer}>
                        <Text style={pageStyle.procedureMessage}>
                          {t("completed")}
                        </Text>
                        <Image src={CheckIcon} style={pageStyle.checkIcon} />
                      </View>
                    </>
                  )}
                </View>
                <View>
                  {items.map((item) => {
                    const Template =
                      PROCEDURE_ITEM_TEMPLATES[item.item_type] ||
                      PROCEDURE_ITEM_TEMPLATES.default;
                    const style = [
                      pageStyle.procedureItem,
                      ...(Template === PROCEDURE_ITEM_TEMPLATES.default
                        ? [pageStyle.procedureItemDefault]
                        : []),
                    ];

                    return (
                      <View style={style} wrap={false}>
                        <View
                          style={[
                            pageStyle.procedureItemName,
                            pageStyle.procedureItemNameWo,
                            pageStyle.procedureWidth,
                          ]}
                        >
                          {getProcedureItemComplete(item)
                            ? CheckedTemplate
                            : UncheckedTemplate}
                          <Text>{item.name}</Text>
                        </View>
                        <View
                          style={[pageStyle.procedureWidth, pageStyle.textBase]}
                        >
                          <Template item={item} />
                        </View>
                      </View>
                    );
                  })}
                </View>
              </>
            ))}
          </View>
        </>
      )}
      {(commentsPresent || checklistPresent) && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <View style={pageStyle.columns}>
              {checklistPresent && (
                <View style={pageStyle.column}>
                  <Text style={pageStyle.h2NoMargin}>
                    {t("work_orders.details.checklist")}
                  </Text>
                  <PDFChecklist items={checklist} />
                </View>
              )}
              {commentsPresent && checklistPresent && (
                <View style={pageStyle.columnDivider} />
              )}
              {commentsPresent && (
                <View style={pageStyle.column}>
                  <Text style={pageStyle.h2NoMargin}>
                    {t("work_orders.pdf.titles.comments", {
                      number: comments.length,
                    })}
                  </Text>
                  <View style={{ gap: 6 }}>
                    {comments.map((comment) => (
                      <View style={pageStyle.textBase}>
                        <Text style={[pageStyle.textSm, pageStyle.textGrey]}>
                          {displayDate(comment.created_at, true)}
                        </Text>
                        <Text style={pageStyle.textSemiBold}>
                          {`${comment?.created_by.name} ${comment?.created_by.surname} `}
                          {comment.updated_by_id &&
                            t("work_orders.comments.edited")}
                        </Text>
                        <Text>{comment.content}</Text>
                      </View>
                    ))}
                  </View>
                </View>
              )}
            </View>
          </View>
        </>
      )}
      {work_order_times.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>{t("work_order_time.title")}</Text>
            {work_order_times.map(
              ({
                amount,
                amount_in_minutes,
                created_at,
                user,
                total_time_cost,
              }) => (
                <View style={pageStyle.rowDetailed}>
                  <Text>{user?.full_name}</Text>
                  <Text>
                    {displayDate(created_at ? created_at : new Date(), true)}
                  </Text>
                  <Text>
                    {amount_in_minutes ? getTimes(amount_in_minutes) : amount}
                  </Text>
                  <Text>{formatCurrency(total_time_cost, currency)}</Text>
                </View>
              )
            )}
          </View>
        </>
      )}
      {partsTransactions.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>{t("work_order_parts.title")}</Text>
            {partsTransactions.map(({ part, quantity }) => {
              const unitMeasure = part.measurement_unit?.id
                ? measurementUnits[part.measurement_unit.id].short_name
                : t("parts.measurement_units.fallback.short_name");

              return (
                <View style={pageStyle.rowDetailed}>
                  <Text>{part.label}</Text>
                  <Text>
                    {quantity} {unitMeasure}
                  </Text>
                </View>
              );
            })}
          </View>
        </>
      )}
      {other_costs.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>
              {t("work_order_other_costs.title")}
            </Text>
            {other_costs.map(({ title, vendor, cost }) => (
              <View style={pageStyle.rowDetailed}>
                <Text>{title}</Text>
                <Text>{vendor?.label || vendor?.name || ""}</Text>
                <Text>{formatCurrency(cost, currency)}</Text>
              </View>
            ))}
          </View>
        </>
      )}
    </>
  );
};
