import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";

import { useGetFormFieldsQuery } from "modules/formFields";

import { useForm } from "shared/lib/hooks/useForm";
import { EditorModal } from "shared/components/Modals";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import {
  useUpdateVendorMutation,
  useGetVendorQuery,
} from "modules/vendors/state/vendorsApi";
import { addVendorValidationSchema } from "../../configs/validationSchema";
import { VendorEditor } from "../VendorEditor";
import {
  getFormatForVendorService,
  getOptionalFields,
  getInitialValues,
} from "../../lib/helpers";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";
import { formatUrls } from "shared/lib/helpers/formatUrls";

export const VendorUpdate = ({ isShow, onClose, onSuccess, vendorId }) => {
  const { addSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const { data } = useGetFormFieldsQuery({
    type: "vendors",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(data);

  const { data: vendorData } = useGetVendorQuery(vendorId ?? skipToken);

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [updateVendor, updateVendorRes] = useUpdateVendorMutation();

  const vendorDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data: vendorData }),
    [vendorData]
  );

  const { setValues, ...updateVendorFrom } = useForm({
    initialValues: vendorDataForForm,
    willUpdatedValues: vendorDataForForm,
    validationSchema: addVendorValidationSchema(optionalFields, t),
    onSubmit: async (body, { setFieldError }) => {
      body.urls_attributes = formatUrls(body.urls_attributes);
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const vendor = getFormatForVendorService(body);
      setDisabledSubmit(true);
      try {
        await updateVendor({
          id: vendorId,
          body: vendor,
        }).unwrap();
        setDisabledSubmit(false);
        onSuccess(vendorId);
      } catch ({ message }) {
        if (message.name?.[0] === "has already been taken") {
          setFieldError("name", t("m11", { ns: "errors" }));
        } else {
          addSnackBar({
            title: t("vendors.snack.failedUpdate"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        }
        setDisabledSubmit(false);
      }
    },
  });

  const onCloseModal = () => {
    onClose();
  };

  return (
    <EditorModal
      label={vendorData ? t("vendors.edit", { id: vendorData.id }) : null}
      isOpen={isShow}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updateVendorFrom.handleSubmit}
      disabledSubmit={
        !updateVendorFrom.isWasChanged ||
        !updateVendorFrom.isFormValid ||
        disabledSubmit
      }
      form={updateVendorFrom}
      isSubmitLoading={updateVendorRes.isLoading || updateVendorRes.isSuccess}
    >
      <VendorEditor form={updateVendorFrom} optionalFields={optionalFields} />
    </EditorModal>
  );
};

VendorUpdate.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
};
