import styled from "styled-components";

export const ModuleTitle = styled.div`
  color: var(--color_primary_text);
  font-weight: 400;
  font-size: 18px;
`;
export const ModuleBox = styled.div`
  background: var(--color_grey10);
  text-align: center;
  padding: 16px;
  gap: 16px;
  width: 90%;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: var(--color_primary_hover);
    color: var(--color_dark_blue);
  }
`;

export const ModuleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;
