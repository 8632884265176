import { ReactNode, useReducer, createContext, Dispatch } from "react";

export enum WorkOrderActionType {
  allowCompletion = "allowCompletion",
  dismissProcedureCommentWarningBanner = "dismissProcedureCommentWarningBanner",
  dismissProcedureWarningBanner = "dismissProcedureWarningBanner",
  showProcedureCommentWarningBanner = "showProcedureCommentWarningBanner",
  showProcedureWarningBanner = "showProcedureWarningBanner",
}

export interface WorkOrderAction {
  type: WorkOrderActionType;
  value?: unknown;
}

interface WorkOrderState {
  allowCompletion: boolean;
  procedureWarningDismissed: boolean;
  showProcedureCommentWarningBanner: boolean;
  showProcedureWarningBanner: boolean;
}

interface WorkOrderProviderProps {
  children: ReactNode;
}

const initialState: WorkOrderState = {
  allowCompletion: false,
  procedureWarningDismissed: false,
  showProcedureCommentWarningBanner: false,
  showProcedureWarningBanner: false,
};

const workOrderReducer = (state: WorkOrderState, action: WorkOrderAction) => {
  switch (action.type) {
    case WorkOrderActionType.showProcedureWarningBanner:
      return {
        ...state,
        showProcedureWarningBanner: action.value as boolean,
        allowCompletion: true,
      };

    case WorkOrderActionType.dismissProcedureWarningBanner:
      return {
        ...state,
        showProcedureWarningBanner: false,
        procedureWarningDismissed: true,
        allowCompletion: true,
      };

    case WorkOrderActionType.showProcedureCommentWarningBanner:
      return {
        ...state,
        showProcedureCommentWarningBanner: true,
        showProcedureWarningBanner: false,
        allowCompletion: false,
      };

    case WorkOrderActionType.dismissProcedureCommentWarningBanner:
      return {
        ...state,
        showProcedureCommentWarningBanner: false,
        allowCompletion: true,
      };

    default:
      return state;
  }
};

export const WorkOrderContext = createContext<{
  state: WorkOrderState;
  dispatch: Dispatch<WorkOrderAction>;
}>({
  state: initialState,
  dispatch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export function WorkOrderProvider({ children }: WorkOrderProviderProps) {
  const [state, dispatch] = useReducer(workOrderReducer, initialState);

  return (
    <WorkOrderContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkOrderContext.Provider>
  );
}
