import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 3fr 3fr;
  align-items: center;
  gap: 12px;
  padding: 34px;
`;

export const Error = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 34px;
  font-weight: 600;
`;

export const PieChartWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 7px;
`;

export const SuperLabel = styled.div`
  font-size: 16px;
  font-weight: 599;
`;

export const Label = styled.div`
  font-weight: 599;
  font-size: 15px;
`;
/** Props that are shared between the WosWaitingForParts and DonutChartSkeleton */
export const pieProps = {
  startAngle: 450,
  endAngle: 90,
  innerRadius: "40%",
  outerRadius: "100%",
  stroke: "none",
  isAnimationActive: false,
};
/** The amount (in pixels) to increase the outerRadius of the active slice by */
export const expandActiveBy = 2;
export const margin = expandActiveBy + 1;
export const chartSize = 110;
export const chartMargins = {
  top: margin,
  bottom: margin,
  left: margin,
  right: margin,
};

export const pieChartColors = [
  "var(--color_open)",
  "var(--color_in_progress)",
  "var(--color_priority_red)",
  "var(--color_priority_yellow)",
  "var(--color_priority_lime)",
  "var(--color_in_review)",
  "var(--color_chart_green)",
  "var(--color_dark_orange)",
  "var(--color_salmon)",
];
