import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery } from "modules/categories";
import { useGetPartsQuery } from "modules/parts";
import { useGetLocationsQuery } from "modules/locations";
import { useGetAssetsQuery } from "modules/assets";
import { Table } from "shared/components/Table";
import {
  useSearch,
  usePageParam,
  useSortParam,
  useFiltersParam,
} from "shared/lib/hooks";

import { TopBar } from "widgets/TopBar";
import { BulkActions } from "./BulkActions";
import {
  useGetVendorsQuery,
  useGetVendorContactsQuery,
  useExportVendorsMutation,
} from "modules/vendors/state/vendorsApi";
import { RowDecoratorImage } from "shared/components/Table/components/RowDecoratorImage";
import { useSearchParam } from "shared/lib/hooks";
import { Pagination } from "features/Pagination/index.jsx";
import { useTranslation } from "react-i18next";
import { stringifyFilters } from "modules/filters/lib/stringifyFilters";
import { useIsLoading } from "shared/lib/hooks/useIsLoading";
import { useVendorsTableTools } from "modules/vendors/lib/useVendorsTableTools";
import { EmptyVendorsList } from "./EmptyVendorsList";
import { getFiltersByKey } from "modules/filters/lib/filterStorage";

export const List = ({ withExport, onOpen = () => {} }) => {
  const { t } = useTranslation();
  const [search] = useSearchParam();
  const [page] = usePageParam(1);
  const [sort] = useSortParam();

  const storedFilters = getFiltersByKey("vendors") ?? [];
  const [filters] = useFiltersParam(storedFilters);
  const stringifiedFilters = stringifyFilters(filters, "vendors");

  const [selectionModel, setSelectionModel] = useState([]);

  const [exportVendors] = useExportVendorsMutation();
  const { tableSettings, applySettings, columns, areTableSettingsFetching } =
    useVendorsTableTools({
      onOpen,
    });

  const { data, error, isFetching } = useGetVendorsQuery({
    page,
    sort,
    search,
    per_page: tableSettings?.per_page,
    ...stringifiedFilters,
  });
  const isLoading = useIsLoading(isFetching, [search, page, sort, filters]);

  useEffect(() => {
    if (data) {
      setSelectionModel([]);
    }
  }, [data]);

  const hasFilter = Object.keys(filters).length > 0;

  const [categories, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    true
  );

  const [parts, onPartsSearch] = useSearch(useGetPartsQuery, true);

  const [locations, onLocationsSearch] = useSearch(useGetLocationsQuery, true);

  const [assets, onAssetsSearch] = useSearch(useGetAssetsQuery, true);

  const [vendorContacts, onVendorContactsSearch] = useSearch(
    useGetVendorContactsQuery,
    true
  );

  const getFilterOptions = (field) => {
    if (field === "categories") {
      return categories?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "parts") {
      return parts?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "locations") {
      return locations?.data.map(({ id, path }) => ({ key: id, value: path }));
    }
    if (field === "assets") {
      return assets?.data.map(({ id, name }) => ({ key: id, value: name }));
    }
    if (field === "vendor_contacts") {
      return vendorContacts?.data.map(({ id, name }) => ({
        key: id,
        value: name,
      }));
    }
    return [];
  };

  const getFilterSearch = (field) => {
    if (field === "locations") {
      return onLocationsSearch;
    }
    if (field === "categories") {
      return onCategoriesSearch;
    }
    if (field === "parts") {
      return onPartsSearch;
    }
    if (field === "assets") {
      return onAssetsSearch;
    }
    if (field === "vendor_contacts") {
      return onVendorContactsSearch;
    }

    return () => {};
  };

  const empty = data?.data?.length === 0 && !isFetching;
  const notFound = empty && (search?.length > 0 || hasFilter);

  if (empty && !notFound) {
    return <EmptyVendorsList />;
  } else if (error) {
    return <div>Error...</div>;
  }

  return (
    <>
      <TopBar
        entity="vendors"
        tableSettings={tableSettings}
        setTableSettings={applySettings}
        columns={columns}
        getOptions={getFilterOptions}
        getSearch={getFilterSearch}
        onExportClick={() => exportVendors({ ...stringifiedFilters, search })}
        withExport={withExport}
        initialFilters={storedFilters}
      />
      <Table
        columns={columns}
        rows={data?.data}
        highlight={search}
        selectionModel={selectionModel}
        onSelectionModelChange={setSelectionModel}
        showSkeleton={isLoading || areTableSettingsFetching}
        notFound={notFound}
        search={search}
        entityTranslationKey="vendors.title"
        bulkActionsNode={<BulkActions selectionModel={selectionModel} />}
        getRowIcon={(row) => (
          <RowDecoratorImage src={row.images?.[0]?.attachment_urls?.small} />
        )}
      />
      <Pagination
        paginationData={data?.meta.pagination}
        label={t("vendors.title_other")}
      />
    </>
  );
};
