import styled from "styled-components";
import { H1, H6, Hint } from "shared/components/Typography";
import Skeleton from "react-loading-skeleton";

interface TextItemProps {
  value?: number | string;
  label?: string | null;
  description?: string;
  isLoading?: boolean;
}

export const PartsKPIsTextItem = ({
  value,
  label,
  description,
  isLoading,
}: TextItemProps) => {
  return (
    <Wrapper>
      <H1 as="span">{isLoading ? <Skeleton width="40%" /> : value}</H1>
      <H6 as="span">{isLoading ? <Skeleton width="70%" /> : label}</H6>
      <Hint>{isLoading ? <Skeleton width="55%" /> : description}</Hint>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%; // 38px;
  flex-direction: column;
  justify-content: center;
`;
