import React from "react";
import { useTranslation } from "react-i18next";
import { Text, View, Image } from "@react-pdf/renderer";

import {
  minPresenceAhead,
  pageStyle,
  normalizeLineEndings,
} from "shared/components/BulkPdfExport/BulkPdfExport.styled.js";
import { useDisplayDate } from "shared/lib/hooks";
import { formatDateWithDayOfWeek } from "shared/lib/helpers/common";
import { getRepeatsText } from "modules/preventiveMaintenances";
import { convertTimeToCompleteHoursToDays } from "../../lib/helpers/index";

import DocumentIcon from "shared/assets/icon/pdf/document.png";
import CriticalIcon from "shared/assets/icon/pdf/critical.png";
import CompletedIcon from "shared/assets/icon/pdf/completed.png";
import HighIcon from "shared/assets/icon/pdf/high.png";
import HoldIcon from "shared/assets/icon/pdf/hold.png";
import LowIcon from "shared/assets/icon/pdf/low.png";
import MediumIcon from "shared/assets/icon/pdf/medium.png";
import NoneIcon from "shared/assets/icon/pdf/none.png";
import ProgressIcon from "shared/assets/icon/pdf/progress.png";

import { PDFChecklist } from "shared/components/PDFChecklist";
import { measurementUnits } from "modules/parts/configs/constant";

const StatusMap = {
  completed: CompletedIcon,
  planned: ProgressIcon,
  paused: HoldIcon,
};

const PriorityMap = {
  none: NoneIcon,
  low: LowIcon,
  medium: MediumIcon,
  high: HighIcon,
  critical: CriticalIcon,
};

export const PMsPdfPage = ({ resource = {} }) => {
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const {
    template_work_order: {
      asset,
      location,
      title: name,
      priority,
      description,
      images = [],
      documents = [],
      vendors = [],
      categories = [],
      type,
      // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
      // teams = [],
      technicians = [],
      checklist,
      parts_transactions,
    },
    id,
    updated_at,
    status,
    type: pm_type,
    start_date,
    time_to_complete,
    next_order_date,
    created_at,
    created_by,
    repeat_occurrence,
    repeat_frequency,
    on_month_day,
    on_week_days,
    in_months,
    procedure_templates,
  } = resource;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const StatusIcon = StatusMap[status];
  const PriorityIcon = PriorityMap[priority];

  const createdByName =
    created_by && `${created_by.name || ""} ${created_by.surname || ""}`;

  const repeatText =
    pm_type === "one_time"
      ? t(
          "work_orders.form.pm_schedule_attributes.information.work_order_detail",
          {
            value: next_order_date
              ? formatDateWithDayOfWeek(next_order_date, true)
              : "",
          }
        )
      : getRepeatsText({
          repeat_occurrence: repeat_occurrence,
          repeat_frequency: repeat_frequency,
          on_month_day: on_month_day,
          on_week_days: on_week_days,
          in_months: in_months,
          scheduleType: pm_type,
        });

  return (
    <>
      <View style={pageStyle.row}>
        <View style={pageStyle.iconContainer}>
          <Image src={PriorityIcon} style={pageStyle.icon} />
          <Text style={pageStyle.textSm}>
            {`${t("work_orders.details.priority")} ${t(
              `work_orders.priority.${priority}`
            )}`}
          </Text>
        </View>
        <Text style={[pageStyle.textSm, pageStyle.textBold]}>
          {t("work_orders.pdf.pm_id", { id })}
        </Text>
      </View>
      <View style={pageStyle.section}>
        <View style={pageStyle.description}>
          <Text style={pageStyle.h1}>{name}</Text>
          <Text style={pageStyle.textBase}>
            {normalizeLineEndings(description)}
          </Text>
        </View>
      </View>
      <View
        style={pageStyle.border}
        minPresenceAhead={minPresenceAhead.section}
      />
      <View style={pageStyle.section}>
        <View style={pageStyle.detailsHeading}>
          <Text style={pageStyle.h2NoMargin}>
            {t("modals.view.details.title")}
          </Text>
          <Text style={[pageStyle.detailsMessage, pageStyle.h2NoMargin]}>
            ·
          </Text>
          <Text style={pageStyle.detailsMessage}>
            {`${t("modals.view.details.last_update_message", {
              date: displayDate(updated_at),
            })}`}
          </Text>
        </View>
        <View style={pageStyle.columns}>
          <View style={pageStyle.column}>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.pdf.fields.status")}
              </Text>
              <View style={[pageStyle.iconContainer, pageStyle.detailsValue]}>
                {StatusIcon && (
                  <Image src={StatusIcon} style={pageStyle.icon} />
                )}
                <Text>
                  {status && t(`preventive_maintenances.status.${status}`)}
                </Text>
              </View>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.type")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {type && t(`work_orders.type.${type}`)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("preventive_maintenances.details.start_date")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {displayDate(start_date, true)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("preventive_maintenances.details.time_to_complete")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {time_to_complete
                  ? convertTimeToCompleteHoursToDays(time_to_complete)
                  : ""}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.categories")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {categories.map(({ name }) => name).join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelLeft}>
                {t("work_orders.details.location")}
              </Text>
              <Text style={pageStyle.detailsValue}>{location?.path}</Text>
            </View>
          </View>
          <View style={pageStyle.column}>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.asset")}
              </Text>
              <Text style={pageStyle.detailsValue}>{asset?.name}</Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.assign_to")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {technicians
                  .map(({ name, surname }) => `${name} ${surname}`)
                  .join(", ")}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.pdf.fields.created_by")}
              </Text>
              <Text style={pageStyle.detailsValue}>{createdByName}</Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.pdf.fields.created_at")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {displayDate(created_at)}
              </Text>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("work_orders.details.vendors")}
              </Text>
              <View style={pageStyle.vendors}>
                {vendors.map((vendor) => (
                  <View key={vendor.id}>
                    <Text style={pageStyle.textSemiBold}>{vendor.name}</Text>
                    {vendor.vendor_contacts.map((contact) => (
                      <View>
                        {!!contact.is_primary && (
                          <View>
                            <Text>{contact?.name}</Text>
                            <Text>{contact?.phone}</Text>
                            <Text>{contact?.email}</Text>
                          </View>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
            <View style={pageStyle.detailsField}>
              <Text style={pageStyle.detailsLabelRight}>
                {t("preventive_maintenances.details.schedule")}
              </Text>
              <Text style={pageStyle.detailsValue}>
                {pm_type &&
                  `${t(
                    `work_orders.schedule_type.${pm_type}`
                  )} (${repeatText})`}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {files.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.filesSection}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>
              {t("work_orders.pdf.titles.media_and_files")}
            </Text>
            <View style={pageStyle.detailsField}>
              <View style={pageStyle.imageRow}>
                {images.map((image) => (
                  <Image src={image.url} style={pageStyle.image} />
                ))}
                {documents.map((file) => (
                  <View style={pageStyle.documentIcon}>
                    <Image src={DocumentIcon} style={pageStyle.logo} />
                    <Text style={[pageStyle.textSm, pageStyle.textGrey]}>
                      {file.name}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </>
      )}
      {procedure_templates?.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <Text style={pageStyle.h2}>{t("procedures.title")}</Text>
          <View style={pageStyle.procedures}>
            {procedure_templates.map(({ id, name, items }) => (
              <>
                <View minPresenceAhead={minPresenceAhead.subSection} />
                <Text style={pageStyle.h3} key={id}>
                  {name}
                </Text>
                <View>
                  {items.map((item) => {
                    return (
                      <View style={pageStyle.procedureItemName}>
                        <Text style={pageStyle.h2NoMargin}>·</Text>
                        <Text>{item.name}</Text>
                      </View>
                    );
                  })}
                </View>
              </>
            ))}
          </View>
        </>
      )}
      {checklist && checklist.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>
              {t("work_orders.details.checklist")}
            </Text>
            <PDFChecklist items={checklist} isPm />
          </View>
        </>
      )}
      {partsTransactions.length > 0 && (
        <>
          <View
            style={pageStyle.border}
            minPresenceAhead={minPresenceAhead.section}
          />
          <View style={pageStyle.section}>
            <Text style={pageStyle.h2}>{t("work_order_parts.title")}</Text>
            {partsTransactions.map(({ part, quantity }) => {
              const unitMeasure = part.measurement_unit?.id
                ? measurementUnits[part.measurement_unit.id].short_name
                : t("parts.measurement_units.fallback.short_name");

              return (
                <View style={pageStyle.rowDetailed}>
                  <Text>{part.label}</Text>
                  <Text>
                    {quantity} {unitMeasure}
                  </Text>
                </View>
              );
            })}
          </View>
        </>
      )}
    </>
  );
};
