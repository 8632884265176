import { useFormik } from "formik";
import { IProcedureItem } from "modules/procedures/types/types";
import { Checkbox } from "./Checkbox";
import { DateInput } from "./DateInput";
import { Inspection } from "./Inspection";
import { MultipleChoice } from "./MultipleChoice";
import { TextInput } from "./TextInput";
import { YesNoNa } from "./YesNoNa";

interface ProcedureItemProps {
  item: IProcedureItem;
  form: ReturnType<typeof useFormik>;
}

export const ProcedureItem = ({ item, form }: ProcedureItemProps) => {
  const handleChange = (value: unknown) => {
    if (value != null) {
      form.setFieldValue(item.name, value);
    }
  };

  if (item == null) {
    return null;
  }

  switch (item.item_type) {
    case "text":
      return <TextInput item={item} onChange={handleChange} />;

    case "checkbox":
      return <Checkbox item={item} onChange={handleChange} />;

    case "number":
      return <TextInput item={item} type="number" onChange={handleChange} />;

    case "date":
      return <DateInput item={item} onChange={handleChange} />;

    case "multiple_choice":
      return <MultipleChoice item={item} onChange={handleChange} />;

    case "yes_no_na":
      return <YesNoNa item={item} onChange={handleChange} />;

    case "inspection":
      return <Inspection item={item} onChange={handleChange} />;

    default:
      return <p>{item.name}</p>;
  }
};
