import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetFormFieldsQuery } from "modules/formFields";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { partValidationSchema } from "../../configs";
import { useGetPartQuery, useUpdatePartMutation } from "modules/parts";

import {
  getPartFormatForService,
  getOptionalFields,
  getInitialValues,
  canEditCostFields,
} from "../../lib/helpers";
import { PartEditor } from "../PartEditor";
import { validateURLsAttributesList } from "shared/lib/helpers/validateURLsAttributesList";
import { formatUrls } from "shared/lib/helpers/formatUrls";

export const PartEdit = ({ isShow, onClose, onOpen, partId }) => {
  const { t } = useTranslation();

  const { addSnackBar } = useSnackBar();
  const [updatePart, updatePartRes] = useUpdatePartMutation();

  const { data: fields } = useGetFormFieldsQuery({
    type: "parts",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(fields);

  const [generateQr, setGenerateQr] = useState(false);

  const { data } = useGetPartQuery(partId ?? skipToken);

  const isEditCostFields = useMemo(() => canEditCostFields(data), [data]);

  const partDataForForm = useMemo(
    () => getInitialValues({ type: "edit", data }),
    [data]
  );

  const { setValues, ...updatePartForm } = useForm({
    initialValues: partDataForForm,
    validationSchema: partValidationSchema(t, optionalFields),
    willUpdatedValues: partDataForForm,
    enableReinitialize: true,
    onSubmit: async (body, { setFieldError }) => {
      body.urls_attributes = formatUrls(body.urls_attributes);
      const urlsValid = validateURLsAttributesList(
        body.urls_attributes ?? [],
        setFieldError
      );
      if (!urlsValid) return;

      const part = getPartFormatForService(body, { generateQr, type: "edit" });
      updatePart({ id: partId, body: part })
        .unwrap()
        .then(({ data }) => {
          addSnackBar({
            title: t("parts.snack.successUpdateTitle"),
            content: t("parts.snack.successUpdateMessage", {
              name: data.name,
            }),
            type: "success",
            primaryButtonLabel: t("parts.snack.view"),
            secondaryButtonLabel: t("close"),
            handlePrimaryButtonClick: () => onOpen(data.id),
          });
          onCloseModal();
        })
        .catch(({ message }) => {
          if (message.name?.[0] === RESPONSE_MESSAGE.already_taken) {
            setFieldError("name", t("m100", { ns: "errors" }));
          } else if (
            message["qr_code.value"]?.[0] === RESPONSE_MESSAGE.already_taken
          ) {
            setFieldError("code", t("m2", { ns: "errors" }));
          } else {
            addSnackBar({
              title: t("parts.snack.failCreationTitle"),
              content: JSON.stringify(message),
              type: "error",
              secondaryButtonLabel: t("close"),
            });
          }
        });
    },
  });

  const onCloseModal = () => {
    updatePartForm.resetForm();
    onClose();
    setGenerateQr(false);
  };

  return (
    <EditorModal
      isOpen={isShow}
      label={t("parts.edit", { id: partId })}
      onClose={onClose}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updatePartForm.handleSubmit}
      disabledSubmit={
        (!updatePartForm.isWasChanged && !generateQr) ||
        !updatePartForm.isFormValid
      }
      form={updatePartForm}
      isSubmitLoading={updatePartRes.isLoading || updatePartRes.isSuccess}
    >
      <PartEditor
        type="edit"
        form={updatePartForm}
        optionalFields={optionalFields}
        generateQr={generateQr}
        setGenerateQr={setGenerateQr}
        isEditCostFields={isEditCostFields}
      />
    </EditorModal>
  );
};
