import { downloadFile } from "shared/lib/helpers";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { CMMSAPI } from "app/services";
import I18n from "../../../app/i18n.js";

const renameNoPartTypeLabels = (res) => {
  const data = res.data;
  if (data.top_part_types_count?.length > 0) {
    data.top_part_types_count.forEach((c) => {
      if (c.label === "none")
        c.label = I18n.t("reporting.parts_inventory.none");
    });
  }
  if (data.top_part_types_cost?.length > 0) {
    data.top_part_types_cost.forEach((c) => {
      if (c.label === "none")
        c.label = I18n.t("reporting.parts_inventory.none");
    });
  }
  if (data.part_types?.length > 0) {
    data.part_types.forEach((c) => {
      if (c.part_type === "none")
        c.part_type = I18n.t("reporting.parts_inventory.none");
    });
  }
  return data;
};
export const reportingApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWidgetData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/${reportId}`,
        params,
      }),
      transformResponse: (res) => res.data,
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getReportKPIs: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/kpis/${reportId}`,
        params,
      }),
      transformResponse: (res) => renameNoPartTypeLabels(res),
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getChartData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/chart_data/${reportId}`,
        params,
      }),
      transformResponse: (res) => renameNoPartTypeLabels(res),
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    getTableData: builder.query({
      query: ({ reportId, ...params }) => ({
        url: `/reporting/table_data/${reportId}`,
        params,
      }),
      providesTags: () => [{ type: "Entity" }, { type: "Report" }],
    }),
    downloadXLS: builder.mutation({
      queryFn: async (
        { reportId, ...params },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `reporting/download/${reportId}`,
          method: "GET",
          params,
          responseHandler: (response) => response.blob(),
        });

        const filename = getReportFilename(reportId, "xls", {
          startDate: params.start_date,
          endDate: params.end_date,
        });
        const url = URL.createObjectURL(result.data);
        downloadFile(url, filename);
        return {};
      },
    }),
  }),
});

export const {
  useGetWidgetDataQuery,
  useGetReportKPIsQuery,
  useGetChartDataQuery,
  useGetTableDataQuery,
  useDownloadXLSMutation,
} = reportingApi;
