import styled from "styled-components";
import { Label as AriaLabel } from "react-aria-components";

export const VerticalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
`;

export const HorizontalItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled(AriaLabel)`
  font-size: 14px;
`;
