import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "shared/components/Checkbox";
import {
  Box,
  Title,
  CheckboxContainer,
  TextBox,
  Text,
  Order,
} from "./WorkOrderChecklist.styled";

export const WorkOrderChecklist = ({
  value = [],
  onUpdate,
  withCheckbox = true,
}) => {
  const { t } = useTranslation();

  const onClick = (order, checked) => {
    const newValue = [...value].map((val) =>
      val.order === order ? { ...val, checked } : val
    );
    onUpdate(newValue);
  };

  return (
    <div>
      <Title>{t("work_orders.details.checklist")}</Title>
      <Box>
        {value.map((val) => {
          const { checked, order, text } = val;
          return (
            <CheckboxContainer key={order} withCheckbox={withCheckbox}>
              {withCheckbox && (
                <Checkbox
                  checked={checked === "true"}
                  onClick={(e) =>
                    onClick(order, checked === "true" ? "false" : "true")
                  }
                />
              )}
              <TextBox>
                <Order>{order}.</Order>
                <Text checked={checked === "true"}>{text}</Text>
              </TextBox>
            </CheckboxContainer>
          );
        })}
      </Box>
    </div>
  );
};
