import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";

import { getUser } from "shared/components/Auth";
import { EditorModal } from "shared/components/Modals";
import { useForm } from "shared/lib/hooks/useForm";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";

import { useGetFormFieldsQuery } from "modules/formFields";

import {
  WorkOrderEditor,
  workOrderValidationSchema,
  getInitialValues,
  getOptionalFields,
} from "modules/workOrders";

import { useGetPMQuery, useUpdatePMMutation } from "modules/workOrders";
import { getPMFormatForService } from "../../lib/helpers/form";
import { usePermission } from "app/providers/PermissionsProvider";

const hiddenFields = [
  "title",
  "priority",
  "categories_ids",
  "assign_to",
  "type",
  "due_date",
  "pm_schedule_attributes",
  "set_asset_to_offline",
  "checklist",
];

export const PMEdit = ({
  isShow,
  onClose,
  onOpen,
  preventiveMaintenanceId,
}) => {
  const { t } = useTranslation();
  const { canEditPmsSchedule } = usePermission();
  const currentUser = getUser();
  const { data, isLoading } = useGetPMQuery(
    preventiveMaintenanceId ?? skipToken
  );

  const { addSnackBar } = useSnackBar();
  const [updatePM, updatePMRes] = useUpdatePMMutation();

  const { data: fields } = useGetFormFieldsQuery({
    type: "work_orders",
    action_type: "upsert",
  });

  const optionalFields = getOptionalFields(fields);

  const pmDataForForm = useMemo(
    () =>
      getInitialValues({
        type: "edit",
        data: {
          ...data?.template_work_order,
          procedure_templates: data?.procedure_templates,
        },
      }),
    [data]
  );

  const { ...updatePMForm } = useForm({
    initialValues: pmDataForForm,
    validationSchema: workOrderValidationSchema(t, optionalFields),
    willUpdatedValues: pmDataForForm,
    enableReinitialize: true,
    onSubmit: async (body) => {
      const pm = getPMFormatForService(body);
      updatePM({ id: preventiveMaintenanceId, body: pm })
        .unwrap()
        .then(() => {
          onOpen(preventiveMaintenanceId);
        })
        .catch(({ message }) => {
          addSnackBar({
            title: t("preventive_maintenances.snack.failUpdateTitle"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: t("close"),
          });
        });
    },
  });

  const disabledFields =
    canEditPmsSchedule(
      data?.created_by_id,
      data?.template_work_order?.technicians,
      data?.template_work_order?.status
    ) === "partial"
      ? hiddenFields
      : [];

  const onCloseModal = () => {
    updatePMForm.resetForm();
    onClose();
  };

  return (
    <EditorModal
      isOpen={isShow}
      label={t("preventive_maintenances.edit", { id: preventiveMaintenanceId })}
      onClose={onCloseModal}
      secondaryButtonLabel={t("discardChanges")}
      onSubmit={updatePMForm.handleSubmit}
      disabledSubmit={!updatePMForm.isWasChanged || !updatePMForm.isFormValid}
      isSubmitLoading={updatePMRes.isLoading || updatePMRes.isSuccess}
      form={updatePMForm}
    >
      {!isLoading && (
        <WorkOrderEditor
          type="edit"
          module="pm"
          form={updatePMForm}
          userRole={currentUser?.role}
          optionalFields={optionalFields}
          hasUncompletedWO={data?.has_uncompleted_wo}
          hasWorkOrders={data?.has_work_orders}
          scheduleType={data?.type}
          scheduleStatus={data?.status}
          disabledFields={disabledFields}
        />
      )}
    </EditorModal>
  );
};
