import { useTranslation } from "react-i18next";
import { ValueByPartType } from "../types";
import { SliceConfig } from "./types";
import { pieChartColors } from "../../PartsKPIs.styled";
import i18n from "i18next";

const MAX_SLICES = 8;

export function useSliceConfigs() {
  const { t } = useTranslation();

  const partTypeConfigs = (
    values: ValueByPartType[] = []
  ): Record<string, SliceConfig> => {
    if (values.length === 0) {
      return {};
    }
    const record: Record<string, SliceConfig> = {};
    let cloned = Array.from(values);
    cloned = cloned.sort((a, b) => {
      return b.percentage - a.percentage;
    });
    cloned.forEach((value, currentIndex) => {
      if (Object.keys(record).length >= MAX_SLICES) {
        record[t("parts.kpis.other")] = {
          label: i18n.t("parts.kpis.other"),
          fill: pieChartColors[MAX_SLICES],
        } as SliceConfig;
      } else {
        record[value.part_type] = {
          label:
            value.part_type === "no_type"
              ? t("parts.kpis.noType")
              : value.part_type,
          fill: pieChartColors[currentIndex],
        } as SliceConfig;
      }
    });
    return record;
  };
  const wosWaitingForPartsConfig = {
    ready: {
      fill: "var(--color_blue)",
      label: t("parts.kpis.waitingForParts.ready"),
    },
    waiting: {
      fill: "var(--color_priority_lime)",
      label: t("parts.kpis.waitingForParts.waiting"),
    },
  };

  return { partTypeConfigs, wosWaitingForPartsConfig };
}
