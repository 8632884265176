import { BacklogByStatusKPIs } from "./types";
import { useTranslation } from "react-i18next";
import {
  formatNumber,
  formatPercent,
  formatUnitDuration,
} from "modules/reporting/lib/formatters";
import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { StringifiedFilters } from "modules/reporting/types/StringifiedFilters";

const reportKey = ReportKey.BacklogByStatus;

export const useBacklogByStatusKPIs = (
  stringifiedFilters?: StringifiedFilters
) => {
  const { data, ...rest } = useGetKPIs<BacklogByStatusKPIs>(
    reportKey,
    undefined,
    stringifiedFilters
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return {
    ...rest,
    data: [
      {
        value: formatNumber(data?.total_work_orders, language),
        label: t(`reporting.${reportKey}.kpis.total_work_orders`),
      },
      {
        value: formatPercent(data?.reactive_percentage, language),
        label: t(`reporting.${reportKey}.kpis.reactive_percentage`),
      },
      {
        value: formatUnitDuration(data?.avg_age, "days", language, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
        label: t(`reporting.${reportKey}.kpis.avg_age`),
      },
      {
        value: formatPercent(data?.preventive_percentage, language),
        label: t(`reporting.${reportKey}.kpis.preventive_percentage`),
      },
      {
        value: formatNumber(data?.overdue_count, language),
        label: t(`reporting.${reportKey}.kpis.overdue_count`),
      },
    ],
  };
};
