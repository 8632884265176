import DromoUploader, {
  IColumnHook,
  IDeveloperSettings,
  IUser,
} from "dromo-uploader-react";

export const defaultSettings = {
  autoMapHeaders: true,
  matchingStep: {
    headerRowOverride: 0,
    matchToSchema: true,
  },
  reviewStep: {
    enableUserTransformations: true,
    highlightAutoFixes: true,
  },
  developmentMode: true,
};

const defaultHooks = [
  {
    fieldName: "asset_type",
    callback: (values: any) => {
      return values.map((value: any) => {
        return {
          index: value.index,
          value: "___" + value.value,
          info: [
            {
              message:
                "We sent this column to the ClickMaint FE app and dynamically added '___' to the front of the asset type. This can be helpful for changes values based on pre-existing data.",
              level: "info",
            },
          ],
        };
      });
    },
  },
];
export const DromoImporter = ({
  schemaId,
  settings,
  hooks,
  user,
  onCancel,
  onResults,
  buttonText,
}: DromoImporterProps) => {
  let mergedSettings = Object.assign(defaultSettings, settings);
  return (
    <DromoUploader
      licenseKey="64fc8b52-59c7-4015-b03f-a3322cbdb854"
      schemaId={schemaId}
      settings={mergedSettings}
      columnHooks={hooks || defaultHooks}
      user={user}
      onCancel={onCancel}
      onResults={onResults}
    >
      {buttonText}
    </DromoUploader>
  );
};

interface DromoImporterProps {
  schemaId: string;
  settings?: IDeveloperSettings;
  hooks?: IColumnHook[];
  user?: IUser;
  onCancel?: () => void;
  buttonText?: React.ReactNode;
  children?: React.ReactNode;
  onResults?: (results: any) => void;
}
