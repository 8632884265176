import { useLinkProcedureTemplatesMutation } from "modules/procedures/state/proceduresApi";
import { IProcedure } from "modules/procedures/types";
import { useMemo, useState } from "react";
import { Button } from "shared/components/Button";
import { OptionType } from "shared/components/Select/SelectCreatable";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useCaptureExceptionWithBreadcrumb } from "shared/lib/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ProcedureLinkModal } from "modules/procedures/components";
import { Procedure } from "./Procedure";
import { pattern } from "@test-data";
import { usePermission } from "app/providers/PermissionsProvider";

interface ProcedureListProps {
  procedures: IProcedure[];
  workOrder: {
    id: string | number;
    technicians: { id: string | number }[];
    created_by_id: string | number;
  };
}

export const ProcedureList = ({
  procedures,
  workOrder,
}: ProcedureListProps) => {
  const { enableProcedures } = useFlags();
  const { id: workOrderId, technicians } = workOrder;
  const { t } = useTranslation();
  const { procedureAddPermit } = usePermission() as unknown as {
    procedureAddPermit: (
      compareUserId: string | number,
      technicians: unknown[]
    ) => boolean;
  };

  const captureException = useCaptureExceptionWithBreadcrumb({
    showGenericErrorSnack: true,
  });

  // State
  const [modalOpen, setModalOpen] = useState(false);
  const [procedureTemplates, setProcedureTemplates] = useState<OptionType[]>(
    []
  );
  const [isLinkingInProgress, setIsLinkingInProgress] =
    useState<boolean>(false);

  // Mutations & queries
  const [triggerLinkProcedures] = useLinkProcedureTemplatesMutation();

  // Callbacks & functions
  const linkProcedures = async () => {
    setIsLinkingInProgress(true);
    const procedureTemplateIds = procedureTemplates.map((t) => t.value);
    try {
      await triggerLinkProcedures({
        targetId: workOrderId,
        targetType: "WorkOrder",
        procedureTemplateIds,
      }).unwrap();
    } catch (e) {
      captureException(e, "Failed to link procedure", {
        workOrderId,
        procedureTemplateIds,
      });
    }

    setIsLinkingInProgress(false);
    setModalOpen(false);
  };

  const canLinkProcedure = useMemo(() => {
    return procedureAddPermit(workOrder.created_by_id, technicians);
  }, [procedureAddPermit, technicians, workOrder.created_by_id]);

  const onSelect = (_: string, value: unknown) => {
    setProcedureTemplates(value as OptionType[]);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setProcedureTemplates([]);
  };

  if (!enableProcedures) {
    return null;
  }

  return (
    <>
      <FlexContainer>
        <Title>{t("procedures.title")}</Title>
        {canLinkProcedure && (
          <StyledButton variant="secondary" onClick={() => setModalOpen(true)}>
            {t("procedures.add_procedure")}
          </StyledButton>
        )}
      </FlexContainer>

      {procedures.length > 0 && (
        <ListContainer>
          {procedures.map((procedure, index) => (
            <Procedure
              key={procedure.id}
              procedure={procedure}
              workOrder={workOrder}
              data-testid={pattern.procedureId(index + 1)}
            />
          ))}
        </ListContainer>
      )}

      <ProcedureLinkModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={linkProcedures}
        value={procedureTemplates}
        onChange={onSelect}
        isSubmitting={isLinkingInProgress}
        submitDisabled={procedureTemplates.length === 0}
      />
    </>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
`;

const ListContainer = styled.ul`
  margin: 0;
  & > li:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 1;
  padding: 4px;
  height: 24px;
`;
